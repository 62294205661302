import { useForm } from '@mantine/form';
import { QueryLoader } from '../../components/Loader';
import { useLeaderboardKpiData, useWidgetConfig } from '../api';
import { KpiSelect, SheetSelect } from './editor/KpiSelect';
import { getSupFromNumber } from './TeamRank';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { Stack } from '@mantine/core';

interface Props {
  competitionId: number;
  playerId: number;
  importId: number;
  widgetKey: string;
  title: string;
}

interface Config {
  sheet: string;
  kpi: string;
}

export function PlayerRank(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    props.widgetKey
  ).data;

  return (
    <div>
      <div>{props.title}</div>
      {config && <PlayerRankInner {...props} config={config} />}
      <WidgetEditor
        widgetKey={props.widgetKey}
        config={config}
        editor={editor}
        competitionId={props.competitionId}
      />
    </div>
  );
}

function PlayerRankInner(props: Props & { config: Config }) {
  const rankQuery = useLeaderboardKpiData(
    props.competitionId,
    [props.config.kpi],
    props.config.sheet || 'player_stats',
    [props.playerId],
    props.importId
  );
  return (
    <QueryLoader
      data={rankQuery}
      loaded={(kpiRows) => {
        const rank = kpiRows.rows.at(0)?.value;
        return (
          <>
            {rank ? (
              <div>
                {rank}
                <sup>{getSupFromNumber(rank)}</sup>
              </div>
            ) : (
              'N/A'
            )}
          </>
        );
      }}
    />
  );
}

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        sheet: config?.sheet || 'player_stats',
        kpi: config?.kpi || '',
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, void>) {
  let form = props.form;
  return (
    <Stack>
      <SheetSelect field="sheet" form={form} />
      <KpiSelect field="kpi" form={form} sheet={form.values.sheet} />
    </Stack>
  );
}
