import { FunctionComponent } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { Row } from './KpiRankTable';
import { AppStore, PlayerInfo, mediaAsset } from '../../state';
import { useViewportSize } from '@mantine/hooks';

interface Props {
  legendConfig: { target: string; actual: string };
  rows: Row[];
  players: Map<number, PlayerInfo>;
  fmtActual: (v: number) => string;
  fmtTarget: (v: number) => string;
}

function maxByKey<T>(
  arr: T[],
  key: (e: T) => number | undefined
): number | undefined {
  let max: number | undefined = undefined;
  arr.forEach((e) => {
    let v = key(e);
    if (v != null && (max == null || v > max)) max = v;
  });
  return max;
}

let wholeUp = (v: undefined | number, d: number) => {
  if (v != null) {
    let powerOfTen = Math.pow(10, Math.min(Math.floor(Math.log10(v)), d));
    return Math.ceil(v / powerOfTen) * powerOfTen;
  } else {
    return v;
  }
};

export default function KpiRankGraph(props: Props) {
  const { width } = useViewportSize();
  let { settings } = AppStore.useState((s) => s);
  let hideGraphNumbers = AppStore.useState(
    (s) => s.competition?.data?.hide_graph_numbers || false
  )!;
  let { rows, players, fmtActual, fmtTarget } = props;
  let maxActual = maxByKey(rows, (r) => r.graphActual);
  maxActual = wholeUp(
    maxActual,
    Math.max(1, Math.floor(Math.log10(maxActual || 1)) - 2)
  );

  let brushEndIndex = 4;
  if (width > 600) brushEndIndex = 8;
  else if (width > 500) brushEndIndex = 6;

  let maxTarget =
    (wholeUp(
      maxByKey(rows, (r) => (r.graphTarget || 0) * 100),
      1
    ) || 100) / 100;

  return (
    <ResponsiveContainer width="100%" height={330}>
      <ComposedChart
        width={500}
        height={500}
        data={rows}
        margin={{
          top: 10,
          bottom: 30,
          left: -10,
          right: -10,
        }}
      >
        <XAxis
          dataKey="subjectId"
          tick={<ImageTick players={players} />}
          height={60}
        />

        <YAxis
          yAxisId="actualAxis"
          orientation="left"
          stroke="#8f8f8f"
          tickFormatter={(v) => (hideGraphNumbers ? '' : fmtActual(v))}
          domain={[0, maxActual || 1]}
          fontSize={10}
        />
        <YAxis
          yAxisId="targetAxis"
          orientation="right"
          stroke="#8f8f8f"
          tickFormatter={(v) => fmtTarget(v)}
          domain={[0, maxTarget || 1]}
          fontSize={10}
        />

        <Tooltip
          labelFormatter={(v) => players.get(v)?.name || ''}
          formatter={(v, _, d) => {
            if (d.dataKey === 'graphActual') {
              if (hideGraphNumbers) return '-';
              return fmtActual(v as number);
            } else if (d.dataKey === 'graphTarget') {
              return fmtTarget(v as number);
            } else {
              return v;
            }
          }}
        />

        <Brush
          dataKey="name"
          tickFormatter={() => ''}
          endIndex={brushEndIndex}
          height={30}
          stroke={settings.main_color}
        />
        <Legend verticalAlign="top" />
        <Bar
          yAxisId="actualAxis"
          dataKey="graphActual"
          barSize={40}
          fill={settings.main_color}
          name={props.legendConfig.actual || 'Actual'}
        />
        <Line
          isAnimationActive={false}
          yAxisId="targetAxis"
          type="linear"
          dataKey="graphTarget"
          name={props.legendConfig.target || 'Achievement'}
          stroke="black"
          dot={{ stroke: 'black', strokeWidth: 4, r: 2 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

const ImageTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload, players } = props;

  let player = players.get(payload.value);
  let logo = player?.photo
    ? mediaAsset(player?.photo)
    : require('../../imgs/team_logo_placeholder.png');

  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject x={-20} y={0} width={40} height={40}>
        <img
          src={logo}
          alt={player?.name}
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
      </foreignObject>
    </g>
  );
};
