import styled from '@emotion/styled';
import { UseFormReturnType } from '@mantine/form';
import {
  ActionIcon,
  Box,
  Group,
  NativeSelect,
  NumberInput,
  Stack,
  TextInput,
  Checkbox,
} from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import React from 'react';
import { useClickOutside } from '@mantine/hooks';
import { getPath } from '../utils';
import { colors } from '@mui/material';

export type KpiFormat = 'decimal' | 'percent' | 'ordinal';
export type ColorOption = 'none' | 'greater_value' | 'lower_value';

export interface KpiOptions {
  sheet: string;
  kpi: string;
  title: string;
  format: KpiFormat;
  decimalPlaces: number;
  suffix: string;
  prefix: string;
  color: string;
  colors: Array<{ color: string; value: number }>;
  letterThousands?: boolean; // round to thousands and use K, M, B, T
}

interface Props<T> {
  field: string;
  form: UseFormReturnType<T>;
}

let formatOptions = ['decimal', 'percent', 'ordinal'].map((s) => ({
  value: s,
  label: s.charAt(0).toUpperCase() + s.slice(1),
}));

export function KpiOptionsWidget<T>(props: Props<T>) {
  let { field, form } = props;
  let colors = (getPath(field, form.values) as KpiOptions)?.colors || [];
  let lastValue = colors.length > 0 ?? colors[colors.length]?.value ?? 1.0;

  return (
    <Wrap>
      <Stack>
        <Group grow>
          <NativeSelect
            label="Number Format"
            data={formatOptions}
            {...form.getInputProps(`${field}.format`)}
          />
          <NumberInput
            label="Decimal Places"
            defaultValue={1}
            {...form.getInputProps(`${field}.decimalPlaces`)}
          />
        </Group>
        <Group grow>
          <TextInput
            label="Prefix"
            defaultValue=""
            {...form.getInputProps(`${field}.prefix`)}
          />
          <TextInput
            label="Suffix"
            defaultValue=""
            {...form.getInputProps(`${field}.suffix`)}
          />
          <Checkbox
            label="K/M suffix"
            defaultValue=""
            {...form.getInputProps(`${field}.letterThousands`)}
          />
        </Group>

        <Stack spacing="xs" mt={20}>
          <Box mb={-10}>Color Settings</Box>
          <Group spacing="xs">
            <ColorSelect
              default="neutral"
              form={form}
              field={`${field}.color`}
            />
            {colors.map((c, i) => {
              return (
                <React.Fragment key={i}>
                  <TextInput
                    type="number"
                    placeholder="Value"
                    defaultValue={1.0}
                    style={{ width: '80px' }}
                    {...form.getInputProps(`${field}.colors.${i}.value`)}
                    onChange={(e) => {
                      let v = parseFloat(e.target.value);
                      form.setFieldValue(
                        `${field}.colors.${i}.value`,
                        Number.isFinite(v) ? (v as any) : e.target.value
                      );
                    }}
                  />
                  <ColorSelect
                    default="neutral"
                    field={`${field}.colors.${i}.color`}
                    form={form}
                  />
                </React.Fragment>
              );
            })}
            <Stack>
              <ActionIcon
                color="green"
                onClick={() => {
                  let v = { color: 'neutral', value: lastValue };
                  if (colors.length === 0) {
                    form.setFieldValue(`${field}.colors`, [v] as any); // works but ts defs are wrong
                  } else {
                    form.insertListItem(`${field}.colors`, v);
                  }
                }}
                disabled={colors.length > 2}
              >
                <IconPlus size="1rem" />
              </ActionIcon>
              <ActionIcon
                color="red"
                onClick={() =>
                  form.removeListItem(`${field}.colors`, colors.length - 1)
                }
                disabled={colors.length === 0}
              >
                <IconMinus size="1rem" />
              </ActionIcon>
            </Stack>
          </Group>
        </Stack>
      </Stack>
    </Wrap>
  );
}

interface ColorSelectProps<T> {
  default: string;
  field: string;
  form: UseFormReturnType<T>;
}

function SingleColor(props: {
  color: string;
  onClick: (color: string) => void;
}) {
  return (
    <Box
      onClick={() => props.onClick(props.color)}
      bg={props.color}
      w="30px"
      h="30px"
      display="block"
      sx={{
        borderRadius: '15px',
        cursor: 'pointer',
        '&:hover': { opacity: 0.5 },
      }}
    />
  );
}

function ColorSelect<T>(props: ColorSelectProps<T>) {
  let [opened, setOpened] = React.useState(false);
  const ref = useClickOutside(() => setOpened(false));

  let data = [
    { value: 'red', color: '#ff595e', label: 'Red' },
    { value: 'yellow', color: '#ffca3a', label: 'Yellow' },
    { value: 'green', color: '#8ac926', label: 'Green' },
    { value: 'blue', color: '#0f52ba', label: 'Blue' },
    { value: 'neutral', color: '#252422', label: 'Neutral' },
  ];

  let field = props.field;
  let selectProps = props.form.getInputProps(field);
  let selectedColor =
    data.find((d) => d.value === selectProps.value)?.color || '#252422';

  return (
    <Box ref={ref} pos="relative">
      <SingleColor color={selectedColor} onClick={() => setOpened(!opened)} />
      {opened && (
        <Group
          bg="white"
          p={10}
          spacing="5px"
          pos="absolute"
          sx={{ zIndex: 1000, borderRadius: 10 }}
          left={35}
          bottom={-10}
          w="85px"
        >
          {data.map((d) => (
            <SingleColor
              color={d.color}
              onClick={() => {
                console.log(field, d.value);
                props.form.setFieldValue(field, d.value as any);
                setOpened(false);
              }}
            />
          ))}
        </Group>
      )}
    </Box>
  );
}

let Wrap = styled.div``;
