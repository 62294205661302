import { FunctionComponent } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AxisDomain } from 'recharts/types/util/types';
import { AppStore } from '../../state';

const RotatedDateAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props;

  let dateFormatterOneLetter = (value: number) => {
    let s = new Date(value).toLocaleString('default', { month: 'short' });
    return s.substring(0, 1);
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        fontSize={10}
      >
        {dateFormatterOneLetter(payload.value)}
      </text>
    </g>
  );
};

export declare type YAxisType = 'numeric' | 'percentage';

interface KpiProps {
  dataKey: string;
  displayName: string;
  color: string;
}

interface Props {
  data: any[];
  kpiList: KpiProps[];
  xAxisDomain: AxisDomain;
  yAxisType: YAxisType;
  yAxisDomain: AxisDomain;
  showGraphNumbersAnyway?: boolean;
}

export default function KpiGraph(props: Props) {
  let hideGraphNumbers = AppStore.useState(
    (s) => s.competition?.data?.hide_graph_numbers || false
  )!;

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart
        data={props.data}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="period"
          tickFormatter={(value) =>
            new Date(value).toLocaleString('default', { month: 'short' })
          }
          type="number"
          domain={props.xAxisDomain}
          scale="time"
          interval={0}
          tick={<RotatedDateAxisTick />}
        />
        {!hideGraphNumbers && (
          <YAxis
            tickFormatter={(value) => {
              if (props.yAxisType === 'percentage') {
                return `${(100 * (value as number)).toFixed(2)}%`;
              } else {
                return Math.round(value).toLocaleString();
              }
            }}
            fontSize={10}
            domain={props.yAxisDomain}
          />
        )}
        {!hideGraphNumbers && (
          <Tooltip
            labelFormatter={(value) =>
              new Date(value).toLocaleString('default', { month: 'long' })
            }
            formatter={(value, _name, _props) => {
              if (props.yAxisType === 'percentage') {
                return `${(100 * (value as number)).toFixed(2)}%`;
              } else {
                if (hideGraphNumbers) return '-';
                return Math.round(value as number).toLocaleString();
              }
            }}
          />
        )}
        {props.kpiList.map((kpiProps, index) =>
          kpiProps.displayName == null ? null : (
            <Line
              key={index}
              name={kpiProps.displayName}
              type="monotone"
              dataKey={kpiProps.dataKey}
              stroke={kpiProps.color}
            />
          )
        )}
        <Legend
          layout="horizontal"
          wrapperStyle={{ bottom: '0px', left: '10px', fontSize: '0.8em' }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
