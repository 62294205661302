import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppStore, ColumnOptions, Settings } from '../state';
import { getMidweekResultBadgeUrl, getWinnerBadgeUrl, siteData } from '../util';
import { FadeElement } from './FadeElement';

export interface Props {
  matchId: number;
  left: NamedAvatarProps;
  right: NamedAvatarProps;
  score: ScoreProps | null;
  stats: StatsProps | null;
  matchInProgress?: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  //transition: theme.transitions.create('transform', {
  //  duration: theme.transitions.duration.shortest,
  //}),
}));

export default function MatchCard(props: Props) {
  const [expanded, setExpanded] = React.useState(false);
  let winnerLeft = undefined;
  let winnerRight = undefined;
  if (props.score !== null) {
    if (props.score?.left !== props.score?.right) {
      winnerLeft = props.score?.left > props.score?.right;
      winnerRight = !winnerLeft;
    }
  }

  return (
    <Card sx={{}}>
      <CardContent sx={{ mb: -1 }}>
        <Main>
          <NamedAvatar
            {...props.left}
            winner={winnerLeft}
            midweekResult={props.matchInProgress}
          />
          {props.score !== null ? <Score {...props.score} /> : <NotPlayed />}
          <NamedAvatar
            {...props.right}
            winner={winnerRight}
            midweekResult={props.matchInProgress}
          />
        </Main>
      </CardContent>
      {props.stats !== null && (
        <>
          <CardActions>
            <Button
              size="small"
              variant="contained"
              component={Link}
              to={`/match/${props.matchId}`}
            >
              Full details
            </Button>

            <ExpandMoreWrap onClick={() => setExpanded(!expanded)}>
              <Typography variant="body2" color="text.secondary">
                SUMMARY
              </Typography>
              <ExpandMore
                expand={expanded}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon fontSize="large" />
              </ExpandMore>
            </ExpandMoreWrap>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <QuickStats {...props.stats} />
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
}

export interface NamedAvatarProps {
  id: number | null;
  name: string;
  img: string;
  imgAlt?: string;
  winner?: boolean;
  midweekResult?: boolean;
  competitionId: number;
}

export function NamedAvatar(props: NamedAvatarProps) {
  const { settings } = AppStore.useState((s) => s);
  const badgeSrc = props.midweekResult
    ? getMidweekResultBadgeUrl(settings)
    : getWinnerBadgeUrl(settings);
  const alt = props.midweekResult ? 'Midweek Result' : 'Winner';

  let getImg = (src: string) => (
    <img
      src={src}
      width="100"
      height="100"
      style={{ objectFit: 'contain' }}
      alt="Team"
    />
  );

  let img = props.imgAlt ? (
    <FadeElement
      front={getImg(props.img)}
      back={getImg(props.imgAlt)}
      width="100px"
      height="100px"
    />
  ) : (
    getImg(props.img)
  );

  let teamUrl = siteData().v2
    ? `/competition/${props.competitionId}/teams/${props.id}`
    : `/teams/${props.id}`;

  return (
    <NamedAvatarWrap>
      {props.winner && <Badge src={badgeSrc} alt={alt} />}
      {props.id ? (
        <Button sx={{ flexDirection: 'column' }} component={Link} to={teamUrl}>
          {img}
          <Typography
            sx={{ fontSize: 12, mt: 1, paddingTop: '5px' }}
            color="text.secondary"
            gutterBottom
          >
            {props.name}
          </Typography>
        </Button>
      ) : (
        <Button sx={{ flexDirection: 'column' }}>
          {img}
          <Typography
            sx={{ fontSize: 14, mt: 1, paddingTop: '5px' }}
            color="text.secondary"
            gutterBottom
          >
            {props.name}
          </Typography>
        </Button>
      )}
    </NamedAvatarWrap>
  );
}

const Badge = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 2;
`;

let Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

let NamedAvatarWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35%;
  text-align: center;
  position: relative;
`;

export interface ScoreProps {
  left: number;
  right: number;
  playingNow?: boolean;
}

const ScoreTextWrap = styled.div`
  flex: 1;
  text-align: center;
  align-items: center;
  max-width: 35%;
  padding-top: 30px;

  span {
    text-transform: uppercase;
  }

  span.scoreTitle {
    font-weight: bold;
  }
`;
export function Score(props: ScoreProps) {
  const { settings } = AppStore.useState((s) => s);
  return (
    <ScoreTextWrap>
      <span className="scoreTitle">Score</span>
      <ScoreWrap className="score" settings={settings}>
        {props.left}&nbsp;<span></span>&nbsp;{props.right}
      </ScoreWrap>
      {props.playingNow ? <span>Playing Now</span> : <span>Full-Time</span>}
    </ScoreTextWrap>
  );
}

export function NotPlayed() {
  const { settings } = AppStore.useState((s) => s);
  return <ScoreWrap settings={settings}>&nbsp;VS&nbsp;</ScoreWrap>;
}

interface StatsProps {
  columnOptions: Array<ColumnOptions>;
  left: Stat;
  right: Stat;
  fullData: boolean;
  teamsHaveSameKpis: boolean;
}

interface Stat {
  [key: string]: KpiStat;
}

interface KpiStat {
  value: string;
  display_value: string;
}

export function QuickStats(props: StatsProps) {
  let previewColumnOptions = props.columnOptions.filter(
    (o) => props.fullData || !o.only_full_data
  );
  let childProps = { ...props, ...{ columnOptions: previewColumnOptions } };

  if (props.teamsHaveSameKpis) {
    return <SameKpiSetsLayout {...childProps} />;
  } else {
    return <DifferentKpiSetsLayout {...childProps} />;
  }
}

// function numberOrBlank(s: string): number | string {
//   let n = Number.parseFloat(s);
//   if (Number.isNaN(n)) {
//     return '';
//   }
//   return n.toFixed(2);
// }

interface ScoreWrapProps {
  settings: Settings;
}

let ScoreWrap = styled.div<ScoreWrapProps>`
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin: 5px 0px;

  span::before {
    content: '-';
  }
`;

let StatsWrap = styled.table`
  width: 100%;
  margin-top: 8px;
  td {
    padding: 3px 10px;
  }

  td:nth-child(2) {
    font-weight: bold;
  }
`;

const ExpandMoreWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

interface KpiSetsProps {
  columnOptions: Array<ColumnOptions>;
  left: Stat;
  right: Stat;
}

interface KpiStatsGroup {
  name: string;
  columnOptions: ColumnOptions[];
}

function SameKpiSetsLayout(props: KpiSetsProps) {
  // const { settings } = AppStore.useState((s) => s); // use settings.main_color for group separator's colour
  const { columnOptions, left, right } = props;

  let soloColumnOptions = columnOptions.filter((o) => !o.group);
  let groupedColumnOptions = columnOptions.filter((o) => o.group);

  let groupedStatsSummary: KpiStatsGroup[] = [];
  for (let i = 0; i < groupedColumnOptions.length; i++) {
    let index = groupedStatsSummary.findIndex(
      (e) => e.name === groupedColumnOptions[i].group
    );
    if (index >= 0) {
      groupedStatsSummary[index].columnOptions.push(groupedColumnOptions[i]);
    } else {
      groupedStatsSummary.push({
        name: groupedColumnOptions[i].group,
        columnOptions: [groupedColumnOptions[i]],
      });
    }
  }

  return (
    <StatsWrap>
      <tbody>
        {soloColumnOptions.map((o, i) => (
          <tr key={i}>
            <td style={{ width: '20%', textAlign: 'center' }}>
              {left[o.name]?.display_value}
            </td>
            <td style={{ width: '60%', textAlign: 'center' }}>
              {o.display_name === '' ? o.name : o.display_name}
            </td>
            <td style={{ width: '20%', textAlign: 'center' }}>
              {right[o.name]?.display_value}
            </td>
          </tr>
        ))}
        {groupedStatsSummary.map((e, i) => (
          <>
            <tr key={`group-${i}`}>
              <td></td>
              <td
                style={{
                  width: '60%',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {e.name}
              </td>
              <td></td>
            </tr>
            {e.columnOptions.map((o, j) => (
              <tr key={`${i}-${j}`}>
                <td style={{ width: '20%', textAlign: 'right' }}>
                  {left[o.name]?.display_value}
                </td>
                <td
                  style={{
                    width: '60%',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  {o.display_name === '' ? o.name : o.display_name}
                </td>
                <td style={{ width: '20%', textAlign: 'left' }}>
                  {right[o.name]?.display_value}
                </td>
              </tr>
            ))}
          </>
        ))}
      </tbody>
    </StatsWrap>
  );
}

interface KpiGroup {
  leftGroupName: string;
  rightGroupName: string;
  columnOptions: {
    left: ColumnOptions | null;
    right: ColumnOptions | null;
  }[];
}

function DifferentKpiSetsLayout(props: KpiSetsProps) {
  const { settings } = AppStore.useState((s) => s);
  const { columnOptions, left, right } = props;

  // skip all KPIs with value 'N/A' ('N/A' means KPI will not be used by that team)
  let LeftStatsSummary = columnOptions.filter(
    (o) => (!left[o.name] || left[o.name].display_value !== 'N/A') && !o.group
  );
  let RightStatsSummary = columnOptions.filter(
    (o) => (!right[o.name] || right[o.name].display_value !== 'N/A') && !o.group
  );

  let LeftGroupingStatsSummary = columnOptions.filter(
    (o) => (!left[o.name] || left[o.name].display_value !== 'N/A') && o.group
  );
  let RightGroupingStatsSummary = columnOptions.filter(
    (o) => (!right[o.name] || right[o.name].display_value !== 'N/A') && o.group
  );

  let LeftGroupedStatsSummary: KpiStatsGroup[] = [];
  let RightGroupedStatsSummary: KpiStatsGroup[] = [];

  for (let i = 0; i < LeftGroupingStatsSummary.length; i++) {
    let indexGrouped = LeftGroupedStatsSummary.findIndex(
      (e) => e.name === LeftGroupingStatsSummary[i].group
    );
    if (indexGrouped >= 0) {
      LeftGroupedStatsSummary[indexGrouped].columnOptions.push(
        LeftGroupingStatsSummary[i]
      );
    } else {
      LeftGroupedStatsSummary.push({
        name: LeftGroupingStatsSummary[i].group,
        columnOptions: [LeftGroupingStatsSummary[i]],
      });
    }
  }

  for (let i = 0; i < RightGroupingStatsSummary.length; i++) {
    let indexGrouped = RightGroupedStatsSummary.findIndex(
      (e) => e.name === RightGroupingStatsSummary[i].group
    );
    if (indexGrouped >= 0) {
      RightGroupedStatsSummary[indexGrouped].columnOptions.push(
        RightGroupingStatsSummary[i]
      );
    } else {
      RightGroupedStatsSummary.push({
        name: RightGroupingStatsSummary[i].group,
        columnOptions: [RightGroupingStatsSummary[i]],
      });
    }
  }

  // zip left and right in case of unequal lengths
  let QuickStatsSummary = Array.from(
    Array(Math.max(LeftStatsSummary.length, RightStatsSummary.length)),
    (_, i) => [LeftStatsSummary[i], RightStatsSummary[i]]
  );

  let QuickGroupedStatsSummary = Array.from(
    Array(
      Math.max(LeftGroupedStatsSummary.length, RightGroupedStatsSummary.length)
    ),
    (_, i) => {
      let QuickStatsSummary = Array.from(
        Array(
          Math.max(
            LeftGroupedStatsSummary[i]
              ? LeftGroupedStatsSummary[i].columnOptions.length
              : 0,
            RightGroupedStatsSummary[i]
              ? RightGroupedStatsSummary[i].columnOptions.length
              : 0
          )
        ),
        (_, j) => {
          return {
            left: LeftGroupedStatsSummary[i]
              ? LeftGroupedStatsSummary[i].columnOptions[j]
              : null,
            right: RightGroupedStatsSummary[i]
              ? RightGroupedStatsSummary[i].columnOptions[j]
              : null,
          };
        }
      );

      let group: KpiGroup = {
        leftGroupName: LeftGroupedStatsSummary[i]
          ? LeftGroupedStatsSummary[i].name
          : '',
        rightGroupName: RightGroupedStatsSummary[i]
          ? RightGroupedStatsSummary[i].name
          : '',
        columnOptions: QuickStatsSummary,
      };
      return group;
    }
  );

  return (
    <StatsWrap>
      <tbody>
        {QuickStatsSummary.map((e, i) => (
          <tr key={i}>
            <td style={{ width: '30%', textAlign: 'right' }}>
              {e[0]?.display_name === '' ? e[0]?.name : e[0]?.display_name}
            </td>
            <td
              style={{
                width: '40%',
                textAlign: 'center',
              }}
            >
              {left[e[0]?.name]?.display_value} -{' '}
              {right[e[1]?.name]?.display_value}
            </td>
            <td style={{ width: '30%', textAlign: 'left' }}>
              {e[1]?.display_name === '' ? e[1]?.name : e[1]?.display_name}
            </td>
          </tr>
        ))}

        {QuickGroupedStatsSummary.map((e, i) => (
          <React.Fragment key={`group-${i}`}>
            <tr>
              <td
                style={{ width: '30%', textAlign: 'right', fontWeight: 'bold' }}
              >
                {e.leftGroupName}
              </td>
              <td style={{ textAlign: 'center', color: settings.main_color }}>
                KPI {i + 1}
              </td>
              <td
                style={{ width: '30%', textAlign: 'left', fontWeight: 'bold' }}
              >
                {e.rightGroupName}
              </td>
            </tr>
            {e.columnOptions.map((f, j) => (
              <tr key={`${i}-${j}`}>
                <td style={{ width: '30%', textAlign: 'right' }}>
                  {f.left?.display_name === ''
                    ? f.left?.name
                    : f.left?.display_name}
                </td>
                <td
                  style={{
                    width: '40%',
                    textAlign: 'center',
                  }}
                >
                  {f.left && left[f.left?.name]?.display_value} -{' '}
                  {f.right && right[f.right?.name]?.display_value}
                </td>
                <td style={{ width: '30%', textAlign: 'left' }}>
                  {f.right?.display_name === ''
                    ? f.right?.name
                    : f.right?.display_name}
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </StatsWrap>
  );
}
