import styled from '@emotion/styled';
import { AddAPhoto, Cancel } from '@mui/icons-material';
import {
  Avatar,
  Backdrop,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  Input,
  Switch,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar, VariantType } from 'notistack';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { queryClient } from '..';
import { QueryLoader } from '../components/Loader';
import PostComponent from '../components/Post';
import { AppStore, mediaAsset, UserPlayer, useTitle, User } from '../state';
import { siteData } from '../util';

interface NewPostFormProps {
  user: User;
  player?: UserPlayer;
  setPostInProgress: Dispatch<SetStateAction<boolean>>;
}

const MAX_POST_LEN = 280;
const MAX_ROAR_LEN = 50;

function NewPostForm(props: NewPostFormProps) {
  const { competitionId } = AppStore.useState((s) => s);
  const { enqueueSnackbar } = useSnackbar();

  const showSnackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, { variant });
  };

  const [file, setFile] = React.useState(null);
  const [fileURL, setFileURL] = React.useState('');
  const [text, setText] = React.useState<string>('');
  const [type, setType] = React.useState<string>('');
  const [extension, setExtension] = React.useState<string>('');
  const [roar, setRoar] = React.useState<boolean>(false);
  const MAX_LEN = roar ? MAX_ROAR_LEN : MAX_POST_LEN;
  const handleRoar = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoar(event.target.checked);
  };

  function handleUpload(event: any) {
    setFile(event.target.files[0]);
    setType(event.target.files[0].type);
    setFileURL(URL.createObjectURL(event.target.files[0]));
    setExtension(event.target.files[0].name.split('.').pop().toLowerCase());
  }

  function handleText(event: any) {
    setText(event.target.value);
  }

  async function submit(e: any) {
    let data = new FormData();
    if ((file === null || roar) && text === '') return;
    if (file !== null && !roar) {
      data.append('file', file as any);
      data.append('type', type);
      data.append('extension', extension);
    }
    if (text !== '') {
      data.append('text', text);
    }
    if (roar) {
      data.append('is_gong', '');
    }
    if (props.player) {
      data.append('player_id', props.player.player_id.toString());
    }

    try {
      let request = axios.post(
        `/api/competitions/${competitionId}/posts`,
        data,
        {
          headers: {
            accept: 'application/json',
            'content-type': `multipart/form-data`,
            'Gamifier-Platform': siteData().platform.toString(),
          },
        }
      );
      props.setPostInProgress(true);
      await request;
      props.setPostInProgress(false);
      setFile(null);
      setText('');
      showSnackBar('Posted to Arena', 'success');
      invalidatePosts(competitionId);
    } catch (e: any) {
      console.log(e);
      props.setPostInProgress(false);
      showSnackBar('Cannot create a post at this time.', 'error');
    }
  }
  let pic = props.player ? props.player.photo : props.user.image;
  let is_video = type.startsWith('video');

  return (
    <FormWrap>
      <Avatar src={(pic && pic.startsWith('http') && pic) || mediaAsset(pic)} />
      <form>
        <FormContainer>
          <div>
            <Input
              multiline
              style={{ fontSize: '1.3em' }}
              placeholder="What's happening?"
              fullWidth
              value={text}
              onChange={handleText}
              minRows={3}
              maxRows={5}
            />
            <div>
              {!roar && file !== null && (
                <ImagePreview>
                  {(is_video && (
                    <CardMedia
                      component="video"
                      src={fileURL}
                      controls
                      preload="metadata"
                    />
                  )) || <CardMedia component="img" src={fileURL} alt="File" />}
                  <IconButton onClick={() => setFile(null)}>
                    <Cancel />
                  </IconButton>
                </ImagePreview>
              )}
            </div>
          </div>
          <div>
            <FormControlLabel
              control={<Switch checked={roar} onChange={handleRoar} />}
              label="Roar"
            />
            {!roar && (
              <label htmlFor="icon-button-file">
                <input
                  accept="image/*;capture=camera"
                  style={{ display: 'none' }}
                  id="icon-button-file"
                  type="file"
                  onChange={handleUpload}
                />
                <IconButton
                  aria-label="upload photo"
                  component="span"
                  style={{ paddingLeft: 0 }}
                >
                  <AddAPhoto sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              </label>
            )}
            <CharLimit style={text.length > MAX_LEN ? { color: 'red' } : {}}>
              {text.length} / {MAX_LEN}
            </CharLimit>
            <Button
              variant="contained"
              color="primary"
              onClick={submit}
              disabled={
                ((file === null || roar) && text === '') ||
                text.length > MAX_LEN
              }
            >
              {(roar && 'Roar') || 'Post'}
            </Button>
          </div>
        </FormContainer>
      </form>
    </FormWrap>
  );
}

export const FormWrap = styled.div`
  display: flex;
  padding: 20px 20px 20px 15px;
  background: #f4f4f4;
  gap: 10px;

  form {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  > div:last-child {
    display: flex;
    justify-content: space-between;
  }
`;

const ImagePreview = styled.div`
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  button {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

interface PostListProps {
  posts: number[];
}

// Tracks which posts have been confetti popped
export interface PoppedPosts {
  [index: number]: boolean;
}

function PostList(props: PostListProps) {
  // Initially all are not popped
  const [poppedPost, setPoppedPost] = useState(() => {
    let initial = {} as PoppedPosts;
    for (const postId of props.posts) {
      initial[postId] = false;
    }
    return initial;
  });
  const [limit, setLimit] = useState<number>(20);

  useEffect(() => {
    setPoppedPost((oldState) => {
      let newState = {} as PoppedPosts;
      for (const postId of props.posts) {
        if (oldState[postId] === undefined) {
          newState[postId] = false;
        }
      }
      return { ...oldState, ...newState };
    });
  }, [props.posts]); // update all new

  return (
    <div>
      <WrapList>
        {props.posts.slice(0, limit).map((postId) => (
          <PostComponent
            key={postId}
            postId={postId}
            poppedPost={poppedPost}
            setPoppedPost={setPoppedPost}
          />
        ))}
      </WrapList>
      {limit < props.posts.length && (
        <LoadMore>
          <Button onClick={() => setLimit(limit + 20)}>Show More</Button>
        </LoadMore>
      )}
    </div>
  );
}

const WrapList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const getPostsByCompetitionId = async (
  competitionId: number
): Promise<number[]> => {
  const { data } = await axios.get<number[]>(
    `/api/competitions/${competitionId}/posts`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
  return data;
};

const usePosts = (competitionId: number) => {
  return useQuery(['posts', competitionId], () =>
    getPostsByCompetitionId(competitionId)
  );
};

export const invalidatePosts = (competitionId: number) => {
  queryClient.invalidateQueries(['posts', competitionId]);
};

export default function WallFeed() {
  useTitle('Arena', '/');

  const [postInProgress, setPostInProgress] = React.useState(false);
  const { competitionId } = AppStore.useState((s) => s);
  const player = AppStore.useState((s) => s.user?.player);
  const user = AppStore.useState((s) => s.user);
  const getPosts = usePosts(competitionId);

  return (
    <Wrap>
      <PullToRefresh
        onRefresh={async () => invalidatePosts(competitionId)}
        pullingContent=""
      >
        <>
          <Backdrop
            open={postInProgress}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress />
          </Backdrop>
          {user && (
            <NewPostForm
              user={user}
              player={player}
              setPostInProgress={setPostInProgress}
            />
          )}
          <Divider />
          <QueryLoader
            data={getPosts}
            loaded={(posts) => {
              return posts.length === 0 ? (
                <NoData>No posts.</NoData>
              ) : (
                <PostList posts={posts} />
              );
            }}
          />
        </>
      </PullToRefresh>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
`;

const NoData = styled.p`
  padding-top: 10px;
  text-align: center;
`;

export const CharLimit = styled.p`
  width: 100%;
  padding: 1px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
