import axios from 'axios';
import { Store } from 'pullstate';
import { siteData } from '../../util';
import { KpiOptions } from './editor/KpiOptions';

export interface KpiBasicOptions {
  name: string;
  number_format: 'decimal' | 'percent';
  decimal_places: string;
}

export interface Kpi {
  key: string;
  name: string;
  short_name: string;
  sheets: string[];
  options: KpiOptions;
}

export type KpiMap = { [key: string]: Kpi };

export interface EditorState {
  kpis: Kpi[];
  keyKpi: KpiMap;
  sheetKpis: { [sheet: string]: Kpi[] };
  sheets: string[];
}

export const EditorStore = new Store<EditorState>({
  kpis: [],
  keyKpi: {},
  sheetKpis: {},
  sheets: [],
});

export async function loadKpis() {
  const platformId = siteData().platform.toString();
  const { data } = await axios.get<{ rows: Kpi[] }>('/api/v2/kpi', {
    params: { platform_id: platformId },
  });

  let kpis = data.rows;
  let keyKpi: { [key: string]: Kpi } = {};
  let sheetKpis: { [key: string]: Kpi[] } = {};
  let sheets: Set<string> = new Set();
  kpis.forEach((kpi) => {
    kpi.sheets.forEach((s) => {
      sheets.add(s);
      if (sheetKpis[s] != null) {
        sheetKpis[s].push(kpi);
      } else {
        sheetKpis[s] = [kpi];
      }
    });
    keyKpi[kpi.key] = kpi;
  });

  EditorStore.update((s) => {
    s.kpis = kpis;
    s.keyKpi = keyKpi;
    s.sheets = Array.from(sheets);
    s.sheetKpis = sheetKpis;
  });
}

// TODO on refresh, call this!
loadKpis();
