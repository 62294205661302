import styled from '@emotion/styled';
import Header from '../../components/NarrowHeader';
import { AppStore, Competition, useTitle } from '../../state';
import { KpiRankTable } from '../widgets/KpiRankTable';

interface Props {
  competition: Competition;
}

export default function KpiRank(props: Props) {
  useTitle('Team KPI Rank');
  const competitionId = props.competition.competition_id;
  const importId = props.competition.active_import_id || 0; // TODO
  const settings = AppStore.useState((s) => s.settings);
  // TODO KPI RANK HEADER
  const perfHeader = settings.kpi_rank_header_background_img;
  const headerColor = settings.team_header_background;

  return (
    <Wrap>
      <Header
        title={perfHeader ? '' : 'Team KPI Rank'}
        background={perfHeader}
        headerColor={headerColor}
      />
      <KpiRankTable
        competitionId={competitionId}
        importId={importId}
        widgetKey="kpi_rank_table"
      />
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  gap: 20px;
`;
