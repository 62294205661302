import { Button, TextField } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { siteData } from '../util';

interface CreateOTPDeviceFormProps {
  onSuccess(device: OTPDeviceResponse): void;
  onError(message?: string): void;
  email?: string;
  password?: string;
}

const validationSchema = yup.object({
  name: yup.string().required('Device name is required'),
});

export interface OTPDeviceResponse {
  id: string;
  name: string;
  url: string;
  key: string;
  confirmed: boolean;
}

export default function CreateOTPDeviceForm(props: CreateOTPDeviceFormProps) {
  console.log(props);
  const createForm = useFormik({
    initialValues: {
      name: '',
      email: props.email,
      password: props.password,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      try {
        let resp = await axios.post(
          '/djapi/v1/user/create_2fa_device/',
          values,
          {
            headers: { 'Gamifier-Platform': siteData().platform.toString() },
          }
        );
        let device = resp.data as OTPDeviceResponse;
        props.onSuccess(device);
      } catch (e: any) {
        if (e.response && e.response.status === 403) {
          props.onError(e.response.data.message);
        }
        props.onError();
      }
    },
  });

  return (
    <form onSubmit={createForm.handleSubmit}>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="OTP Device Name"
        value={createForm.values.name}
        onChange={createForm.handleChange}
        error={createForm.touched.name && Boolean(createForm.errors.name)}
        helperText={createForm.touched.name && createForm.errors.name}
      />
      <Button color="primary" variant="contained" fullWidth type="submit">
        Create Two Factor Device
      </Button>
    </form>
  );
}
