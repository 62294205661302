import { Avatar, Button, Input } from '@mui/material';
import React from 'react';
import { CharLimit, FormContainer, FormWrap } from '../pages/WallFeed';
import { mediaAsset, UserPlayer } from '../state';

const MAX_COMMENT_LEN = 280;

interface Props {
  player?: UserPlayer;
  submit: (text: string) => Promise<boolean>;
  text?: string;
  handleEdit?: () => void;
}

export function NewCommentForm(props: Props) {
  const [text, setText] = React.useState<string>(props.text || '');

  function handleText(event: any) {
    setText(event.target.value);
  }

  async function handleSubmit(event: any) {
    let success = await props.submit(text);
    if (success) {
      setText('');
    }
  }

  return (
    <FormWrap>
      <Avatar src={mediaAsset(props.player?.photo || '')} />
      <form>
        <FormContainer>
          <div>
            <Input
              sx={{ fontSize: '0.8rem' }}
              multiline
              placeholder="Write a comment."
              fullWidth
              value={text}
              onChange={handleText}
              minRows={3}
              maxRows={6}
            />
          </div>
          <div>
            <CharLimit
              style={text.length > MAX_COMMENT_LEN ? { color: 'red' } : {}}
            >
              {text.length} / {MAX_COMMENT_LEN}
            </CharLimit>
            {props.handleEdit && (
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleEdit}
              >
                Cancel
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={text === '' || text.length > MAX_COMMENT_LEN}
            >
              Comment
            </Button>
          </div>
        </FormContainer>
      </form>
    </FormWrap>
  );
}
