import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, IconButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { mediaAsset } from '../state';

interface Props {
  news_id: number;
  title: string;
  img: string | null;
  photo_cover: boolean;
  photo_background: string;
  heading: string;
  body: string;
  externalLink: string | null;
  document: string | null;
  published_at: Date;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  // marginLeft: 'auto',
  //transition: theme.transitions.create('transform', {
  //  duration: theme.transitions.duration.shortest,
  //}),
}));

export default function NewsCard(props: Props) {
  const [expanded, setExpanded] = React.useState(false);
  const canExpand = props.body !== '';

  return (
    <StyledCard sx={{ width: '100%' }}>
      {props.img && (
        <div style={{ paddingTop: '56.25%', position: 'relative' }}>
          <CardMedia
            component="img"
            height="100%"
            sx={{
              backgroundColor: props.photo_background,
              objectFit: props.photo_cover ? 'cover' : 'contain',
              position: 'absolute',
              top: 0,
            }}
            image={mediaAsset(props.img!)}
            alt=""
          />
        </div>
      )}
      <CardContent sx={{ pb: 0 }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontSize: '20px' }}
        >
          {props.title}
        </Typography>
        {expanded ? (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: '12px' }}
          >
            <ReactMarkdown>{props.body}</ReactMarkdown>
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: '12px' }}
          >
            {props.heading}
          </Typography>
        )}
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ mt: expanded ? 2 : 0 }}
        >
          <i>
            Published at: {new Date(props.published_at).toLocaleDateString()}
          </i>
        </Typography>
      </CardContent>
      <CardActions>
        <Wrap>
          {props.externalLink && (
            <Button
              size="small"
              variant="contained"
              target="_blank"
              sx={{ mr: props.document ? 1 : 0 }}
              href={props.externalLink}
            >
              {props.externalLink.match(/youtube.com|youtu.be/)
                ? 'View on YouTube'
                : 'Open'}
            </Button>
          )}

          {props.document && (
            <Button
              size="small"
              variant="contained"
              target="_blank"
              href={mediaAsset(props.document)}
            >
              Download
            </Button>
          )}

          {canExpand && (
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon fontSize="large" />
            </ExpandMore>
          )}
        </Wrap>
      </CardActions>
    </StyledCard>
  );
}

let StyledCard = styled(Card)`
  p {
    margin-bottom: 10px;
  }
`;

let Wrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
