import styled from '@emotion/styled';
import React from 'react';
import { QueryLoader } from '../../components/Loader';
import Header from '../../components/NarrowHeader';
import { AppStore, Competition, useTitle } from '../../state';
import { useKpiInfo } from '../api';
import { TotalPerformance } from '../widgets/TotalPerformance';

interface Props {
  competition: Competition;
}

export default function Performance(props: Props) {
  useTitle('Total Performance');
  const competitionId = props.competition.competition_id;
  const importId = props.competition.active_import_id || 0; // TODO
  const settings = AppStore.useState((s) => s.settings);
  const perfHeader = settings.performance_header_background_img;
  const headerColor = settings.team_header_background;

  const kpiInfoQuery = useKpiInfo();

  const [activeGroup, setActiveGroup] = React.useState(0);

  return (
    <Wrap>
      <Header
        title={perfHeader ? '' : 'Total Performance'}
        background={perfHeader}
        headerColor={headerColor}
      />
      <QueryLoader
        data={kpiInfoQuery}
        loaded={(kpiInfo) => (
          <TotalPerformance
            importId={importId}
            competitionId={competitionId}
            widgetKey={'total_performance'}
            context={{
              activeGroup,
              setActiveGroup,
            }}
            kpiInfo={kpiInfo}
          />
        )}
      />
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;
