import React from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { VariantType } from 'notistack';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import IconButton from '@mui/material/IconButton/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import FormControl from '@mui/material/FormControl/FormControl';

const validationSchema1 = yup.object({
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  passwordAgain: yup.string(),
});

const RegForm = (props: Props) => {
  let [globalError, setGlobalError] = React.useState<string | null>(null);
  let [passwordChanged, setPasswordChanged] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordAgain = () =>
    setShowPasswordAgain((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownPasswordAgain = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const formik1 = useFormik({
    initialValues: {
      password: '',
      passwordAgain: '',
    },
    validationSchema: validationSchema1,
    onSubmit: async (data) => {
      if (data.password !== data.passwordAgain) {
        formik1.setFieldError('passwordAgain', 'Password did not match');
        return;
      }

      try {
        await axios.post('/djapi/v1/change_password/', data);
        setPasswordChanged(true);
        props.showSnackBar('Your password was changed', 'success');
      } catch (e: any) {
        if (e.response && e.response.data) {
          formik1.setFieldError('password', e.response.data.detail);
        } else {
          setGlobalError('An error happened. Please try again or contact us.');
        }
        props.showSnackBar('Password not changed', 'error');
      }
    },
  });

  if (passwordChanged) {
    return <h3>You password was changed</h3>;
  }

  return (
    <>
      <form onSubmit={formik1.handleSubmit}>
        {globalError && <p className="error">{globalError}</p>}
        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formik1.values.password}
            onChange={formik1.handleChange}
            error={formik1.touched.password && Boolean(formik1.errors.password)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="password_again">Password Again</InputLabel>
          <OutlinedInput
            id="password_again"
            name="passwordAgain"
            label="Password again aaaa"
            type={showPasswordAgain ? 'text' : 'password'}
            value={formik1.values.passwordAgain}
            onChange={formik1.handleChange}
            error={
              formik1.touched.passwordAgain &&
              Boolean(formik1.errors.passwordAgain)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordAgain}
                  onMouseDown={handleMouseDownPasswordAgain}
                  edge="end"
                >
                  {showPasswordAgain ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik1.isSubmitting}
        >
          {formik1.isSubmitting ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </form>
    </>
  );
};

interface Props {
  showSnackBar(message: string, variant: VariantType): void;
}

export default function Register(props: Props) {
  return (
    <Root>
      <RegForm {...props} />
    </Root>
  );
}

let Root = styled.div`
  form {
    padding: 0;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
