import { UseFormReturnType } from '@mantine/form';
import React, { ComponentType } from 'react';
import {
  ActionIcon,
  Button,
  Divider,
  Box,
  Group,
  NativeSelect,
  TextInput,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { randomId } from '@mantine/hooks';

type Groups<T> = Array<{ name: string; kpis: T; key: string }>;

interface FormField<V> {
  form: UseFormReturnType<any>;
  field: string;
  value: V;
}

interface Props<
  Value,
  Subvalue,
  C extends FormField<Subvalue> = FormField<Subvalue>
> extends FormField<Value> {
  component: ComponentType<any>; // todo any
  newKpis: () => Subvalue;
  innerProps: Omit<C, 'form' | 'field' | 'value'>;
}

export function KpiGroup<T>(props: Props<Groups<T>, T>) {
  let { form, value: groups, field } = props;
  let [groupKey, setGroupKey] = React.useState(
    groups.length > 0 ? groups[0].key : undefined
  );

  let group = groups.find((g) => g.key === groupKey);
  let groupIndex = groups.findIndex((g) => g.key === groupKey);
  let groupEl = null;

  if (group != null && groupIndex != null) {
    let kpis = group.kpis;

    groupEl = (
      <Box mt="md">
        <TextInput
          label="Group Name"
          placeholder="Group Name"
          sx={{ flex: 1 }}
          {...form.getInputProps(`${field}.${groupIndex}.name`)}
        />

        {React.createElement(props.component, {
          form: props.form,
          value: kpis,
          field: `${field}.${groupIndex}.kpis`,
          ...props.innerProps,
        })}
      </Box>
    );
  }

  let selectGroups = groups.map((g) => ({
    value: g.key,
    label: 'Group: ' + g.name,
  }));

  return (
    <>
      <Group align="center" mt="md" mb="md">
        <NativeSelect
          style={{ flex: '1' }}
          data={selectGroups}
          value={groupKey}
          disabled={groups.length === 0}
          onChange={(e) => setGroupKey(e.target.value)}
        />
        <ActionIcon
          color="red"
          onClick={() => {
            let nextKey =
              groups.length === 1
                ? undefined
                : groups[(groupIndex + 1) % (groups.length - 1)].key;
            form.removeListItem('groups', groupIndex);
            setGroupKey(nextKey);
          }}
        >
          <IconTrash size="1rem" />
        </ActionIcon>
        <Button
          onClick={() => {
            let key = randomId();
            form.insertListItem('groups', {
              name: 'New Group',
              kpis: props.newKpis(),
              key,
            });
            setGroupKey(key);
          }}
        >
          Create Group
        </Button>
      </Group>
      <Divider size="sm" />
      {groupEl}
    </>
  );
}
