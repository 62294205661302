import { Button, Tooltip } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { OTPDeviceResponse } from './CreateOTPDeviceForm';

interface Props {
  device: OTPDeviceResponse;
}
export default function ScanOTPDevice(props: Props) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (tooltipOpen) handleTooltipClose();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [tooltipOpen]);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const { device } = props;
  return (
    <div className="QRCodeScan">
      <h2>{device.name}</h2>
      <div>
        <QRCodeSVG value={device.url} />
      </div>
      <div>
        <CopyToClipboard text={device.key} onCopy={handleTooltipOpen}>
          <Tooltip open={tooltipOpen} title="Copied to clipboard.">
            <Button variant="contained">Copy Key</Button>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </div>
  );
}
