import { Checkbox, Group } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React from 'react';

interface KpiCheckProps<T> {
  form: UseFormReturnType<T>;
  field: string;
  label?: string;
  useSameKpis: boolean;
}

export function WidgetCheckbox<T>(props: KpiCheckProps<T>) {
  let { form, field, useSameKpis } = props;

  return (
    <Group position="center" mt="xl" mb="xl" grow align="flex-end">
      <Checkbox
        onClick={() => form.setFieldValue(field, !useSameKpis as any)}
        label={props.label}
        checked={useSameKpis}
        {...form.getInputProps(field)}
      />
    </Group>
  );
}
