import styled from '@emotion/styled';
import { useForm } from '@mantine/form';
import { Divider, Center } from '@mantine/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { QueryLoader } from '../../components/Loader';
import { KpiData, useLeaderboardKpiData, useWidgetConfig } from '../api';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { AppStore } from '../../state';
import { KpiTitle, KpiValue } from './utils';
import { KpiOptions } from './editor/KpiOptions';
import { KpiAdd, KpiList } from './editor/KpiList';

type SummaryType = 'big' | 'small';

interface Props {
  summaryType: SummaryType;
  subjectId: number;
  competitionId: number;
  className?: string;
  importId: number;
  widgetKey: string;
  subject: string; // this is not used?
}

export function Summary(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    props.widgetKey
  ).data;
  const editMode = AppStore.useState((s) => s.editMode);

  return (
    <Wrap
      className={props.className}
      style={{ paddingRight: editMode ? '40px' : '0' }}
    >
      {config && <SummaryInner {...props} config={config} />}
      <WidgetEditor
        widgetKey={props.widgetKey}
        config={config}
        editor={editor}
        competitionId={props.competitionId}
      />
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;

  .edit-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &.player_stats {
    padding: 0 20px !important;
  }

  &.player_stats div {
    font-size: 12px !important;
  }

  &.player_stats > div > div {
    flex: 1;
  }
`;

interface Config {
  kpis: KpiOptions[];
  sheet: string;
}

function SummaryInner(props: Props & { config: Config }) {
  const type = props.summaryType;
  const competitionId = props.competitionId;
  const subjects = [props.subjectId];
  const subjectType = props.config.sheet;
  const kpis = props.config.kpis.map((k) => k.kpi);
  const kpiQuery = useLeaderboardKpiData(
    competitionId,
    kpis,
    subjectType,
    subjects,
    props.importId
  );

  return (
    <QueryLoader
      data={kpiQuery}
      loaded={(kpiRows) => {
        if (type === 'big') {
          return <BigSummary kpiRows={kpiRows.rows} config={props.config} />;
        } else {
          return <SmallSummary kpiRows={kpiRows.rows} config={props.config} />;
        }
      }}
    />
  );
}

interface SummaryProps {
  kpiRows: KpiData[];
  config: Config;
}

function BigSummary(props: SummaryProps) {
  return (
    <Div className="big">
      {props.config.kpis.map((opts) => {
        return (
          <div key={opts.kpi}>
            <div>
              <KpiTitle options={opts} />
            </div>
            <div>
              <KpiValue value={props.kpiRows} options={opts} />
            </div>
          </div>
        );
      })}
    </Div>
  );
}

function SmallSummary(props: SummaryProps) {
  return (
    <PerformanceTableWrap className="small">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {props.config.kpis.map((opts) => (
                <TableCell key={opts.kpi}>
                  <KpiTitle options={opts} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              {props.config.kpis.map((opts) => (
                <TableCell key={opts.kpi}>
                  <KpiValue value={props.kpiRows} options={opts} />
                </TableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PerformanceTableWrap>
  );
}

let Div = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;

  & > div {
    text-align: center;
    padding: 10px;
  }

  &.items-2 > div {
    width: 50%;
  }

  &.items-3 > div {
    width: 33%;
  }

  &.items-4 > div {
    width: 25%;
  }

  &.items-5 > div {
    width: 33%;
  }

  &.items-6 > div {
    width: 33%;
  }

  & > div > div:first-of-type {
    color: gray;
  }

  & > div > div:last-child {
    font-size: 1.8em;
  }
`;

let PerformanceTableWrap = styled.div`
  padding: 0 15px;

  td,
  th {
    text-align: center;
  }

  th.MuiTableCell-root,
  td.MuiTableCell-root {
    padding: 0 2px 3px 0;
    font-size: 0.8em;
    min-width: 10%;
  }

  td.MuiTableCell-root {
    padding-top: 6px;
    font-size: 1em;
  }
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        kpis: config?.kpis || [],
        sheet: config?.sheet || '',
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, void>) {
  let form = props.form;
  let kpis = form.values.kpis;

  return (
    <>
      <KpiAdd form={form} kpisField="kpis" sheetDisabled={kpis.length > 0} />
      {kpis.length > 0 && <KpiList kpis={kpis} form={form} field="kpis" />}
      {kpis.length === 0 && (
        <Center mt="lg" mb="lg">
          No KPIs added
        </Center>
      )}
      <Divider size="sm" />
    </>
  );
}
