import { Competition, CompetitionNews } from '../state';
import NewsCard from './NewsCard';

interface NewsProps {
  competition: Competition;
  news: CompetitionNews[];
}

export default function NewsComponent(props: NewsProps) {
  const newsItems = props.news.map((item) => (
    <NewsCard
      key={item.news_id}
      news_id={item.news_id}
      title={item.title}
      img={item.photo}
      photo_cover={item.photo_cover}
      photo_background={item.photo_background_color}
      heading={item.summary}
      body={item.body}
      externalLink={item.external_link}
      document={item.document}
      published_at={item.published_at}
    />
  ));
  return <>{newsItems}</>;
}
