import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Paper } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import * as React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { AppStore } from '../state';
import { siteData } from '../util';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState<number | null>(0);
  const currentUrl = useLocation().pathname;
  let competitionId = AppStore.useState(
    (s) => s.competition?.data?.competition_id
  );
  const player = AppStore.useState((s) => s.user?.player);
  let isLeague = AppStore.useState((s) => s.competition?.data?.is_league)!;
  let ladderLabel = isLeague ? 'Ladder' : 'Groups';
  let hasGroupLadder = AppStore.useState(
    (s) => s.competition?.data?.has_group_ladder
  )!;
  let match: any = useRouteMatch('/competition/:competition_id');
  let routeCompetitionId = match?.params?.competition_id;
  let v2 = siteData().v2;
  competitionId = routeCompetitionId || competitionId;

  React.useEffect(() => {
    const myTeamUrl = player ? `/teams/${player.team_id}` : '';
    const targets =
      v2 && competitionId
        ? [
            '/',
            '/arena',
            myTeamUrl,
            `/competition/${competitionId}/matches`,
            `/competition/${competitionId}/leaderboard`,
          ]
        : ['/', '/arena', myTeamUrl, '/matches', '/leaderboard'];
    let t = targets.findIndex((t) => t === currentUrl);
    if (t !== -1) {
      setValue(t);
    } else {
      setValue(null);
    }
  }, [currentUrl, player]);

  let hasArena = siteData().has_arena;
  let sx = { minWidth: hasArena ? '60px' : '80px' };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        sx={{
          height: '65px',
          maxWidth: '600px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          sx={sx}
          component={Link}
          to="/"
        />
        {hasArena && (
          <BottomNavigationAction
            component={Link}
            to="/arena"
            label="Arena"
            sx={sx}
            icon={<FavoriteIcon />}
          />
        )}
        {player ? (
          <BottomNavigationAction
            component={Link}
            to={
              v2 && competitionId
                ? `/competition/${competitionId}/teams/${player.team_id}`
                : `/teams/${player.team_id}`
            }
            label="My&nbsp;Team"
            sx={sx}
            icon={<GroupsIcon />}
          />
        ) : (
          <BottomNavigationAction
            disabled
            sx={Object.assign({ opacity: 0.5 }, sx)}
            label="My&nbsp;Team"
            icon={<GroupsIcon />}
          />
        )}
        {!hasGroupLadder && (
          <BottomNavigationAction
            component={Link}
            to={
              v2 && competitionId
                ? `/competition/${competitionId}/matches`
                : `/matches`
            }
            label="Matches"
            sx={sx}
            icon={<EmojiEventsIcon />}
          />
        )}
        <BottomNavigationAction
          component={Link}
          to={
            v2 && competitionId
              ? `/competition/${competitionId}/leaderboard`
              : `/leaderboard`
          }
          label={ladderLabel}
          sx={sx}
          icon={<FormatListNumberedIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
