import {
  Avatar,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { mediaAsset, UserPlayer } from '../state';
import {
  Comment,
  deleteCommentById,
  updateCommentById,
} from '../components/PostCommentsComponent';
import { dateFormatterFull } from '../util';
import { MoreVert } from '@mui/icons-material';
import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '..';
import { NewCommentForm } from './NewCommentForm';

interface CommentProps {
  player?: UserPlayer;
  competitionId: number;
  postId: number;
  comment: Comment;
}

export function CommentCard(props: CommentProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = React.useState(false);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    if (edit) {
      handleClose();
    }
    setEdit(!edit);
  };

  const deleteComment = useMutation(
    () => deleteCommentById(props.competitionId, props.comment.comment_id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'post',
          props.competitionId,
          props.postId,
        ]);
        queryClient.invalidateQueries([
          'comment',
          props.competitionId,
          props.postId,
        ]);
      },
    }
  );

  const updateComment = useMutation(
    (text: string) =>
      updateCommentById(props.competitionId, props.comment.comment_id, text),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'post',
          props.competitionId,
          props.postId,
        ]);
        queryClient.invalidateQueries([
          'comment',
          props.competitionId,
          props.postId,
        ]);
      },
    }
  );

  async function submit(text: string) {
    updateComment.mutate(text);
    if (updateComment.isError) {
      return false;
    }
    handleEdit();
    return true;
  }

  const toolbar = props.comment.is_owned ? (
    <Toolbar
      variant="dense"
      style={{ padding: '16px 0 0 0', marginRight: '-8px' }}
    >
      <IconButton
        size="small"
        aria-label="my post"
        aria-controls="menu-postbar"
        aira-aria-haspopup="true"
        onClick={handleMenu}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="menu-postbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={() => deleteComment.mutate()}>Delete</MenuItem>
      </Menu>
    </Toolbar>
  ) : null;

  if (edit) {
    return (
      <NewCommentForm
        player={props.player}
        text={props.comment.text}
        submit={submit}
        handleEdit={handleEdit}
      />
    );
  }
  return (
    <div style={{ padding: '10px 20px', margin: '10px 0' }}>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item>
          <Avatar
            sx={{ width: 34, height: 34 }}
            src={mediaAsset(props.comment.player_photo)}
            alt="Profile"
          />
        </Grid>
        <Grid
          justifyContent="left"
          item
          xs
          zeroMinWidth
          style={{ paddingLeft: '8px' }}
        >
          <Typography
            variant="body1"
            style={{ margin: 0, fontSize: '0.875rem' }}
          >
            {props.comment.player_name}
          </Typography>
          <Typography
            variant="caption"
            color="gray"
            sx={{ fontSize: '0.75rem' }}
          >
            {dateFormatterFull(new Date(props.comment.created_at).getTime())}
          </Typography>
        </Grid>
        {toolbar}
      </Grid>
      <Typography variant="body2" sx={{ mt: '15px', fontSize: '0.75rem' }}>
        {props.comment.text}
      </Typography>
    </div>
  );
}
