import styled from '@emotion/styled';
import { Loader } from '../components/Loader';
import RichText from '../components/RichText';
import { newData } from '../core/data';
import {
  AppStore,
  DynamicPageElement,
  dynamicPageLoader,
  mediaAsset,
  useTitle,
} from '../state';
import { FromUrl, siteData } from '../util';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import CardActions from '@mui/material/CardActions';

interface DynamicPageProps {
  element: DynamicPageElement;
  externalLink: string | null;
  document: string | null;
}

function DynamicPageDetails({
  element,
  externalLink,
  document,
}: DynamicPageProps) {
  return (
    <Card sx={{ width: '100%' }}>
      {element.photo && (
        <CardMedia
          component="img"
          height="200"
          image={mediaAsset(element.photo)}
          alt=""
        />
      )}
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontSize: '20px' }}
        >
          {element.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: '12px' }}
        >
          <RichText html={element.content} />
        </Typography>
      </CardContent>
      <CardActions>
        <CardActionWrap>
          {externalLink && (
            <Button
              size="small"
              variant="contained"
              target="_blank"
              sx={{ mr: document ? 1 : 0 }}
              href={externalLink}
            >
              {externalLink.match(/youtube.com|youtu.be/)
                ? 'View on YouTube'
                : 'Open'}
            </Button>
          )}

          {document && (
            <Button
              size="small"
              variant="contained"
              target="_blank"
              href={mediaAsset(document)}
            >
              Download
            </Button>
          )}
        </CardActionWrap>
      </CardActions>
    </Card>
  );
}

interface SummaryProps {
  name?: string;
  description: string;
}

const StyledCard = styled(Card)`
  p {
    margin-bottom: 10px;
  }

  .home-rich-text {
    font-family: var(--main-font);
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.6em;
    font-size: 0.75rem;
  }
`;

function SummaryCard({ name, description }: SummaryProps) {
  return (
    <StyledCard sx={{ width: '100%' }} raised>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {name}
        </Typography>
        <RichText className="home-rich-text" html={description} />
      </CardContent>
    </StyledCard>
  );
}

export default function DynamicPage() {
  let { slug } = FromUrl<{ slug: string }>();
  let title = siteData().pages.find((p) => p.slug === slug)?.title || '';
  useTitle(title, '/');
  dynamicPageLoader.use({ slug }, [slug]);
  let page =
    AppStore.useState((s) => s.dynamicPages[slug], [slug]) || newData();

  let competition = AppStore.useState((s) => s.competition);

  return (
    <Loader
      data={page}
      loaded={(page) => (
        <Wrap>
          {page.header_img !== '' && (
            <Header>
              <img src={mediaAsset(page.header_img)} alt="header" />
            </Header>
          )}

          {page.summary.length > 0 && (
            <SummaryWrap>
              <SummaryCard
                name={competition.data?.name}
                description={page.summary}
              />
            </SummaryWrap>
          )}

          {page.elements.length > 0 && (
            <Elements>
              {page.elements.map((element) => (
                <DynamicPageDetails
                  key={element.title + element.order}
                  element={element}
                  document={element.document}
                  externalLink={element.external_link}
                />
              ))}
            </Elements>
          )}
        </Wrap>
      )}
    />
  );
}

let Header = styled.div`
  padding-top: 25%;
  position: sticky;
  margin-bottom: 15px;

  img {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    object-fit: cover;
  }
`;

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  overflow: hidden;

  .rich-text {
    overflow: scroll;
  }
`;

let CardActionWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

let Elements = styled.div`
  padding: 10px 10px 100px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 10px;
  }
`;

let SummaryWrap = styled.div`
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 10px;
  }
`;
