interface Props {
  code: string;
  width?: string;
}

export default function CountryFlag(props: Props) {
  return (
    <img
      loading="lazy"
      width={props.width || '20'}
      src={`https://flagcdn.com/w20/${props.code.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${props.code.toLowerCase()}.png 2x`}
      alt=""
    />
  );
}
