import { ComponentType } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { AppStore } from '../../state';
import { Group, Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import axios from 'axios';
import { siteData } from '../../util';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

export interface EditorProps<T, C> {
  form: UseFormReturnType<T>;
  context?: C;
}

export interface Editor<T, C = undefined> {
  useForm: (initialConfig?: T) => UseFormReturnType<T>;
  component: ComponentType<EditorProps<T, C>>;
}

interface WidgetEditorProps<T, C> {
  widgetKey: string;
  config?: T;
  context?: C;
  competitionId: number;
  editor: Editor<T, C>;
}

export function WidgetEditor<T, C>(props: WidgetEditorProps<T, C>) {
  const editMode = AppStore.useState((s) => s.editMode);
  if (editMode) {
    return <WidgetEditorInner {...props} />;
  } else {
    return null;
  }
}

function WidgetEditorInner<T, C>(props: WidgetEditorProps<T, C>) {
  const [opened, { open, close }] = useDisclosure(false);
  let styles = {
    root: {
      '& .mantine-Modal-content': { OverflowY: 'visible', minHeight: '400px' },
      '& .mantine-Modal-root': { overflow: 'visible' },
    },
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Edit Widget"
        centered
        size="lg"
        styles={styles}
      >
        <WidgetEditorForm {...props} close={close} />
      </Modal>
      <Button className="edit-btn" onClick={open} variant="subtle">
        Edit
      </Button>
    </>
  );
}

function WidgetEditorForm<T, C>(
  props: WidgetEditorProps<T, C> & { close: () => void }
) {
  const form = props.editor.useForm(props.config);
  const queryClient = useQueryClient();

  const submit = async (config: any) => {
    try {
      // save to api
      await axios.post(
        `/api/v2/widget/${props.competitionId}`,
        { key: props.widgetKey, config },
        { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
      );
      queryClient.invalidateQueries(['widgets', props.competitionId]);
    } catch (e) {
      // TODO set error
    }
    props.close();
  };

  const cancel = () => {
    props.close();
  };

  return (
    <form onSubmit={form.onSubmit(submit)}>
      {React.createElement(props.editor.component, {
        form,
        context: props.context,
      })}
      <Group position="right" mt="md">
        <Button onClick={cancel} variant="outline">
          Cancel
        </Button>
        <Button type="submit" disabled={!form.isDirty()} variant="filled">
          Save
        </Button>
      </Group>
    </form>
  );
}
