import styled from '@emotion/styled';
import { Box } from '@mantine/core';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { QueryLoader } from '../../components/Loader';
import { NamedAvatar, NotPlayed, Score } from '../../components/MatchCard';
import { WonLostDrawBar } from '../../components/WonLostDrawBar';
import { getPeriodLabel } from '../../pages/MatchesAll';
import { Match, media } from '../../state';
import { displayPeriodFormatter, isMidweekResult } from '../../util';
import { useMatchesByCompetition } from '../api';
import { SectionTitle } from '../pages/TeamOverview';
import { RoundsOverview } from '../widgets/RoundsOverview';

interface MatchesProps {
  teamId: number;
  competitionId: number;
  importId?: number;
}

export function Matches(props: MatchesProps) {
  const { teamId, competitionId, importId } = props;
  const matchesQuery = useMatchesByCompetition(competitionId, importId, teamId);

  return (
    <QueryLoader
      data={matchesQuery}
      loaded={({ matches, current_round }) => (
        <InnerMatches
          matches={matches}
          teamId={teamId}
          importId={importId || 0}
          competitionId={competitionId}
        />
      )}
    />
  );
}

function InnerMatches(props: {
  matches: Match[];
  teamId: number;
  importId: number;
  competitionId: number;
}) {
  let { matches, teamId, competitionId } = props;

  let matchTeamIds = matches
    .filter((m) => m.teams.some((mt) => mt.team_id === teamId))
    .map((m) => m.teams.find((mt) => mt.team_id === teamId)?.match_team_id!);

  let outcomes = matches
    .filter(
      (m) =>
        m.teams.every((t) => t.total_score || t.total_score === 0) &&
        m.teams.some((t) => t.team_id === teamId) &&
        m.teams.some((t) => t.team_id !== teamId)
    )
    .map((m) => {
      let teamScore = m.teams.find((t) => t.team_id === teamId)!.total_score!;
      let enemyScore = m.teams.find((t) => t.team_id !== teamId)!.total_score!;
      let outcome = '';
      if (teamScore > enemyScore) outcome = 'W';
      if (teamScore === enemyScore) outcome = 'D';
      if (teamScore < enemyScore) outcome = 'L';

      return {
        round: m.period,
        outcome: outcome,
      };
    });

  return (
    <div>
      <Box>
        <SectionTitle
          title="WEEKLY MATCHES: TEAM PERFORMANCE OVERVIEW"
          sub="Season form guide, weekly round results, rank and match stats summary."
        />
        <Box pl={10} pr={10}>
          <WonLostDrawBar {...countWins(teamId, matches)} />
        </Box>
        <Box pl={10} pr={10}>
          <RoundsOverview
            competitionId={competitionId}
            importId={props.importId}
            subjectIds={matchTeamIds}
            widgetKey="matches_rounds_overview"
            outcomes={outcomes}
          />
        </Box>
      </Box>
      {[...matches]
        .filter((m) =>
          m.teams.every((t) => t.total_score || t.total_score === 0)
        )
        .reverse()
        .map((m) => (
          <MatchInfo
            match={m}
            competitionId={competitionId}
            key={m.match_id}
            showRound
          />
        ))}
    </div>
  );
}

interface MatchInfoProps {
  match: Match;
  competitionId: number;
  showRound?: boolean;
}

export function countWins(teamId: number, matches: Match[]) {
  let won = 0,
    lost = 0,
    draw = 0;
  matches.forEach((m) => {
    if (m.teams.length === 2) {
      let [team, opponent] =
        m.teams[0].team_id === teamId
          ? [m.teams[0], m.teams[1]]
          : [m.teams[1], m.teams[0]];
      if (team.total_score == null || opponent.total_score == null) return;
      if (team.total_score > opponent.total_score) {
        won += 1;
      } else if (team.total_score < opponent.total_score) {
        lost += 1;
      } else {
        draw += 1;
      }
    }
  });
  return { won, lost, draw };
}

export function MatchInfo(props: MatchInfoProps) {
  const history = useHistory();
  const { match, competitionId, showRound } = props;

  const left = match.teams.find((t) => t.is_home);
  const right = match.teams.find((t) => !t.is_home);

  let leftWinner = undefined;
  let rightWinner = undefined;
  if (
    (left?.total_score || left?.total_score === 0) &&
    (right?.total_score || right?.total_score === 0) &&
    left?.total_score !== right?.total_score
  ) {
    leftWinner = left.total_score > right.total_score;
    rightWinner = !leftWinner;
  }

  let midweekResult = isMidweekResult(match.round_start, match.round_end);

  return (
    <>
      {showRound && (
        <SectionTitle
          title={getPeriodLabel(match.period, false, 'Round #')}
          sub={
            (match.round_start &&
              match.round_end &&
              displayPeriodFormatter(
                new Date(match.round_start).getTime(),
                new Date(match.round_end).getTime()
              )) ||
            ''
          }
        />
      )}
      <Card>
        <CardContent>
          <Main>
            <NamedAvatar
              id={left?.team_id || null}
              name={left?.name || ''}
              img={
                media(left?.logo) ||
                require('../../imgs/team_logo_placeholder.png')
              }
              imgAlt={media(left?.alt_logo) || undefined}
              competitionId={competitionId}
              winner={leftWinner}
              midweekResult={midweekResult}
            />
            {(left?.total_score || left?.total_score === 0) &&
            (right?.total_score || right?.total_score === 0) ? (
              <Score
                left={left.total_score}
                right={right.total_score}
                playingNow={midweekResult}
              />
            ) : (
              <NotPlayed />
            )}
            <NamedAvatar
              id={right?.team_id || null}
              name={right?.name || ''}
              img={
                media(right?.logo) ||
                require('../../imgs/team_logo_placeholder.png')
              }
              imgAlt={media(right?.alt_logo) || undefined}
              competitionId={competitionId}
              winner={rightWinner}
              midweekResult={midweekResult}
            />
          </Main>
        </CardContent>
        <CardActions sx={{ flexDirection: 'row-reverse' }}>
          <Button
            size="small"
            variant="contained"
            component={Link}
            to={{
              pathname: `/competition/${competitionId}/matches/${match.match_id}`,
              state: { back: history.location },
            }}
          >
            Full details
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

let Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
