import sanitizeHtml from 'sanitize-html';

const options = {
  allowedTags: [
    'h1',
    'h2',
    'h3',
    'p',
    'b',
    'i',
    'em',
    'strong',
    'ul',
    'ol',
    'li',
  ],
  allowedAttributes: {
    a: ['href'],
  },
};

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty, options),
});

interface Props {
  html: string;
  className?: string;
}

const RichText = (props: Props) => (
  <div
    className={props.className}
    dangerouslySetInnerHTML={sanitize(props.html)}
  />
);

export default RichText;
