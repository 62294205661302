import styled from '@emotion/styled';
import { Card, CardContent, List, ListItem } from '@mui/material';
import React from 'react';
import Header from '../components/NarrowHeader';
import { Loader } from '../components/Loader';
import MatchCard from '../components/MatchCard';
import { Props as MatchCardProps } from '../components/MatchCard';

import {
  allMatchesLoader,
  AppStore,
  Competition,
  Matches,
  mediaAsset,
  useTitle,
} from '../state';
import { displayPeriodFormatter } from '../util';
import Subheader from '../components/Subheader';

interface MatchProps {
  matchCardProps: MatchCardProps;
  roundName?: string;
  period: string | number;
  roundStart: Date | null;
  roundEnd: Date | null;
  i: number;
  division: string;
}

export function Match(props: MatchProps) {
  return (
    <ListItem key={props.i}>
      <Wrap>
        <React.Fragment key={props.matchCardProps.matchId}>
          <MatchCard {...props.matchCardProps} />
        </React.Fragment>
      </Wrap>
    </ListItem>
  );
}

interface Props {
  matches: Matches;
  competition: Competition;
}

function MatchesComponent(props: Props) {
  const { matches, competition } = props;
  const { settings } = AppStore.useState((s) => s);

  let periods: {
    period: string;
    roundStart: Date | null;
    roundEnd: Date | null;
    isKnockout: boolean;
  }[] = [];

  let roundName = competition.round_name;
  let isLeague = competition.is_league;
  let teamsHaveSameKpis = competition.teams_have_same_kpis;
  const upcomingMatches = matches.upcoming;
  const playingMatches = matches.playing;
  const playedMatches = matches.matches;
  const columnOptions = matches.column_options;

  const current_round = matches.current_round;
  let currentRoundPeriod = current_round
    ? current_round.stage === 'Knockout'
      ? RoundName(current_round.period, '', isLeague)
      : current_round.period.toString()
    : null;

  let upcomingMatchesList: MatchProps[] = upcomingMatches.map((m, i) => {
    if (
      periods.length === 0 ||
      periods[periods.length - 1].period !==
        RoundName(m.period, m.division, isLeague)
    ) {
      periods.push({
        period: RoundName(m.period, m.division, isLeague),
        roundStart: m.round_start,
        roundEnd: m.round_end,
        isKnockout: !isLeague && m.division === '',
      });
    }
    let leftTeam = null;
    let rightTeam = null;
    if (m.match_data[0].is_home) {
      leftTeam = m.match_data[0];
      rightTeam = m.match_data[1];
    } else {
      leftTeam = m.match_data[1];
      rightTeam = m.match_data[0];
    }
    return {
      i: i,
      period: m.period,
      roundName: roundName,
      roundStart: m.round_start,
      roundEnd: m.round_end,
      matchCardProps: {
        matchId: m.match_id,
        left: {
          id: leftTeam.team_id,
          name: leftTeam.team,
          img: mediaAsset(leftTeam.logo),
          imgAlt: leftTeam.alt_logo ? mediaAsset(leftTeam.alt_logo) : undefined,
          competitionId: competition.competition_id,
        },
        right: {
          id: rightTeam.team_id,
          name: rightTeam.team,
          img: mediaAsset(rightTeam.logo),
          imgAlt: rightTeam.alt_logo
            ? mediaAsset(rightTeam.alt_logo)
            : undefined,
          competitionId: competition.competition_id,
        },
        score: null,
        stats: null,
      },
      division: m.division,
    };
  });
  let playingMatchesList: MatchProps[] = playingMatches.map((m, i) => {
    if (
      periods.length === 0 ||
      periods[periods.length - 1].period !==
        RoundName(m.period, m.division, isLeague)
    ) {
      periods.push({
        period: RoundName(m.period, m.division, isLeague),
        roundStart: m.round_start,
        roundEnd: m.round_end,
        isKnockout: !isLeague && m.division === '',
      });
    }
    let leftTeam = null;
    let rightTeam = null;
    if (m.match_data[0].is_home) {
      leftTeam = m.match_data[0];
      rightTeam = m.match_data[1];
    } else {
      leftTeam = m.match_data[1];
      rightTeam = m.match_data[0];
    }
    return {
      i: i,
      period: m.period,
      roundName: roundName,
      roundStart: m.round_start,
      roundEnd: m.round_end,
      matchCardProps: {
        matchId: m.match_id,
        left: {
          id: leftTeam.team_id,
          name: leftTeam.team,
          img: mediaAsset(leftTeam.logo),
          imgAlt: leftTeam.alt_logo ? mediaAsset(leftTeam.alt_logo) : undefined,
          competitionId: competition.competition_id,
        },
        right: {
          id: rightTeam.team_id,
          name: rightTeam.team,
          img: mediaAsset(rightTeam.logo),
          imgAlt: rightTeam.alt_logo
            ? mediaAsset(rightTeam.alt_logo)
            : undefined,
          competitionId: competition.competition_id,
        },
        score:
          Number.isInteger(leftTeam.total_score) &&
          Number.isInteger(rightTeam.total_score)
            ? {
                left: leftTeam.total_score,
                right: rightTeam.total_score,
              }
            : null,
        stats:
          leftTeam.kpi_data && rightTeam.kpi_data
            ? {
                columnOptions: columnOptions,
                left: leftTeam.kpi_data,
                right: rightTeam.kpi_data,
                fullData: false,
                teamsHaveSameKpis,
              }
            : null,
      },
      division: m.division,
    };
  });
  let matchesList: MatchProps[] = playedMatches.map((m, i) => {
    if (
      periods.length === 0 ||
      periods[periods.length - 1].period !==
        RoundName(m.period, m.division, isLeague)
    ) {
      periods.push({
        period: RoundName(m.period, m.division, isLeague),
        roundStart: m.round_start,
        roundEnd: m.round_end,
        isKnockout: !isLeague && m.division === '',
      });
    }
    let leftTeam = null;
    let rightTeam = null;
    if (m.match_data[0].is_home) {
      leftTeam = m.match_data[0];
      rightTeam = m.match_data[1];
    } else {
      leftTeam = m.match_data[1];
      rightTeam = m.match_data[0];
    }
    return {
      i: i + upcomingMatches.length,
      period: m.period,
      roundName: roundName,
      roundStart: m.round_start,
      roundEnd: m.round_end,

      matchCardProps: {
        matchId: m.match_id,
        left: {
          id: leftTeam.team_id,
          name: leftTeam.team,
          img: mediaAsset(leftTeam.logo),
          imgAlt: leftTeam.alt_logo ? mediaAsset(leftTeam.alt_logo) : undefined,
          competitionId: competition.competition_id,
        },
        right: {
          id: rightTeam.team_id,
          name: rightTeam.team,
          img: mediaAsset(rightTeam.logo),
          imgAlt: rightTeam.alt_logo
            ? mediaAsset(rightTeam.alt_logo)
            : undefined,
          competitionId: competition.competition_id,
        },
        score: {
          left: leftTeam.total_score,
          right: rightTeam.total_score,
        },
        stats: {
          columnOptions: columnOptions,
          left: leftTeam.kpi_data,
          right: rightTeam.kpi_data,
          fullData: false,
          teamsHaveSameKpis,
        },
      },
      division: m.division,
    };
  });
  upcomingMatchesList.push(...playingMatchesList);
  upcomingMatchesList.push(...matchesList);

  let [period, setPeriod] = React.useState(
    currentRoundPeriod || periods[0]?.period || ''
  );

  const matchesHeader = settings.matches_header_background_img;

  let foundPeriod = periods.find((p) => p.period === period);
  let title =
    foundPeriod && foundPeriod.roundStart && foundPeriod.roundEnd
      ? displayPeriodFormatter(
          new Date(foundPeriod.roundStart).getTime(),
          new Date(foundPeriod.roundEnd).getTime()
        )
      : '';
  let headerColor = settings.team_header_background;

  return (
    <Main>
      <Header
        title={matchesHeader ? '' : 'Matches'}
        background={matchesHeader}
        headerColor={headerColor}
      />
      <SubheaderWrap>
        <Subheader
          title={title}
          value={period}
          onChange={setPeriod}
          inputId="round-select"
          inputLabel="Round"
          values={periods}
          mapValues={(d, i) => ({
            value: d.period,
            label: getPeriodLabel(d.period, d.isKnockout, roundName),
          })}
        />
      </SubheaderWrap>
      <List>
        {(upcomingMatchesList.length > 0 &&
          upcomingMatchesList
            .filter((m) => RoundName(m.period, m.division, isLeague) === period)
            .map(Match)) || (
          <CardWrap>
            <Card>
              <CardContent>No matches.</CardContent>
            </Card>
          </CardWrap>
        )}
      </List>
    </Main>
  );
}

export function getPeriodLabel(
  period: string | number,
  isKnockout: boolean,
  roundName: string | undefined
): string {
  if (isKnockout) {
    return period.toString();
  }
  return roundName
    ? roundName.replace('#', period.toString())
    : `Round ${period}`;
}

let Main = styled.div`
  padding-bottom: 56px;
`;

let SubheaderWrap = styled.div`
  padding: 20px 20px 0px 20px;
`;

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default function MatchesAll() {
  useTitle('Matches');
  const { competitionId, competition } = AppStore.useState((s) => s);
  const { allMatches } = AppStore.useState((s) => s.pageData);
  React.useEffect(() => {
    allMatchesLoader({});
  }, [competitionId]);

  return (
    <Loader
      data={competition}
      loaded={(loadedCompetition) => (
        <Loader
          data={allMatches}
          loaded={(matches) => (
            <MatchesComponent
              matches={matches}
              competition={loadedCompetition}
            />
          )}
        />
      )}
    />
  );
}

export function RoundName(
  period: string | number,
  division: string,
  isLeague: boolean
): string {
  if (division === '' && !isLeague) {
    if (!isNaN(Number(period))) {
      switch (Number(period)) {
        case 2:
          return 'Finals';
        case 4:
          return 'Semi-Finals';
        case 8:
          return 'Quarter-Finals';
        case 16:
          return 'Round of 16';
        case 32:
          return 'Round of 32';
      }
    }
  }
  return period.toString();
}

let CardWrap = styled.div`
  margin: 16px;
`;
