import React from 'react';
import styled from '@emotion/styled';

let subscribers: Set<(v: boolean) => void> = new Set();
let animationState: boolean = false;

(window as any).ANIM_SUBSCRIBERS = subscribers;

setInterval(() => {
  subscribers.forEach((f) => f(animationState));
  animationState = !animationState;
}, 3000);

interface Props {
  front: React.ReactElement;
  back: React.ReactElement | null;
  width: string;
  height: string;
}

export function useFade() {
  const [fade, setFade] = React.useState(true);
  React.useEffect(() => {
    subscribers.add(setFade);
    return () => {
      subscribers.delete(setFade);
    };
  }, [setFade]);
  return fade;
}

export function FadeElement(props: Props) {
  const [fade, setFade] = React.useState(true);

  React.useEffect(() => {
    subscribers.add(setFade);
    return () => {
      subscribers.delete(setFade);
    };
  }, [setFade]);

  if (props.back == null) {
    return <div>{props.front}</div>;
  }

  return (
    <Wrap
      className={fade ? 'backVisible' : ''}
      style={{
        width: props.width,
        height: props.height,
      }}
    >
      <div>{props.front}</div>
      <div>{props.back}</div>
    </Wrap>
  );
}

let Wrap = styled.div`
  position: relative;

  & > div:first-child {
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
  }

  & > div:last-child {
    opacity: 0;
    transition: opacity 0.7s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.backVisible > div:last-child {
    opacity: 1;
  }

  &.backVisible > div:first-child {
    opacity: 0;
  }
`;
