import styled from '@emotion/styled';
import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Header from '../../components/Header';
import { QueryLoader } from '../../components/Loader';
import {
  AppStore,
  Competition,
  Division,
  Settings,
  useTitle,
} from '../../state';
import {
  TeamDetail,
  useDivisionByCompetition,
  useTeamDetailByCompetition,
} from '../api';
import { Matches } from './TeamMatches';
import { Players } from './TeamPlayers';
import { Overview } from './TeamOverview';
import { PlayerDetails } from './PlayerDetails';

export default function Team(props: { competition: Competition }) {
  useTitle('Team');
  let competition = props.competition;
  const match = useRouteMatch('/competition/:competition_id/teams/:team_id');
  const { team_id } = match?.params as { team_id: number };
  const settings = AppStore.useState((s) => s.settings);
  const divisionsQuery = useDivisionByCompetition(competition.competition_id);
  const teamQuery = useTeamDetailByCompetition(
    competition.competition_id,
    team_id
  );

  return (
    <QueryLoader
      data={teamQuery}
      loaded={(team) => (
        <QueryLoader
          data={divisionsQuery}
          loaded={(divisions) => {
            const division = divisions.find((div) => div.key === team.division);
            return (
              <LoadedTeamPage
                settings={settings}
                team={team}
                competition={competition}
                division={division}
              />
            );
          }}
        />
      )}
    />
  );
}

interface Props {
  settings: Settings;
  team: TeamDetail;
  competition: Competition;
  division?: Division;
}

function LoadedTeamPage(props: Props) {
  const history = useHistory();
  const { settings, team, competition, division } = props;
  const companyLogo = settings.company_logo;
  const competitionTeamHeaderBackground = settings.team_header_background;
  const competitionTeamHeaderImg = settings.team_header_background_img;

  let pathParts = history.location.pathname.split('/');
  let tab = 'overview';
  if (pathParts.indexOf('matches') === pathParts.length - 1) {
    tab = 'matches';
  } else if (pathParts.indexOf('players') !== -1) {
    tab = 'players';
  }

  const onTabChange = (_: any, value: string) => {
    value = value === 'overview' ? '' : '/' + value;
    history.push(
      `/competition/${competition.competition_id}/teams/${team.team_id}${value}`
    );
  };

  return (
    <Wrap>
      <TabContext value={tab}>
        <Header
          name={team.name}
          centerLogo={
            team.logo || require('../../imgs/team_logo_placeholder.png')
          }
          centerLogoAlt={team.alt_logo}
          topRightLogo={companyLogo}
          background_color={competitionTeamHeaderBackground}
          background_image={competitionTeamHeaderImg}
        />

        <Head header_color={competitionTeamHeaderBackground}>
          <TabList
            variant="fullWidth"
            onChange={onTabChange}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label="Overview" value="overview" />
            <Tab label="Matches" value="matches" />
            <Tab label="Players" value="players" />
          </TabList>
        </Head>

        <Pad>
          <Switch>
            <Route exact path="/competition/:competition_id/teams/:team_id">
              <Overview
                team={team}
                competition={competition}
                division={division}
              />
            </Route>
            <Route
              exact
              path="/competition/:competition_id/teams/:team_id/matches"
            >
              <Matches
                teamId={team.team_id}
                competitionId={competition.competition_id}
                importId={competition.active_import_id}
              />
            </Route>
            <Route
              exact
              path="/competition/:competition_id/teams/:team_id/players"
            >
              <Players competition={competition} teamId={team.team_id} />
            </Route>
            <Route
              exact
              path="/competition/:competition_id/teams/:team_id/players/:player_id"
            >
              <PlayerDetails competition={competition} />
            </Route>
          </Switch>
        </Pad>
      </TabContext>
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

const Pad = styled.div`
  padding: 8px 8px 8px;
`;

interface HeadProps {
  header_color: string;
}

let Head = styled.div<HeadProps>`
  background: ${(props: HeadProps) =>
    props.header_color === ''
      ? 'var(--team-header-background)'
      : props.header_color};
  color: white;
`;
