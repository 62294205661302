import styled from '@emotion/styled';
import { Card, CardContent } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { QueryLoader } from '../../components/Loader';
import { NamedAvatar, NotPlayed, Score } from '../../components/MatchCard';
import { AppStore, Competition, Match, media, useTitle } from '../../state';
import {
  KpiMap,
  useKpiInfo,
  useMatchById,
  useMatchesByCompetition,
} from '../api';
import { MatchStats } from '../widgets/MatchStats';
import { displayPeriodFormatter, isMidweekResult } from '../../util';
import { countWins } from './TeamMatches';
import { WonLostDrawBar } from '../../components/WonLostDrawBar';
import { MutualMatches } from '../../components/MutualMatches';
import { SectionTitle } from './TeamOverview';
import Header from '../../components/NarrowHeader';
import { Box } from '@mantine/core';

export function MatchDetails(props: { competition: Competition }) {
  const prevLocation = useHistory().location.state as { back: string };
  useTitle('Match Detail', prevLocation?.back);
  const match = useRouteMatch('/competition/:competition_id/matches/:match_id');
  const competition = props.competition;
  const { match_id } = match?.params as { match_id: number };
  const settings = AppStore.useState((s) => s.settings);
  const matchQuery = useMatchById(
    competition.competition_id,
    match_id,
    competition.active_import_id
  );

  return (
    <>
      <Header
        title=""
        headerColor={settings.team_header_background}
        background={settings.match_details_header_background_img}
      />
      <QueryLoader
        data={matchQuery}
        loaded={(match) => (
          <MatchDetailsInner competition={competition} match={match} />
        )}
      />
    </>
  );
}

interface MatchDetailsProps {
  competition: Competition;
  match: Match;
}

function MatchDetailsInner(props: MatchDetailsProps) {
  const { match, competition } = props;
  const kpiMatchQuery = useKpiInfo();
  const mutualMatchesQuery = useMatchesByCompetition(
    competition.competition_id,
    competition.active_import_id,
    match.teams[0].team_id,
    match.teams[1].team_id
  );

  return (
    <QueryLoader
      data={kpiMatchQuery}
      loaded={(kpiInfo) => (
        <QueryLoader
          data={mutualMatchesQuery}
          loaded={(mutualMatches) => (
            <MatchFull
              match={match}
              importId={competition.active_import_id || 0}
              kpiInfo={kpiInfo}
              competitionId={competition.competition_id}
              mutualMatches={mutualMatches.matches}
            />
          )}
        />
      )}
    />
  );
}

interface MatchFullProps {
  match: Match;
  competitionId: number;
  importId: number;
  kpiInfo: KpiMap;
  mutualMatches: Match[];
}

function MatchFull(props: MatchFullProps) {
  const { match, competitionId, importId, mutualMatches } = props;

  const roundDate =
    match.round_start && match.round_end
      ? displayPeriodFormatter(
          new Date(match.round_start).getTime(),
          new Date(match.round_end).getTime()
        )
      : '';

  const left = match.teams.find((t) => t.is_home)!;
  const right = match.teams.find((t) => !t.is_home)!;
  const matchTeamIds: [number, number] = [
    left.match_team_id,
    right.match_team_id,
  ];

  let leftWinner = undefined;
  let rightWinner = undefined;
  if (
    (left?.total_score || left?.total_score === 0) &&
    (right?.total_score || right?.total_score === 0) &&
    left?.total_score !== right?.total_score
  ) {
    leftWinner = left.total_score > right.total_score;
    rightWinner = !leftWinner;
  }

  let midweekResult = isMidweekResult(match.round_start, match.round_end);

  return (
    <Card sx={{ mb: '80px' }}>
      <div style={{ padding: '10px 15px 0 15px' }}>
        <SectionTitle
          title="HEAD-TO-HEAD MATCHES OVERVIEW"
          sub="H2H form guide and match stats. Based on all matches in Leaguestar, where available."
        />
      </div>

      <CardContent>
        <Box>
          <WonLostDrawBar
            {...countWins(left.team_id, mutualMatches)}
            twoTeams
          />
        </Box>
      </CardContent>

      <CardContent>
        <SectionTitle title={`Round ${match.period}`} sub={roundDate} />
        <Main>
          <NamedAvatar
            id={left?.team_id || null}
            name={left?.name || ''}
            img={
              media(left?.logo) ||
              require('../../imgs/team_logo_placeholder.png')
            }
            competitionId={competitionId}
            winner={leftWinner}
            midweekResult={midweekResult}
          />
          {(left?.total_score || left?.total_score === 0) &&
          (right?.total_score || right?.total_score === 0) ? (
            <Score
              left={left.total_score}
              right={right.total_score}
              playingNow={midweekResult}
            />
          ) : (
            <NotPlayed />
          )}
          <NamedAvatar
            id={right?.team_id || null}
            name={right?.name || ''}
            img={
              media(right?.logo) ||
              require('../../imgs/team_logo_placeholder.png')
            }
            competitionId={competitionId}
            winner={rightWinner}
            midweekResult={midweekResult}
          />
        </Main>
      </CardContent>

      <CardContent>
        <MatchStats
          matchTeamIds={matchTeamIds}
          importId={importId}
          competitionId={competitionId}
        />
      </CardContent>

      <CardContent>
        <MutualMatches
          match={match}
          mutualMatches={mutualMatches}
          competitionId={competitionId}
        />
      </CardContent>
    </Card>
  );
}

let Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
