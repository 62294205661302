import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { QueryLoader } from '../../components/Loader';
import { AppStore, Competition, mediaAsset, Team, useTitle } from '../../state';
import { useTeamsByCompetition } from '../api';
import { FadeElement } from '../../components/FadeElement';

interface Props {
  competitionId: number;
  teams: Team[];
}

function SearchableTeamList(props: Props) {
  let { competitionId, teams } = props;
  const [search, setSearch] = React.useState<string>('');

  if (teams == null) teams = [];

  return (
    <div>
      <Box sx={{ padding: '20px 15px 15px 15px' }}>
        <TextField
          id="search-team"
          label="Search"
          variant="outlined"
          fullWidth
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Grid container direction="row" sx={{ paddingBottom: '56px' }}>
        {teams
          .filter((t) => {
            let searchRegex = new RegExp(search, 'giu'); // global, case-insensitive, unicode
            return searchRegex.test(t.name);
          })
          .map((t) => (
            <Grid item key={t.team_id} xs={4} sx={{ padding: '15px 5px' }}>
              <Button
                component={Link}
                to={`/competition/${competitionId}/teams/${t.team_id}`}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <TeamLogo>
                  <FadeElement
                    front={
                      <img
                        src={
                          t.logo
                            ? mediaAsset(t.logo)
                            : require('../../imgs/team_logo_placeholder.png')
                        }
                        alt="team"
                      />
                    }
                    back={
                      t.alt_logo !== '' ? (
                        <img src={mediaAsset(t.alt_logo)} alt="alt_logo" />
                      ) : null
                    }
                    width={'120px'}
                    height={'120px'}
                  />
                </TeamLogo>
                <Typography
                  sx={{ fontSize: 13, mt: 1 }}
                  color="text.primary"
                  gutterBottom
                >
                  {t.name}
                </Typography>
              </Button>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

const TeamLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  text-align: center;
  perspective: 1000px;
  -webkit-perspective: 1000px;
`;

export default function TeamList(props: { competition: Competition }) {
  useTitle('Teams');

  const settings = AppStore.useState((s) => s.settings);
  const topRightLogo = settings.company_logo;
  const headerColor = settings.team_header_background;
  const headerImage = settings.team_header_background_img;

  let competition = props.competition;
  const teamsQuery = useTeamsByCompetition(competition.competition_id);

  return (
    <Wrap>
      <Header
        name={competition.name}
        centerLogo={mediaAsset(competition.logo)}
        topRightLogo={topRightLogo}
        background_color={headerColor}
        background_image={headerImage}
      />
      <QueryLoader
        data={teamsQuery}
        loaded={(teams) => (
          <SearchableTeamList
            competitionId={competition.competition_id}
            teams={teams.teams}
          />
        )}
      />
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  .MuiGrid-root.MuiGrid-item {
    padding: 5px;
  }
`;
