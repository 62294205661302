import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { useTitle } from '../state';
import { siteData } from '../util';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

export default function Login() {
  useTitle('Reset Password');
  let [error, setError] = React.useState<string | null>(null);
  let [success, setSuccess] = React.useState<boolean>(false);

  let formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(null);
      try {
        await axios.post('/djapi/v1/reset_password/', values, {
          headers: { 'Gamifier-Platform': siteData().platform.toString() },
        });
        setSuccess(true);
      } catch (e: any) {
        if (e.response && e.response.status === 403) {
          setError('E-mail incorrect.');
        } else {
          setError('Failed to reset password. Please try again or contact us.');
        }
        if (e.response && e.response.status === 400) {
          setError(e.response.data.message);
        }
      }
    },
  });

  return (
    <Wrap>
      {success && (
        <p>
          Check your e-mail. <Link to="/app/login">Log in</Link>
        </p>
      )}

      {!success && (
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <Button color="primary" variant="contained" fullWidth type="submit">
            Reset password
          </Button>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </form>
      )}
    </Wrap>
  );
}

const Wrap = styled.div`
  & > p {
    padding: 20px;
    color: #444;
  }

  & > p > a {
    color: #444;
  }

  form {
    padding: 20px;
  }

  form > * {
    margin: 10px 0;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }
`;
