import React from 'react';
import styled from '@emotion/styled';
import { UseFormReturnType, useForm } from '@mantine/form';
import { KpiData, useKpiData, useWidgetConfig } from '../api';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { QueryLoader } from '../../components/Loader';
import { KpiOptions } from './editor/KpiOptions';
import { KpiListEditor } from './editor/KpiList';
import { EditorStore } from './state';
import { KpiGroup } from './editor/KpiGroup';
import { WidgetCheckbox } from './editor/WidgetCheckbox';
import { KpiTitle, KpiValue } from './utils';
import { AppStore } from '../../state';

const WIDGET_KEY = 'match_stats';

interface Props {
  matchTeamIds: [number, number];
  importId: number;
  competitionId: number;
}

export function MatchStats(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    WIDGET_KEY
  ).data;
  return (
    <>
      {config && <MatchStatsInner {...props} config={config} />}
      <WidgetEditor
        widgetKey={WIDGET_KEY}
        config={config}
        editor={editor}
        competitionId={props.competitionId}
      />
    </>
  );
}

interface Config {
  groups: Array<{ name: string; kpis: KpiOptions[]; key: string }>;
  sheet: string;
  useSameKpis: boolean;
}

function MatchStatsInner(props: Props & { config: Config }) {
  const kpis = props.config.groups.flatMap((g) => g.kpis.map((k) => k.kpi));

  const kpiDataQuery = useKpiData({
    importId: props.importId,
    competitionId: props.competitionId,
    subjectType: 'matches',
    subjects: props.matchTeamIds,
    kpis,
  });

  return (
    <QueryLoader
      data={kpiDataQuery}
      loaded={(data) => {
        if (props.config.useSameKpis) {
          return (
            <SameKpiSetsLayout
              config={props.config}
              kpis={data.rows}
              matchTeamIds={props.matchTeamIds}
            />
          );
        }
        return (
          <DifferentKpiSetsLayout
            config={props.config}
            kpis={data.rows}
            matchTeamIds={props.matchTeamIds}
          />
        );
      }}
    />
  );
}

interface LayoutProps {
  config: Config;
  kpis: KpiData[];
  matchTeamIds: [number, number];
}

function SameKpiSetsLayout(props: LayoutProps) {
  let groups = props.config.groups;
  let [left, right] = props.matchTeamIds;

  const findKpi = (subject: number, kpi: string) =>
    props.kpis.find((k) => k.key === kpi && k.subject_id === subject);

  return (
    <StatsWrap>
      <tbody>
        {groups.map((group) => (
          <React.Fragment key={group.key}>
            <tr>
              <td></td>
              <td
                style={{
                  width: '60%',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {group.name}
              </td>
              <td></td>
            </tr>
            {group.kpis.map((k, _) => (
              <tr key={k.kpi}>
                <td style={{ width: '20%', textAlign: 'right' }}>
                  <KpiValue value={findKpi(left, k.kpi)} options={k} />
                </td>
                <td
                  style={{
                    width: '60%',
                    textAlign: 'center',
                  }}
                >
                  <KpiTitle options={k} />
                </td>
                <td style={{ width: '20%', textAlign: 'left' }}>
                  <KpiValue value={findKpi(right, k.kpi)} options={k} />
                </td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </StatsWrap>
  );
}

function DifferentKpiSetsLayout(props: LayoutProps) {
  const mainColor = AppStore.useState((s) => s.settings.main_color);
  let groups = props.config.groups;
  let [left, right] = props.matchTeamIds;

  const find = (subject: number, kpi: string) =>
    props.kpis.find((k) => k.key === kpi && k.subject_id === subject);

  const leftKpis = props.kpis.filter((k) => k.subject_id === left);
  const rightKpis = props.kpis.filter((k) => k.subject_id === right);

  const leftGroups = groups.filter((g) =>
    g.kpis.every((gk) => leftKpis.map((k) => k.key).includes(gk.kpi))
  );
  const rightGroups = groups.filter((g) =>
    g.kpis.every((gk) => rightKpis.map((k) => k.key).includes(gk.kpi))
  );

  const zipGroups = leftGroups.map(function (e, i) {
    return [e, rightGroups[i]];
  });

  return (
    <StatsWrap>
      <tbody>
        {zipGroups.map((groups, i) => {
          const zipKpis = groups[0].kpis.map(function (e, i) {
            return [e, groups[1].kpis[i]];
          });
          return (
            <React.Fragment key={i}>
              <tr>
                <td
                  style={{
                    width: '30%',
                    textAlign: 'right',
                    fontWeight: 'bold',
                  }}
                >
                  {groups[0].name}
                </td>
                <td
                  style={{
                    width: '40%',
                    textAlign: 'center',
                    color: mainColor,
                  }}
                >
                  KPI {i + 1}
                </td>
                <td
                  style={{
                    width: '30%',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  {groups[1].name}
                </td>
              </tr>
              {zipKpis.map((kpis, j) => (
                <tr key={j}>
                  <td style={{ width: '30%', textAlign: 'right' }}>
                    <KpiTitle options={kpis[0]} />
                  </td>
                  <td
                    style={{
                      width: '40%',
                      textAlign: 'center',
                    }}
                  >
                    <KpiValue
                      value={find(left, kpis[0].kpi)}
                      options={kpis[0]}
                    />
                    {' : '}
                    <KpiValue
                      value={find(right, kpis[1].kpi)}
                      options={kpis[1]}
                    />
                  </td>
                  <td style={{ width: '30%', textAlign: 'left' }}>
                    <KpiTitle options={kpis[1]} />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          );
        })}
      </tbody>
    </StatsWrap>
  );
}

let StatsWrap = styled.table`
  width: 100%;
  margin-top: 8px;
  td {
    padding: 3px 10px;
  }

  td:nth-child(2) {
    font-weight: bold;
  }
`;

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        groups: config?.groups || [],
        sheet: config?.sheet || '',
        useSameKpis: config ? config.useSameKpis : true,
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, void>) {
  let form = props.form;
  let groups = form.values.groups;
  let useSameKpis = form.values.useSameKpis;

  return (
    <>
      <WidgetCheckbox
        form={form}
        field="useSameKpis"
        label="Use same KPIs layout"
        useSameKpis={useSameKpis}
      />
      <KpiGroup
        form={form}
        value={groups}
        field="groups"
        component={KpiListEditor}
        newKpis={() => []}
        innerProps={{
          sheetDisabled: (form: UseFormReturnType<Config>) =>
            form.values.groups.some((g) => g.kpis.length > 0),
        }}
      />
    </>
  );
}
