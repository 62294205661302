import styled from '@emotion/styled';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { DateTime } from 'luxon';
import { QueryLoader } from '../../components/Loader';
import { KpiDataMap, useKpiDataGroupedByKpi, useWidgetConfig } from '../api';
import { EditorStore, KpiMap } from './state';
import { KpiSelectAndEdit, SheetSelect } from './editor/KpiSelect';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { KpiGroup } from './editor/KpiGroup';
import { Divider } from '@mantine/core';
import { KpiOptions } from './editor/KpiOptions';
import { ErrorBoundary } from 'react-error-boundary';
import { KpiValue } from './utils';
import { matchIcon } from './LeaderTable';

interface Props {
  competitionId: number;
  importId: number;
  subjectIds: number[];
  widgetKey: string;
  outcomes: { round: number; outcome: string }[];
}

export function RoundsOverview(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    props.widgetKey
  ).data;

  return (
    <Wrap>
      {
        <WidgetEditor
          widgetKey={props.widgetKey}
          config={config}
          editor={editor}
          competitionId={props.competitionId}
        />
      }
      <ErrorBoundary fallback={<div>Currently unavailable</div>}>
        {config && <MonstrumInner {...props} config={config} />}
      </ErrorBoundary>
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;
  padding-top: 20px;

  .edit-btn {
    align-self: flex-end;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

interface GroupConf {
  target: KpiOptions;
  goals: KpiOptions;
  rank: KpiOptions;
}

export interface Context {
  select: string;
  setSelect: React.Dispatch<React.SetStateAction<string>>;
}

interface Config {
  sheet: string;
  indicator: KpiOptions;
  groups: Array<{ name: string; kpis: GroupConf; key: string }>;
}

export function MonstrumInner(props: Props & { config: Config }) {
  const keyKpi = EditorStore.useState((s) => s.keyKpi);
  const importId = props.importId;
  const competitionId = props.competitionId;
  const widgetConfig = props.config;
  const subjects = props.subjectIds;

  let kpis = [widgetConfig.indicator.kpi];
  widgetConfig.groups.forEach((g) =>
    kpis.push(...[g.kpis.target.kpi, g.kpis.goals.kpi, g.kpis.rank.kpi])
  );

  const mainKpiDataQuery = useKpiDataGroupedByKpi({
    importId,
    competitionId,
    subjectType: widgetConfig.sheet,
    subjects,
    kpis,
  });

  return (
    <QueryLoader
      data={mainKpiDataQuery}
      loaded={(kpiData) => (
        <KpiTableGraphSelect
          kpiData={kpiData}
          kpiInfo={keyKpi}
          config={widgetConfig}
          outcomes={props.outcomes}
        />
      )}
    />
  );
}

export type SelectOpt = 'target' | 'goals' | 'rank';

export function TableSelect(props: {
  value: string;
  onChange: (v: SelectOpt) => void;
}) {
  return (
    <Select
      id="table-select"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value as SelectOpt)}
      sx={{
        background: 'white',
        height: '30px',
      }}
    >
      <MenuItem value="target">Target</MenuItem>
      <MenuItem value="goals">Score</MenuItem>
      <MenuItem value="rank">Rank</MenuItem>
    </Select>
  );
}

interface KpiTableProps {
  kpiInfo: KpiMap;
  kpiData: KpiDataMap;
  config: Config;
  outcomes: { round: number; outcome: string }[];
}

function KpiTableGraphSelect(props: KpiTableProps) {
  const [select, setSelect] = React.useState<SelectOpt>('target');

  let head =
    (props.kpiData[props.config.indicator.kpi] || []).sort(
      (a, b) => new Date(a.period).getTime() - new Date(b.period).getTime()
    ) ?? [];

  const rows = props.config.groups.map((group) => {
    let options = group.kpis[select];
    return {
      value:
        (props.kpiData[options?.kpi] || []).sort(
          (a, b) => new Date(a.period).getTime() - new Date(b.period).getTime()
        ) ?? [],
      options,
    };
  });

  console.log(head, rows);

  return (
    <TableWrap>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell className="stickyCell">
                <TableSelect value={select} onChange={setSelect} />
              </TableCell>
              {head.map((kpiData, index) => (
                <TableCell key={index}>
                  R {index + 1}
                  <br />
                  {matchIcon(
                    props.outcomes.find((o) => o.round === kpiData.value)
                      ?.outcome || '',
                    kpiData.value
                  )}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                className="stickyCell"
                style={{ fontWeight: 'normal' }}
              >
                WEEK
              </TableCell>
              {head.map((value, index) => {
                let date = DateTime.fromISO(value.period.toString());
                return (
                  <TableCell key={index}>
                    {date.toFormat('MMM')}&nbsp;{date.toFormat('dd')}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.config.groups.map((group, i) => (
              <TableRow key={i}>
                <TableCell className="stickyCell">{group.name}</TableCell>
                {head.map(({ period }, j) => {
                  let kpiData = rows[i].value.find((d) => d.period === period);
                  console.log(kpiData);
                  if (kpiData) {
                    return (
                      <TableCell key={j}>
                        <KpiValue value={kpiData} options={rows[i].options} />
                      </TableCell>
                    );
                  } else {
                    return <TableCell key={j}>-</TableCell>;
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrap>
  );
}

let TableWrap = styled.div`
  td,
  th {
    text-align: center;
  }

  .stickyCell {
    position: sticky;
    left: 0;
    background: white;
    z-index: 90;
    mind-width: 20px;
    text-align: left;
  }

  table {
    margin-top: 5px;
  }

  th {
    font-weight: bold;
  }

  th,
  td {
    font-size: 12px;
  }
`;

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        sheet: config?.sheet ?? '',
        indicator: config?.indicator ?? ({} as KpiOptions),
        groups: config?.groups ?? [],
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, { subjectType: string }>) {
  let form = props.form;
  let groups = form.values.groups;

  let sheetDisabled =
    form.values.indicator.kpi != null ||
    groups.some(
      (g) =>
        g.kpis.target.kpi != null ||
        g.kpis.rank.kpi != null ||
        g.kpis.goals.kpi != null
    );
  let sheet = form.values.sheet;

  return (
    <>
      <SheetSelect
        label="Sheet"
        form={form}
        field={'sheet'}
        disabled={sheetDisabled}
      />
      {sheet && (
        <KpiSelectAndEdit
          form={form}
          field="indicator"
          label="Indicator KPI"
          sheet={sheet}
          hasEdit={false}
          hasClear
        />
      )}

      <Divider mt={10} size="md" />

      {sheet && (
        <KpiGroup
          form={form}
          value={groups}
          field="groups"
          component={GroupEditor}
          newKpis={() => ({
            target: {},
            goals: {},
            rank: {},
          })}
          innerProps={{
            sheetDisabled,
            sheet,
          }}
        />
      )}
    </>
  );
}

interface GroupEditorProps {
  form: UseFormReturnType<any>;
  field: string;
  sheet: string;
}

function GroupEditor(props: GroupEditorProps) {
  let { form, field, sheet } = props;

  return (
    <Stack>
      <Divider mt={10} mb={10} />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.target`}
        label="Target"
        sheet={sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.goals`}
        label="Score"
        sheet={sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.rank`}
        label="Rank"
        sheet={sheet}
      />
    </Stack>
  );
}
