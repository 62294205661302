import CircularProgress from '@mui/material/CircularProgress';
import { Data } from '../core/data';
import styled from '@emotion/styled';
import { UseQueryResult } from '@tanstack/react-query';

// TODO ability to show old data and progress overlay

interface Props<T> {
  data: Data<T>;
  loaded: (data: T) => JSX.Element;
}

export function Loader<T>(props: Props<T>) {
  let { data, loaded } = props;

  if (data.data == null) {
    if (data.error != null) {
      return (
        <div style={{ paddingTop: '50px', textAlign: 'center' }}>
          {data.error.message}
        </div>
      );
    } else {
      return (
        <Progress>
          <CircularProgress />
        </Progress>
      );
    }
  } else {
    return loaded(data.data);
  }
}

export const Progress = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
`;

interface QueryProps<T> {
  data: UseQueryResult<T, any>;
  loaded: (data: T) => JSX.Element;
}

export function QueryLoader<T>(props: QueryProps<T>) {
  const { isLoading, isError, data, error } = props.data;

  if (isLoading) {
    return (
      <Progress>
        <CircularProgress />
      </Progress>
    );
  }

  if (isError) {
    return (
      <div style={{ paddingTop: '50px', textAlign: 'center' }}>
        {error.message}
      </div>
    );
  }

  return props.loaded(data!);
}
