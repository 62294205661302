import styled from '@emotion/styled';
import { AddAPhoto, Cancel } from '@mui/icons-material';
import {
  Backdrop,
  Button,
  CardMedia,
  CircularProgress,
  IconButton,
  Input,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar, VariantType } from 'notistack';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Post } from '../components/Post';
import { AppStore, mediaAsset } from '../state';
import { siteData } from '../util';
import { CharLimit } from './WallFeed';

const MAX_POST_LEN = 280;
const MAX_ROAR_LEN = 50;

export default function EditPost() {
  const location = useLocation();
  const history = useHistory();
  const state = location.state as { post: Post } | undefined;
  const { competitionId } = AppStore.useState((s) => s);
  const { enqueueSnackbar } = useSnackbar();

  const showSnackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, { variant });
  };

  const post = state === undefined ? null : state.post;
  const [img, setImg] = React.useState(post?.file || null); // old file
  const [file, setFile] = React.useState(null); // new file
  const [fileURL, setFileURL] = React.useState('');
  const [text, setText] = React.useState<string>(post?.text || '');
  const [type, setType] = React.useState<string>(post?.file_format || '');
  const [postInProgress, setPostInProgress] = React.useState(false);

  const [extension, setExtension] = React.useState<string>('');
  function handleUpload(event: any) {
    setFile(event.target.files[0]);
    setImg(null);
    setType(event.target.files[0].type);
    setFileURL(URL.createObjectURL(event.target.files[0]));
    setExtension(event.target.files[0].name.split('.').pop().toLowerCase());
  }

  function handleText(event: any) {
    setText(event.target.value);
  }

  function handleBack() {
    history.push('/arena');
  }

  if (post === null) {
    history.push('/');
  }

  const roar = post!.is_gong;

  async function submit(e: any) {
    let data = new FormData();
    if ((file === null || roar) && text === '') return;
    if (file !== null && !roar) {
      data.append('file', file as any);
      data.append('type', type);
      data.append('extension', extension);
    } else if (img !== null && !roar) {
      data.append('old_file', '');
    }
    if (text !== '') {
      data.append('text', text);
    }
    if (post?.player_id) {
      data.append('player_id', post?.player_id.toString() || '');
    }

    try {
      let request = axios.put(
        `/api/competitions/${competitionId}/posts/${post?.post_id}`,
        data,
        {
          headers: {
            accept: 'application/json',
            'content-type': `multipart/form-data`,
            'Gamifier-Platform': siteData().platform.toString(),
          },
        }
      );
      setPostInProgress(true);
      await request;
      setPostInProgress(false);
      setImg(null);
      setFile(null);
      setText('');
      showSnackBar('Updated', 'success');
      handleBack();
    } catch (e: any) {
      console.log(e);
      setPostInProgress(false);
      showSnackBar('Cannot update post at this time.', 'error');
    }
  }

  const MAX_LEN = roar ? MAX_ROAR_LEN : MAX_POST_LEN;

  let is_video = type.startsWith('video');

  return (
    <FormWrap>
      <Backdrop
        open={postInProgress}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      <form>
        <FormContainer>
          <div>
            <Input
              multiline
              placeholder="What's happening?"
              fullWidth
              value={text}
              onChange={handleText}
            />
            {!roar && file !== null && (
              <ImagePreview>
                {(is_video && (
                  <CardMedia
                    component="video"
                    src={fileURL}
                    controls
                    preload="metadata"
                  />
                )) || <CardMedia component="img" src={fileURL} alt="File" />}
                <IconButton onClick={() => setFile(null)}>
                  <Cancel />
                </IconButton>
              </ImagePreview>
            )}
            {!roar && img !== null && (
              <ImagePreview>
                {(is_video && (
                  <CardMedia
                    component="video"
                    src={mediaAsset(img)}
                    controls
                    preload="metadata"
                  />
                )) || (
                  <CardMedia component="img" src={mediaAsset(img)} alt="File" />
                )}
                <IconButton onClick={() => setImg(null)}>
                  <Cancel />
                </IconButton>
              </ImagePreview>
            )}
          </div>
          <div>
            {!roar && (
              <label htmlFor="icon-button-file">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="icon-button-file"
                  type="file"
                  onChange={handleUpload}
                />
                <IconButton aria-label="upload photo" component="span">
                  <AddAPhoto sx={{ width: '20px', height: '20px' }} />
                </IconButton>
              </label>
            )}
            <CharLimit style={text.length > MAX_LEN ? { color: 'red' } : {}}>
              {text.length} / {MAX_LEN}
            </CharLimit>
            <ButtonsWrap>
              <Button variant="contained" color="primary" onClick={handleBack}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={submit}
                disabled={
                  ((file === null || roar) && text === '') ||
                  text.length > MAX_LEN
                }
              >
                Update
              </Button>
            </ButtonsWrap>
          </div>
        </FormContainer>
      </form>
    </FormWrap>
  );
}

const FormWrap = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;

  form {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  > div:last-child {
    display: flex;
    justify-content: space-between;
  }

  padding-bottom: 100px;
`;

const ImagePreview = styled.div`
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    margin: 10px auto 10px auto;
  }

  button {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  gap: 5px;
`;
