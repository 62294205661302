import styled from '@emotion/styled';
import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import axios from 'axios';
import React, { Dispatch, SetStateAction } from 'react';
import { Loader } from '../components/Loader';
import { AppStore, Team, teamListLoader } from '../state';

interface Props {
  teams: Team[];
  competition: number;
}

function TeamPlaceholder() {
  return <em>Select Team</em>;
}

function renderTeam(params: GridRenderCellParams<number>, teams: Team[]) {
  let team = teams.find((t) => t.team_id === params.value);
  if (team) {
    return team.name;
  } else {
    return <TeamPlaceholder />;
  }
}

function renderEditTeam(params: GridRenderEditCellParams, teams: Team[]) {
  const { id, value, api, field } = params;
  const handleChange = (event: SelectChangeEvent<number>) => {
    api.setEditCellValue(
      { id, field, value: Number(event.target.value) },
      event
    );
    api.commitCellChange({ id, field });
    api.setCellMode(id, field, 'view');
  };
  return (
    <Select
      labelId="team-select-label"
      id="team-select"
      value={value as number}
      label="Team"
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected === 0) {
          return <TeamPlaceholder />;
        }

        return selected;
      }}
    >
      <MenuItem disabled value={0}>
        <TeamPlaceholder />
      </MenuItem>
      {teams.map((t: Team) => (
        <MenuItem key={t.team_id} value={t.team_id}>
          {t.name}
        </MenuItem>
      ))}
    </Select>
  );
}

function ImportTable(props: Props) {
  const { teams, competition } = props;
  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      minWidth: 200,
    },
    {
      field: 'team',
      headerName: 'Team',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      type: 'number',
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<number>) =>
        renderTeam(params, teams),
      renderEditCell: (params: GridRenderEditCellParams) =>
        renderEditTeam(params, teams),
    },
    {
      field: 'captain',
      headerName: 'Is Captain',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      type: 'boolean',
    },
  ];
  const playerInit: Array<any> = [
    { id: 0, email: 'example@mail.com', name: 'Name', team: 0, captain: false },
  ];
  let [players, setPlayers] = React.useState(playerInit);

  const updateDataCallback = React.useCallback(
    async (
      params: GridCellEditCommitParams,
      players: Array<any>,
      setPlayers: Dispatch<SetStateAction<any[]>>
    ) => {
      const { id, field, value } = params;
      let partial: any = {};
      partial[field] = value;
      let copyPlayers = players.slice();
      copyPlayers[id as number] = Object.assign(players[id as number], partial);
      setPlayers(copyPlayers);
    },
    []
  );

  return (
    <Wrap>
      <DataGrid
        density="compact"
        disableSelectionOnClick
        hideFooter
        columns={columns}
        rows={players}
        onCellEditCommit={(params: GridCellEditCommitParams) =>
          updateDataCallback(params, players, setPlayers)
        }
      />
      <WrapButtons>
        <InlineButtons>
          <Button
            variant="contained"
            onClick={() => {
              let newPlayers = players.slice();
              newPlayers.push({
                id: players.length,
                name: 'Name',
                email: 'example@mail.com',
                team: 0,
                captain: false,
              });
              setPlayers(newPlayers);
            }}
          >
            Add Player
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              let newPlayers = players.slice();
              newPlayers.pop();
              setPlayers(newPlayers);
            }}
          >
            Remove Player
          </Button>
        </InlineButtons>
        <Button
          variant="contained"
          onClick={() => postImportData(players, competition)}
        >
          Import
        </Button>
      </WrapButtons>
    </Wrap>
  );
}

async function postImportData(players: Array<any>, competition: number) {
  let data = {
    players: players.map((p) => {
      return { name: p.name, email: p.email, team: p.team, captain: p.captain };
    }),
  };
  console.log(data);
  try {
    await axios.post('/djapi/v1/import/', data);
  } catch (e: any) {
    console.log(e);
    // TODO error handle
  }
}

export default function ImportPlayers() {
  const { competitionId } = AppStore.useState((s) => s);
  const { teams } = AppStore.useState((s) => s.pageData);
  React.useEffect(() => {
    teamListLoader({});
  }, [competitionId]);
  return (
    <Loader
      data={teams}
      loaded={(teams) => (
        <ImportTable teams={teams} competition={competitionId} />
      )}
    />
  );
}

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 150px);
`;

let WrapButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

let InlineButtons = styled.div`
  display: flex;
  flex-diection: row;
  justify-content: space-between;
  padding-bottom: 10px;
`;
