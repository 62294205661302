import styled from '@emotion/styled';
import Header from '../components/NarrowHeader';
import { useTitle, AppStore } from '../state';
import RichText from '../components/RichText';
import { Loader } from '../components/Loader';

export default function CompetitionRules() {
  useTitle('Rules', '/');
  let competition = AppStore.useState((s) => s.competition);
  const settings = AppStore.useState((s) => s.settings);
  let baseCompetition = AppStore.useState((s) => s.baseCompetition);
  let rulesHeader = settings.rules_header_background_img;
  let headerColor = settings.team_header_background;

  return (
    <Wrap>
      <Header
        title={rulesHeader ? '' : baseCompetition.name}
        background={rulesHeader}
        headerColor={headerColor}
      />

      <Loader
        data={competition}
        loaded={(competition) => (
          <RichText className="rich-text" html={competition.rules} />
        )}
      />
    </Wrap>
  );
}

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  height: calc(100% - 80px);
  overflow: hidden;

  .rich-text {
    overflow: scroll;
  }
`;
