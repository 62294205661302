import { Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useSnackbar, VariantType } from 'notistack';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '..';
import { CommentCard } from '../components/CommentCard';
import { NewCommentForm } from '../components/NewCommentForm';
import { AppStore, UserPlayer } from '../state';
import { siteData } from '../util';
import { QueryLoader } from './Loader';
import { invalidatePost } from './Post';

interface Props {
  postId: number;
}

export interface Comment {
  comment_id: number;
  created_at: Date;
  updated_at: Date | null;
  text: string;
  player_id: number;
  player_name: string;
  player_photo: string;
  likes: number;
  comments: number;
  is_liked: boolean;
  is_owned: boolean;
}

const getCommentByPostId = async (
  competitionId: number,
  postId: number
): Promise<Comment[]> => {
  const { data } = await axios.get<Comment[]>(
    `/api/competitions/${competitionId}/posts/${postId}/comments`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
  return data;
};

export const useComment = (competitionId: number, postId: number) => {
  return useQuery(['comment', competitionId, postId], () =>
    getCommentByPostId(competitionId, postId)
  );
};

export const invalidateUseComment = (competitionId: number, postId: number) => {
  queryClient.invalidateQueries(['comment', competitionId, postId]);
};

export const deleteCommentById = async (
  competitionId: number,
  commentId: number
) => {
  await axios.delete(
    `/api/competitions/${competitionId}/comments/${commentId}`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
};

export const updateCommentById = async (
  competitionId: number,
  commentId: number,
  text: string
) => {
  let data = { text };
  await axios.put(
    `/api/competitions/${competitionId}/comments/${commentId}`,
    data,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
};

export default function PostCommentsComponent(props: Props) {
  const { postId } = props;
  const [postInProgress, setPostInProgress] = React.useState(false);
  const { competitionId } = AppStore.useState((s) => s);
  const player = AppStore.useState((s) => s.user?.player);
  const comments = useComment(competitionId, postId);
  const { enqueueSnackbar } = useSnackbar();
  const showSnackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, { variant });
  };

  async function submit(text: string) {
    try {
      let data = { text };
      let request = axios.post(
        `/api/competitions/${competitionId}/posts/${postId}/comments`,
        data,
        {
          headers: {
            'Gamifier-Platform': siteData().platform.toString(),
          },
        }
      );
      setPostInProgress(true);
      await request;
      setPostInProgress(false);
      showSnackBar('Posted a comment', 'success');
      invalidateUseComment(competitionId, postId);
      invalidatePost(competitionId, postId);
      return true;
    } catch (e: any) {
      console.log(e);
      setPostInProgress(false);
      showSnackBar('Cannot post a comment at this time.', 'error');
      return false;
    }
  }

  return (
    <>
      <Backdrop
        open={postInProgress}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      <NewCommentForm player={player} submit={submit} />
      <QueryLoader
        data={comments}
        loaded={(data) => (
          <ListPostComments
            player={player}
            comments={data}
            postId={postId}
            competitionId={competitionId}
          />
        )}
      />
    </>
  );
}

interface ListProps {
  player?: UserPlayer;
  competitionId: number;
  postId: number;
  comments: Comment[];
}

function ListPostComments(props: ListProps) {
  return (
    <div>
      {props.comments.map((c, index) => (
        <CommentCard {...props} key={index} comment={c} />
      ))}
    </div>
  );
}
