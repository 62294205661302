import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { FadeElement } from './FadeElement';

interface Props {
  topLeftLogo?: string;
  topRightLogo?: string;
  centerLogo?: string;
  centerLogoAlt?: string;
  name: string;
  narrow?: boolean;
  className?: string;
  background_color: string;
  background_image: string;
  id?: string;
}

export default function Header(props: Props) {
  return (
    <Background
      id={props.id}
      className={(props.className ?? '') + ' page-header'}
      background_color={props.background_color}
      background_image={props.background_image}
    >
      <div>
        <Row>
          {props.topLeftLogo && (
            <Image src={props.topLeftLogo} style={{ left: '10px' }} />
          )}
          {props.topRightLogo && (
            <Image src={props.topRightLogo} style={{ right: '10px' }} />
          )}
        </Row>

        {props.centerLogo && !props.centerLogoAlt && (
          <img
            className="logo"
            src={props.centerLogo}
            style={{ margin: props.narrow ? '0px' : '20px' }}
            alt="Logo"
          />
        )}

        {props.centerLogo && props.centerLogoAlt && (
          <div className="flip_logo" style={{ height: '80%' }}>
            <FadeElement
              front={<img src={props.centerLogo} alt="Logo" />}
              back={<img src={props.centerLogoAlt} alt="Logo" />}
              width="100%"
              height="80%"
            />
          </div>
        )}

        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '20px',
            mb: 2,
            textAlign: 'center',
          }}
          variant="h3"
        >
          {props.name}
        </Typography>
      </div>
    </Background>
  );
}

interface BackgroundProps {
  background_color: string;
  background_image: string;
}

let Background = styled.div<BackgroundProps>`
  padding: 66.6667% 0 0 0;
  position: relative;
  background-color: ${(props) => props.background_color};
  background-image: url(${(props) => props.background_image});
  background-size: cover;
  color: white;

  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  img.logo {
    height: 65%;
    object-fit: contain;
  }

  .flip_logo {
    position: relative;
  }

  .flip_logo > * {
    top: 20px;
    height: 80% !important;
    transform-style: preserve-3d !important;
    -webkit-transform-style: preserve-3d !important;
  }

  .flip_logo img {
    height: 100%;
  }
`;

let Row = styled.div`
  flex-flow: row;
  width: 100%;
`;

let Image = styled.img`
  width: 150px;
  position: absolute;
  top: 10px;
`;
