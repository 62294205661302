import styled from '@emotion/styled';
import React from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import Header from '../components/Header';
import HomeCard from '../components/HomeCard';
import { Loader } from '../components/Loader';
import NewsComponent from '../components/News';
import {
  AppStore,
  Competition,
  mediaAsset,
  newsLoader,
  refreshMainData,
  useTitle,
} from '../state';

interface Props {
  competition: Competition;
}

function HomeComponent(props: Props) {
  const { competition } = props;
  const { settings, competitionId } = AppStore.useState((s) => s);
  const { news } = AppStore.useState((s) => s.pageData);
  React.useEffect(() => {
    newsLoader({});
  }, [competitionId]);
  let header_color = settings.team_header_background;
  let header_image = settings.team_header_background_img;

  return (
    <>
      <Header
        id="home-header"
        name={competition.name}
        centerLogo={mediaAsset(competition.logo)}
        topRightLogo={settings.company_logo}
        background_color={header_color}
        background_image={header_image}
      />
      <HomeWrap>
        <HomeCard
          name={competition.name}
          description={competition.description}
        />
        <Loader
          data={news}
          loaded={(data) => (
            <NewsComponent competition={competition} news={data} />
          )}
        />
      </HomeWrap>
    </>
  );
}

export default function Home() {
  useTitle('Home');
  let competition = AppStore.useState((s) => s.competition);

  return (
    <Wrap>
      <PullToRefresh onRefresh={() => refreshMainData()} pullingContent="">
        <Loader
          data={competition}
          loaded={(data) => <HomeComponent competition={data} />}
        />
      </PullToRefresh>
    </Wrap>
  );
}

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
`;

let HomeWrap = styled.div`
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
        margin: 10px;
  }

      img.MuiCardMedia-root {
        object - fit: contain;
  }
      `;
