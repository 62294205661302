import React from 'react';
import { refreshMainData, useTitle } from '../state';
import queryString from 'query-string';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { siteData } from '../util';
import styled from '@emotion/styled';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CreateOTPDeviceForm, {
  OTPDeviceResponse,
} from '../components/CreateOTPDeviceForm';
import ConfirmOTPDeviceForm from '../components/ConfirmOTPDeviceForm';
import ScanOTPDevice from '../components/ScanOTPDevice';
import { VisibilityOff, Visibility } from '@mui/icons-material';

const qs = queryString.parse(window.location.search);

const authValidationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const otpValidationSchema = yup.object({
  otp_device: yup.string().required('OTP Device is required'),
  otp_token: yup.string().required('OTP Token is required'),
});

export default function Login2FA() {
  useTitle('Login');
  let [error, setError] = React.useState<string | null>(null);
  let [auth, setAuth] = React.useState(false);
  let [devices, setDevices] = React.useState<{ id: string; name: string }[]>(
    []
  );
  let [newDevice, setNewDevice] = React.useState<OTPDeviceResponse | null>(
    null
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const routeChange = () => {
    window.location.href =
      `/api/auth/login/azure/` + siteData().platform.toString();
  };

  let authForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values: any) => {
      setError(null);
      try {
        let resp = await axios.post('/djapi/v1/auth_2fa/', values, {
          headers: { 'Gamifier-Platform': siteData().platform.toString() },
        });
        let data = resp.data as {
          otp_devices: { id: string; name: string }[];
          has_2fa_enabled: boolean;
        };
        setDevices(data.otp_devices);
        if (!data.has_2fa_enabled && !siteData().force2fa) {
          let loginResp = await axios.post('/djapi/v1/login/', values, {
            headers: { 'Gamifier-Platform': siteData().platform.toString() },
          });
          let loginData = loginResp.data as {
            is_verified: boolean;
            is_active: boolean;
          };
          if (!loginData.is_verified) {
            setError(
              'Verify your e-mail address in order to log in. Check your e-mail for instructions.'
            );
          }
          if (loginData.is_verified && !loginData.is_active) {
            setError(
              'Please wait until your account is verified and activated.'
            );
          }
          if (loginData.is_verified && loginData.is_active) {
            if (qs.next && typeof qs.next === 'string')
              window.location.replace(qs.next);
            await refreshMainData();
          }
        } else {
          setAuth(true);
        }
      } catch (e: any) {
        setAuth(false);
        if (e.response && e.response.status === 403) {
          setError(e.response.data.message);
        } else {
          setError(
            'Failed to check login data. Please try again or contact us.'
          );
        }
      }
    },
  });

  const otpAuthForm = useFormik({
    initialValues: {
      otp_device: '',
      otp_token: '',
    },
    validationSchema: otpValidationSchema,
    onSubmit: async (values: any) => {
      setError(null);
      try {
        let data = { ...authForm.values, ...values };
        await axios.post('/djapi/v1/login_2fa/', data, {
          headers: { 'Gamifier-Platform': siteData().platform.toString() },
        });
        await refreshMainData();
      } catch (e: any) {
        if (e.response && e.response.status === 403) {
          setError(e.response.data.message);
        } else {
          setError(
            'Failed to check login data. Please try again or contact us.'
          );
        }
      }
    },
  });

  // TODO fix warning, infinite render if otpAuthForm is included
  React.useEffect(() => {
    otpAuthForm.setValues({
      otp_device: devices.length > 0 ? devices[0].id : '',
      otp_token: '',
    });
  }, [devices]);

  const AuthForm = (
    <Wrap>
      <img
        style={{ maxWidth: '280px', margin: '60px auto 30px auto' }}
        src={require('../imgs/leaguestar-logo-vertical-dark.png')}
        alt="Leaguestar logo"
      />
      <form onSubmit={authForm.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={authForm.values.email}
          onChange={authForm.handleChange}
          error={authForm.touched.email && Boolean(authForm.errors.email)}
          helperText={authForm.touched.email && authForm.errors.email}
        />

        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={authForm.values.password}
            onChange={authForm.handleChange}
            error={
              authForm.touched.password && Boolean(authForm.errors.password)
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <Button color="primary" variant="contained" fullWidth type="submit">
          Log in
        </Button>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          className="px-4"
          onClick={routeChange}
        >
          Log in with SSO
        </Button>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </form>

      <p>
        Don't have an account? <Link to="/register">Register&nbsp;here.</Link>
        <br />
        If you have forgotten your password, you can{' '}
        <Link to="/reset_password">reset it here.</Link>
      </p>
    </Wrap>
  );

  const OTPForm = (
    <Wrap>
      <form onSubmit={otpAuthForm.handleSubmit}>
        <Select
          fullWidth
          id="otp_device"
          name="otp_device"
          label="OTP Device"
          value={otpAuthForm.values.otp_device}
          error={
            otpAuthForm.touched.otp_device &&
            Boolean(otpAuthForm.errors.otp_device)
          }
          onChange={otpAuthForm.handleChange}
        >
          {devices.map((d, i) => (
            <MenuItem key={i} value={d.id}>
              {d.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          id="otp_token"
          name="otp_token"
          label="OTP Token"
          type="text"
          value={otpAuthForm.values.otp_token}
          onChange={otpAuthForm.handleChange}
          error={
            otpAuthForm.touched.otp_token &&
            Boolean(otpAuthForm.errors.otp_token)
          }
          helperText={
            otpAuthForm.touched.otp_token && otpAuthForm.errors.otp_token
          }
        />
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </form>
    </Wrap>
  );

  if (!auth) {
    return AuthForm;
  }

  if (devices.length === 0) {
    return (
      <Wrap>
        {newDevice ? (
          <>
            <ScanOTPDevice device={newDevice} />
            <ConfirmOTPDeviceForm
              device={newDevice}
              {...authForm.values}
              onSuccess={async () => {
                setError(null);
                try {
                  await axios.post('/djapi/v1/login/', authForm.values, {
                    headers: {
                      'Gamifier-Platform': siteData().platform.toString(),
                    },
                  });
                  await refreshMainData();
                } catch (e: any) {
                  if (e.response && e.response.status === 403) {
                    setError(e.response.data.message);
                  } else {
                    setError(
                      'Cannot log in at this time. Please try again or contact us.'
                    );
                  }
                }
              }}
              onError={(message) => {
                if (message) {
                  setError(message);
                } else {
                  setError(
                    'Cannot confirm the Two Factor Device at this time.'
                  );
                }
              }}
            />
          </>
        ) : (
          <CreateOTPDeviceForm
            onSuccess={(device) => {
              setError(null);
              setNewDevice(device);
            }}
            onError={(message) => {
              if (message) {
                setError(message);
              } else {
                setError(
                  'Cannot create a Two Factor Device at this time. Try again later or contact support.'
                );
              }
            }}
            {...authForm.values}
          />
        )}
        {error && <FormHelperText error>{error}</FormHelperText>}
        <FormHelperText>
          Two Factor Authentification is required to log in. Create a Two Factor
          Device and add it to Google Authenticator app. Make sure to confirm
          the token via Google Authenticator app in order to log in, or
          otherwise you will be locked out from your account.
        </FormHelperText>
      </Wrap>
    );
  }

  return OTPForm;
}

const Wrap = styled.div`
  & > p {
    padding: 20px;
    color: #444;
  }

  & > p > a {
    color: #444;
  }

  form {
    padding: 20px;
  }

  form > * {
    margin: 10px 0;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .QRCodeScan {
    padding: 20px;

    div {
      padding: 0;
    }

    div > * {
      margin: 8px 0;
      width: 100%;
    }
  }
`;
