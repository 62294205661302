import * as React from 'react';
import styled from '@emotion/styled';
import {
  AppStore,
  Competition,
  mediaAsset,
  PlayerInfo,
  useTitle,
} from '../../state';
import { useForm } from '@mantine/form';
import { QueryLoader } from '../../components/Loader';
import Header from '../../components/Header';
import { Avatar, Box, Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { usePlayers } from '../api/players';
import { siteData } from '../../util';
import { Link } from 'react-router-dom';
import { FadeElement } from '../../components/FadeElement';
import { KpiData, useLeaderboardKpiData, useWidgetConfig } from '../api';
import { EditorProps, WidgetEditor } from '../widgets/WidgetEditor';
import { KpiOptions } from '../widgets/editor/KpiOptions';
import { KpiAdd, KpiList } from '../widgets/editor/KpiList';
import { Center, Divider as MantineDivider } from '@mantine/core';
import { KpiValue } from '../widgets/utils';

interface Config {
  sheet: string;
  firstRank: boolean;
  kpis: KpiOptions[];
}

export function Players(props: { competition: Competition }) {
  useTitle('Players', '/');
  let competition = props.competition;
  let settings = AppStore.useState((s) => s.settings);
  let header_color = settings.team_header_background;
  let header_image = settings.team_header_background_img;
  let players_header_image = settings.players_header_img;

  return (
    <Wrap>
      {players_header_image ? (
        <Header
          name=""
          className="player-header"
          background_color={header_color}
          background_image={players_header_image}
        />
      ) : (
        <Header
          name={competition.name}
          className="player-header"
          centerLogo={mediaAsset(competition.logo)}
          topRightLogo={settings.company_logo}
          background_color={header_color}
          background_image={header_image}
        />
      )}

      <PlayersMain competition={competition} />
    </Wrap>
  );
}

export function PlayersMain(props: {
  competition: Competition;
  teamId?: number;
  widgetKey?: string;
}) {
  let competition = props.competition;
  let teamId = props.teamId;
  let players = usePlayers(competition.competition_id);
  let widgetKey = props.widgetKey || 'players_page';
  let config: Config | undefined = useWidgetConfig(
    competition.competition_id,
    widgetKey
  ).data;
  return (
    <>
      <WidgetEditor
        widgetKey={widgetKey}
        config={config}
        editor={editor}
        competitionId={competition.competition_id}
      />
      <QueryLoader
        data={players}
        loaded={(players) =>
          config ? (
            <PlayerListLoader
              competitionId={competition.competition_id}
              importId={competition.active_import_id || 0}
              config={config}
              kpis={[]}
              players={players.filter(
                (p) => teamId == null || p.team_id === teamId
              )}
              search
            />
          ) : (
            <PlayerList
              competitionId={competition.competition_id}
              importId={competition.active_import_id || 0}
              config={config}
              kpis={[]}
              players={players.filter(
                (p) => teamId == null || p.team_id === teamId
              )}
              search
            />
          )
        }
      />
    </>
  );
}

interface PlayerListProps {
  players: PlayerInfo[];
  competitionId: number;
  importId: number;
  kpis: KpiData[];
  search: boolean;
  config?: Config;
}

export function PlayerListLoader(props: PlayerListProps) {
  const competitionId = props.competitionId;
  const subjects = props.players.map((p) => p.player_id);
  const subjectType = props.config?.sheet || '';
  const kpis = (props.config?.kpis || []).map((k) => k.kpi);
  const kpiQuery = useLeaderboardKpiData(
    competitionId,
    kpis,
    subjectType,
    subjects,
    props.importId
  );
  return (
    <QueryLoader
      data={kpiQuery}
      loaded={(kpiRows) => {
        return <PlayerList {...props} kpis={kpiRows.rows} />;
      }}
    />
  );
}

export function PlayerList(props: PlayerListProps) {
  const [search, setSearch] = React.useState<string>('');
  const loadedPlayers = props.players;
  const rankKpi = props.config?.kpis[0]?.kpi;
  const otherKpis = React.useMemo(
    () => props.config?.kpis?.slice(1) || [],
    [props.config]
  );
  const rankMap = React.useMemo(
    () =>
      new Map(
        props.kpis
          .filter((k) => k.key === rankKpi)
          .map((k) => [k.subject_id, k.value])
      ),
    [props.kpis, rankKpi]
  );
  const kpiMap = React.useMemo(
    () =>
      new Map(
        otherKpis.map(({ kpi }) => [
          kpi,
          new Map(
            props.kpis
              .filter((k) => k.key === kpi)
              .map((k) => [k.subject_id, k.value])
          ),
        ])
      ),
    [otherKpis, props.kpis]
  );
  const hasRank = rankKpi && props.kpis.some((k) => k.key === rankKpi);
  const rankTitle = hasRank ? props.config?.kpis[0].title : null;
  const [sortCol, setSortCol] = React.useState<number>(hasRank ? 0 : 1);
  const [sortDir, setSortDir] = React.useState<1 | -1>(1);
  const setSort = React.useCallback(
    (colIndex: number, sort: 1 | -1) => {
      setSortCol(colIndex);
      setSortDir(sort);
    },
    [setSortCol, setSortDir]
  );

  const players = React.useMemo(() => {
    let players = loadedPlayers!.filter((p) => {
      let searchRegex = new RegExp(search, 'giu'); // global, case-insensitive, unicode
      return searchRegex.test(p.name);
    });
    players.sort((a, b) => {
      if (sortCol === 0) {
        let aRank = rankMap.get(a.player_id);
        let bRank = rankMap.get(b.player_id);
        if (aRank == null) return 1;
        if (bRank == null) return -1;
        return (aRank - bRank) * sortDir;
      } else if (sortCol === 1) {
        return (a.name < b.name ? -1 : 1) * sortDir;
      } else {
        let aKpi = kpiMap.get(otherKpis[sortCol - 2].kpi)?.get(a.player_id);
        let bKpi = kpiMap.get(otherKpis[sortCol - 2].kpi)?.get(b.player_id);
        if (aKpi == null) return 1;
        if (bKpi == null) return -1;
        return (aKpi - bKpi) * sortDir;
      }
    });
    return players;
  }, [loadedPlayers, search, sortCol, sortDir, rankMap, otherKpis, kpiMap]);

  let [limit, setLimit] = React.useState(20);

  return (
    <div>
      {props.search && (
        <Box sx={{ padding: '20px 15px 15px 15px' }}>
          <TextField
            id="search-players"
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
      )}
      <PlayerListWrap>
        <PlayerListHeader>
          {hasRank && (
            <HeaderCol
              colIndex={0}
              onClick={setSort}
              sort={sortCol === 0 ? sortDir : null}
            >
              {rankTitle || (
                <>
                  League
                  <br />
                  Rank
                </>
              )}
            </HeaderCol>
          )}
          <span></span>
          <HeaderCol
            colIndex={1}
            onClick={setSort}
            sort={sortCol === 1 ? sortDir : null}
          >
            Player Name
          </HeaderCol>
          {otherKpis.map((k, i) => (
            <HeaderCol
              key={k.kpi}
              className="kpi"
              colIndex={i + 2}
              onClick={setSort}
              sort={sortCol === i + 2 ? sortDir : null}
            >
              {k.title}
            </HeaderCol>
          ))}
          <span>Team</span>
        </PlayerListHeader>
        {players.slice(0, limit).map((p) => (
          <Link
            key={p.player_id}
            className="row"
            to={
              siteData().v2
                ? `/competition/${props.competitionId}/teams/${p.team_id}/players/${p.player_id}`
                : `/teams/${p.team_id}?player_id=${p.player_id}`
            }
          >
            {hasRank && (
              <span className="rank">{rankMap.get(p.player_id) || '-'}</span>
            )}
            <span className="playerImage">
              <Avatar
                src={mediaAsset(p.photo)}
                sx={{ width: 40, height: 40 }}
              />
            </span>
            <span className="name">{p.name}</span>
            {otherKpis.map((k) => (
              <span key={k.kpi} className="kpi">
                <KpiValue
                  value={kpiMap.get(k.kpi)?.get(p.player_id)}
                  options={k}
                />
              </span>
            ))}
            <span className="teamLogo">
              <FadeElement
                front={
                  <img
                    src={
                      p.team_logo
                        ? mediaAsset(p.team_logo)
                        : require('../../imgs/team_logo_placeholder.png')
                    }
                    alt="team"
                  />
                }
                back={
                  p.team_alt_logo !== '' ? (
                    <img src={mediaAsset(p.team_alt_logo)} alt="alt_logo" />
                  ) : null
                }
                width={'40px'}
                height={'40px'}
              />
            </span>
          </Link>
        ))}
      </PlayerListWrap>

      {limit < players.length && (
        <div className="loadMore">
          <Button onClick={() => setLimit(limit + 20)}>Show More</Button>
        </div>
      )}
    </div>
  );
}

let Wrap = styled.div`
  .page-header.player-header {
    padding: 25% 0 0 0;

    img {
      height: 50%;
      margin: 20px 10px 10px 10px !important;
    }
  }

  & > div {
    padding-bottom: 100px;
  }

  .loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
`;

let PlayerListWrap = styled.div`
  display: table;
  width: 100%;

  & > * {
    display: table-row;
    width: 100%;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.43;
    font-size: 12px;
    padding: 10px 15px;
  }

  & > * > * {
    display: table-cell;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(224, 224, 224);
  }

  & > a > *:first-child {
    padding-left: 15px;
  }

  & > a > *:last-child {
    padding-right: 15px;
  }

  & > a:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  .playerImage {
    padding-right: 20px;
    width: 40px;
  }

  .name {
    vertical-align: middle;
  }

  .teamLogo {
    width: 40px;
    padding-left: 10px;
  }

  .rank {
    width: 30px;
    vertical-align: middle;
    font-size: 1.1em;
    font-weight: bold;
  }

  .kpi {
    width: 50px;
    vertical-align: middle;
    text-align: right;
    padding-right: 10px;
    font-size: 1em;
  }

  & .name {
    flex: 1;
  }
`;

let PlayerListHeader = styled.div`
  span {
    padding-bottom: 20px;
    font-weight: bold;
    vertical-align: middle;
  }

  span:first-child {
    padding-left: 15px;
    min-width: 65px;
  }

  span:last-child {
    padding-right: 15px;
    padding-left: 10px;
  }
`;

interface HeaderColProps {
  children: React.ReactNode;
  className?: string;
  colIndex: number;
  onClick: (colIndex: number, sort: 1 | -1) => void;
  sort: 1 | -1 | null;
}

function HeaderCol(props: HeaderColProps) {
  let { sort, colIndex, onClick } = props;
  return (
    <HeaderColWrap
      className={props.className}
      onClick={() => onClick(colIndex, (sort ? sort * -1 : 1) as -1 | 1)}
    >
      {props.children}
      &nbsp;
      {sort && (sort === 1 ? '▲' : '▼')}
    </HeaderColWrap>
  );
}

let HeaderColWrap = styled.span`
  cursor: pointer;
  user-select: none;
`;

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        firstRank: (config?.firstRank || true) as boolean,
        sheet: config?.sheet || '',
        kpis: config?.kpis || [],
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, void>) {
  let form = props.form;
  let kpis = form.values.kpis;

  return (
    <>
      <KpiAdd form={form} kpisField="kpis" sheetDisabled={kpis.length > 0} />
      {kpis.length > 0 && <KpiList kpis={kpis} form={form} field="kpis" />}
      {kpis.length === 0 && (
        <Center mt="lg" mb="lg">
          No KPIs added
        </Center>
      )}
      <MantineDivider size="sm" />
      {/* something didn't work with isDirty and disabled save button */}
      {/* <Checkbox label="Use first KPI as rank" {...form.getInputProps('firstRank')} /> */}
    </>
  );
}
