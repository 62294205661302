import styled from '@emotion/styled';
import { Box, Stack } from '@mantine/core';
import { AppStore } from '../state';

interface Props {
  won: number;
  lost: number;
  draw: number;
  twoTeams?: boolean;
}

export function WonLostDrawBar(props: Props) {
  let { won, lost, draw, twoTeams } = props;
  const { settings } = AppStore.useState((s) => s);
  let total = won + lost + draw;
  if (total === 0) return null;
  let wonPercent = Math.round((100 * won) / total);
  let lostPercent = Math.round((100 * lost) / total);
  let drawPercent = 100 - wonPercent - lostPercent;

  return (
    <Wrap>
      <BarPart
        letter="W"
        color={twoTeams ? settings.main_color : '#01af50'}
        count={won}
        percent={wonPercent}
      />
      <BarPart letter="D" color="#a5a7a3" count={draw} percent={drawPercent} />
      <BarPart
        letter={twoTeams ? 'W' : 'L'}
        color={twoTeams ? settings.main_color : '#fe0000'}
        count={lost}
        percent={lostPercent}
        reverse
      />
    </Wrap>
  );
}

function BarPart(props: {
  letter: string;
  color: string;
  count: number;
  percent: number;
  reverse?: boolean;
}) {
  return (
    <Box
      className="box"
      sx={{ flexGrow: props.percent }}
      p={5}
      bg={props.color}
    >
      {!props.reverse && (
        <Stack spacing="0" pl={10} pr={10} sx={{ textAlign: 'center' }}>
          <span>{props.letter}</span>
          <strong>{props.count}</strong>
        </Stack>
      )}
      {props.percent > 0 && <Box className="percent">{props.percent}%</Box>}
      {props.reverse && (
        <Stack spacing="0" pl={10} pr={10} sx={{ textAlign: 'center' }}>
          <span>{props.letter}</span>
          <strong>{props.count}</strong>
        </Stack>
      )}
    </Box>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  color: white;

  strong {
    font-weight: bold;
  }

  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .box:first-of-type {
    border-radius: 24px 0 0 24px;
    padding-left: 15px;
  }

  .box:last-child {
    border-radius: 0 24px 24px 0;
    padding-right: 15px;
  }

  .percent {
    font-weight: bold;
    text-align: center;
    flex: 1;
    font-size: 1.3em;
    padding: 0 5px;
  }
`;
