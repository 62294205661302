import { createRoot } from 'react-dom/client';
import axios from 'axios';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './pwa.js';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { getAppBaseName, getFonts } from './util';
import { createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ScrollToTop from './components/ScrollToTop';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const theme = createTheme({
  typography: {
    fontFamily: getFonts(),
  },
});

export const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root')!);

root.render(
  <QueryClientProvider client={queryClient}>
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <ModalsProvider>
        <ThemeProvider theme={theme}>
          <Router basename={getAppBaseName()}>
            <ScrollToTop />
            <App />
          </Router>
        </ThemeProvider>
      </ModalsProvider>
    </MantineProvider>
    {/* <ReactQueryDevtools initialIsOpen={true} /> */}
  </QueryClientProvider>
);
