import styled from '@emotion/styled';
import { Competition, useTitle } from '../../state';
import { PlayersMain } from './Players';

interface Props {
  competition: Competition;
  teamId: number;
}

export function Players(props: Props) {
  useTitle('Players', '');
  let competition = props.competition;
  return (
    <Wrap>
      <PlayersMain
        competition={competition}
        teamId={props.teamId}
        widgetKey="team_players_page"
      />
    </Wrap>
  );
}

let Wrap = styled.div`
  padding-bottom: 100px;
`;
