import styled from '@emotion/styled';
import { Loader } from '../components/Loader';
import RichText from '../components/RichText';
import { newData } from '../core/data';
import { AppStore, mediaAsset, pageLoader, useTitle } from '../state';
import { FromUrl, siteData } from '../util';

export default function Page() {
  let { slug } = FromUrl<{ slug: string }>();
  let title = siteData().pages.find((p) => p.slug === slug)?.title || '';
  useTitle(title, '/');
  pageLoader.use({ slug }, [slug]);
  let page = AppStore.useState((s) => s.pages[slug], [slug]) || newData();

  return (
    <Loader
      data={page}
      loaded={(page) => (
        <Wrap>
          {page.header_img !== '' && (
            <Header>
              <img src={mediaAsset(page.header_img)} alt="header" />
            </Header>
          )}
          <RichText className="rich-text" html={page.content} />
        </Wrap>
      )}
    />
  );
}

let Header = styled.div`
  padding-top: 25%;
  position: sticky;
  margin-bottom: 15px;

  img {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    object-fit: cover;
  }
`;

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  overflow: hidden;

  .rich-text {
    overflow: scroll;
  }
`;
