import styled from '@emotion/styled';
import { useForm } from '@mantine/form';
import { QueryLoader } from '../../components/Loader';
import { useLeaderboardKpiData, useWidgetConfig } from '../api';
import { KpiSelect } from './editor/KpiSelect';
import { EditorProps, WidgetEditor } from './WidgetEditor';

export const WIDGET_KEY = 'team_rank';

export enum RankType {
  Group = 'Group',
  Current = 'Current',
}

interface Props {
  rankType: RankType;
  teamId: number;
  importId: number;
  competitionId: number;
}

export default function TeamRank(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    WIDGET_KEY
  ).data;

  return (
    <Pos>
      {config && <TeamRankInner {...props} config={config} />}
      <WidgetEditor
        widgetKey={WIDGET_KEY}
        config={config}
        editor={editor}
        competitionId={props.competitionId}
      />
    </Pos>
  );
}

interface Config {
  kpi: string;
}

function TeamRankInner(props: Props & { config: Config }) {
  const rankQuery = useLeaderboardKpiData(
    props.competitionId,
    [props.config.kpi],
    'leaderboard',
    [props.teamId],
    props.importId
  );

  return (
    <QueryLoader
      data={rankQuery}
      loaded={(kpiRows) => {
        const rank = kpiRows.rows.at(0)?.value;
        return (
          <>
            {props.rankType} pos:{' '}
            <b>
              {rank ? (
                <>
                  {rank}
                  <sup>{getSupFromNumber(rank)}</sup>
                </>
              ) : (
                'N/A'
              )}
            </b>
          </>
        );
      }}
    />
  );
}

export function getSupFromNumber(n: number | string | undefined): string {
  if (!isNaN(n as number)) {
    var s = ['th', 'st', 'nd', 'rd'],
      v = (n as number) % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  } else {
    return '';
  }
}

let Pos = styled.strong`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: -10px;

  b {
    font-size: 1.8em;
    text-transform: lowercase;
    margin-left: 10px;
    padding-bottom: 5px;
  }
`;

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        kpi: config?.kpi || '',
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, void>) {
  let form = props.form;
  return (
    <>
      <KpiSelect field="kpi" form={form} sheet="leaderboard" />
    </>
  );
}
