import { KpiOptions, KpiOptionsWidget } from './KpiOptions';
import { UseFormReturnType } from '@mantine/form';
import {
  Table as MantineTable,
  ActionIcon,
  TextInput,
  Group,
  Button,
  Center,
} from '@mantine/core';
import {
  IconTrash,
  IconEdit,
  IconArrowUp,
  IconArrowDown,
} from '@tabler/icons-react';
import React from 'react';
import { EditorStore } from '../state';
import { KpiSelectSimple, SheetSelect } from './KpiSelect';

interface Props<T> {
  kpis: KpiOptions[];
  form: UseFormReturnType<T>;
  field: string;
}

export function KpiList<T>(props: Props<T>) {
  let { form, kpis, field } = props;
  let [editItem, setEditItem] = React.useState<string | null>(null);
  let keyKpi = EditorStore.useState((s) => s.keyKpi);
  const groups = kpis.map((item: { kpi: string }, groupIndex: number) => {
    let isEdit = editItem === item.kpi;
    return (
      <React.Fragment key={item.kpi}>
        <tr style={{ background: isEdit ? '#f0f0f0' : 'none' }}>
          <td style={{ width: '25%', fontWeight: isEdit ? 'bold' : 'normal' }}>
            {keyKpi[item.kpi].name}
          </td>
          <td>
            <TextInput
              placeholder="Title"
              withAsterisk
              sx={{ flex: 1 }}
              {...form.getInputProps(`${field}.${groupIndex}.title`)}
            />
          </td>
          <td style={{ width: '150px' }}>
            <Group style={{ gap: '0' }}>
              <ActionIcon
                color="gray"
                disabled={groupIndex === kpis.length - 1}
                onClick={() =>
                  form.reorderListItem(field, {
                    from: groupIndex,
                    to: groupIndex + 1,
                  })
                }
              >
                <IconArrowDown size="1rem" />
              </ActionIcon>
              <ActionIcon
                color="gray"
                disabled={groupIndex === 0}
                onClick={() =>
                  form.reorderListItem(field, {
                    from: groupIndex,
                    to: groupIndex - 1,
                  })
                }
              >
                <IconArrowUp size="1rem" />
              </ActionIcon>
              <ActionIcon
                color="gray"
                onClick={() => setEditItem(isEdit ? null : item.kpi)}
              >
                <IconEdit size="1rem" />
              </ActionIcon>
              <ActionIcon
                color="red"
                onClick={() => form.removeListItem(field, groupIndex)}
              >
                <IconTrash size="1rem" />
              </ActionIcon>
            </Group>
          </td>
        </tr>
        {isEdit && (
          <tr>
            <td
              colSpan={3}
              style={{
                borderTop: 'none',
                paddingTop: '15px',
                paddingBottom: '20px',
                background: '#f0f0f0',
              }}
            >
              <KpiOptionsWidget field={`${field}.${groupIndex}`} form={form} />
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  });
  return (
    <MantineTable mb="xl">
      <thead>
        <tr style={{ fontWeight: 'bold' }}>
          <th>KPI</th>
          <th>Title</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{groups}</tbody>
    </MantineTable>
  );
}

interface KpiAddProps<T> {
  form: UseFormReturnType<T>;
  field?: string;
  kpisField: string;
  sheetDisabled: boolean;
}

export function KpiAdd<T>(props: KpiAddProps<T>) {
  let { form, field, kpisField, sheetDisabled } = props;
  let sheetField = field ? field + '.sheet' : 'sheet';
  kpisField = field ? field + '.' + kpisField : kpisField;
  let sheet = form.getInputProps(sheetField).value;
  let [kpi, setKpi] = React.useState<string | null>(null);

  return (
    <Group position="center" mt="xl" mb="xl" grow align="flex-end">
      <SheetSelect
        form={form}
        field={sheetField}
        label="Sheet"
        disabled={sheetDisabled}
      />
      <KpiSelectSimple
        label="KPI"
        sheet={sheet}
        value={kpi}
        onChange={setKpi}
      />
      <Button
        disabled={kpi == null}
        onClick={() => form.insertListItem(kpisField, { kpi, sheet })}
      >
        Add KPI
      </Button>
    </Group>
  );
}

interface KpiListEditorProps<T> {
  form: UseFormReturnType<T>;
  field: string;
  value: KpiOptions[];
  sheetDisabled?: (form: UseFormReturnType<T>) => boolean;
}

export function KpiListEditor<T>(props: KpiListEditorProps<T>) {
  let { form, value: kpis, field } = props;

  let sheetDisabled = props.sheetDisabled
    ? props.sheetDisabled(form)
    : kpis.length > 0;

  return (
    <>
      <KpiAdd form={form} kpisField={field} sheetDisabled={sheetDisabled} />
      {kpis.length > 0 && <KpiList kpis={kpis} form={form} field={field} />}
      {kpis.length === 0 && (
        <Center mt="lg" mb="lg">
          No KPIs added
        </Center>
      )}
    </>
  );
}
