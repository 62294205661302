import styled from '@emotion/styled';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  Button,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { QueryLoader } from '../../components/Loader';
import { KpiData, KpiMap, useKpiData, useWidgetConfig } from '../api';
import { SectionTitle } from '../pages/TeamOverview';
import KpiGraph, { YAxisType } from './KpiGraph';
import { KpiSelectAndEdit, SheetSelect } from './editor/KpiSelect';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { KpiGroup } from './editor/KpiGroup';
import { Divider } from '@mantine/core';
import { KpiOptions } from './editor/KpiOptions';
import { ErrorBoundary } from 'react-error-boundary';
import { KpiValue } from './utils';
import { Subteam } from '../../state';

interface Props {
  competitionId: number;
  importId: number;
  subjectId: number;
  widgetKey: string;
  context: MonstrumContext;
  kpiInfo: KpiMap;
  sectionTitle: string;
}

export function Monstrum(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    props.widgetKey,
    (config: Config) => {
      // insert percentYtd if missing
      config.groups.forEach((g) => {
        g.kpis.percentYtd = g.kpis.percentYtd || {};
        g.kpis.leagueRank = g.kpis.leagueRank || {};
        g.kpis.teamRank = g.kpis.teamRank || {};
        g.kpis.leagueRankYtd = g.kpis.leagueRankYtd || {};
        g.kpis.teamRankYtd = g.kpis.teamRankYtd || {};
      });
      return config;
    }
  ).data;

  return (
    <Wrap>
      {
        <WidgetEditor
          widgetKey={props.widgetKey}
          config={config}
          editor={editor}
          competitionId={props.competitionId}
        />
      }
      <ErrorBoundary fallback={<div>Currently unavailable</div>}>
        {config && <MonstrumInner {...props} config={config as any} />}
      </ErrorBoundary>
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;

  .edit-btn {
    align-self: flex-end;
    position: absolute;
    right: 0;
    top: 10px;
  }
`;

interface GroupConf {
  sheet: string;
  subsheet: string;
  actual: KpiOptions;
  target: KpiOptions;
  previous: KpiOptions;
  percent: KpiOptions;
  percentYtd: KpiOptions;
  points: KpiOptions;
  leagueRank: KpiOptions;
  teamRank: KpiOptions;
  leagueRankYtd: KpiOptions;
  teamRankYtd: KpiOptions;
}

export interface MonstrumContext {
  select: string;
  setSelect: React.Dispatch<React.SetStateAction<string>>;
  focusGroup: number;
  setFocusGroup: React.Dispatch<React.SetStateAction<number>>;
  hasTeamRank: boolean;
  originalSubjectId?: number;
  subteams?: Subteam[];
}

export function roundToFirstTwoDigits(num: number): number {
  const digitsMin = (Math.log(num) * Math.LOG10E + 1) | 0;
  return (
    Math.floor(num / Math.pow(10, digitsMin - 2)) * Math.pow(10, digitsMin - 2)
  );
}

function prepareGraphData(kpiData: KpiData[], kpiInfo: KpiMap, config: Config) {
  return config.groups
    .map((group) => {
      const actual = kpiData.filter((kpi) => kpi.key === group.kpis.actual.kpi);
      const target = kpiData.filter((kpi) => kpi.key === group.kpis.target.kpi);
      const percent = kpiData.filter(
        (kpi) => kpi.key === group.kpis.percent.kpi
      );
      const previous = kpiData.filter(
        (kpi) => kpi.key === group.kpis.previous.kpi
      );
      let data: {
        period: number;
        actual: number;
        target: number;
        previous: number;
      }[] = [];
      actual.forEach((a, i) => {
        const period = new Date(a.period).getTime();
        let t = target.find((t) => t.period === a.period);
        let p = previous.find((t) => t.period === a.period);
        data.push({
          period: period,
          actual: a?.value,
          target: t?.value as any, // hack
          previous: p?.value as any,
        });
      });

      let xAxisDomain = [
        Math.min(...data.map((d) => d.period)),
        Math.max(...data.map((d) => d.period)),
      ];

      const values = data.map((d) => [d.actual, d.target, d.previous]).flat();
      // Y-axis new domain
      const yAxisDomain = [
        roundToFirstTwoDigits(Math.min(...values) * 0.75),
        roundToFirstTwoDigits(Math.max(...values) * 1.25),
      ];

      return {
        data: data,
        percent,
        kpiList: [
          {
            dataKey: 'actual',
            color: 'blue',
            displayName:
              group.kpis.actual.title || kpiInfo[group.kpis.actual.kpi]?.name,
          },
          {
            dataKey: 'target',
            color: 'black',
            displayName:
              group.kpis.actual.title || kpiInfo[group.kpis.target.kpi]?.name,
          },
          {
            dataKey: 'previous',
            color: 'orange',
            displayName:
              group.kpis.actual.title || kpiInfo[group.kpis.previous.kpi]?.name,
          },
        ],
        xAxisDomain: xAxisDomain,
        yAxisDomain: yAxisDomain,
        yAxisType: (group.kpis.actual.format === 'percent'
          ? 'percentage'
          : 'numeric') as YAxisType,
      };

      // filter out empty percent because this is done in the table already
      // otherwise, the graph and table will be out of sync and the graph will show wrong data
    })
    .filter((g) => g.percent.length > 0);
}

interface Config {
  groups: Array<{ name: string; kpis: GroupConf; key: string }>;
}

export function MonstrumInner(props: Props & { config: Config }) {
  const importId = props.importId;
  const competitionId = props.competitionId;
  const widgetConfig = props.config;

  const [subjectSelect, setSubjectSelect] = React.useState(props.subjectId);
  const [subjectTypeSelect, setSubjectTypeSelect] = React.useState(
    widgetConfig.groups[0].kpis.sheet
  );

  const mainKpis = widgetConfig.groups
    .map((g) => [
      g.kpis.actual.kpi,
      g.kpis.target.kpi,
      g.kpis.previous.kpi,
      g.kpis.percent.kpi,
      g.kpis.percentYtd?.kpi,
      g.kpis.points.kpi,
      g.kpis.leagueRank.kpi,
      g.kpis.teamRank.kpi,
      g.kpis.leagueRankYtd?.kpi,
      g.kpis.teamRankYtd?.kpi,
    ])
    .flat();

  const mainKpiDataQuery = useKpiData({
    importId,
    competitionId,
    subjectType: subjectTypeSelect,
    subjects: [subjectSelect],
    kpis: mainKpis,
    agg: 'sum',
    period: 'month',
  });

  return (
    <QueryLoader
      data={mainKpiDataQuery}
      loaded={(kpiData) => {
        const graphs = prepareGraphData(
          kpiData.rows,
          props.kpiInfo,
          widgetConfig
        );

        const focusedGraph = graphs[props.context.focusGroup];
        return (
          <>
            {props.context.originalSubjectId &&
            props.context.subteams &&
            props.context.subteams.length > 1 ? (
              <SectionTitle
                title={props.sectionTitle}
                sub=""
                right={
                  <SubjectSelect
                    teamId={props.context.originalSubjectId}
                    teamSubject={widgetConfig.groups[0].kpis.sheet}
                    subteams={props.context.subteams}
                    subteamSubject={widgetConfig.groups[0].kpis.subsheet}
                    subject={subjectSelect}
                    onChangeSubject={setSubjectSelect}
                    subjectType={subjectTypeSelect}
                    onChangeSubjectType={setSubjectTypeSelect}
                  />
                }
              />
            ) : (
              <SectionTitle title={props.sectionTitle} sub="" />
            )}
            <KpiGraph
              data={focusedGraph.data}
              kpiList={focusedGraph.kpiList}
              xAxisDomain={focusedGraph.xAxisDomain}
              yAxisDomain={focusedGraph.yAxisDomain}
              yAxisType={focusedGraph.yAxisType}
            />
            <SectionTitle
              title=""
              sub=""
              right={
                <TableSelect
                  value={props.context.select}
                  onChange={props.context.setSelect}
                  hasTeamRank={props.context.hasTeamRank}
                />
              }
            />
            <KpiTableGraphSelect
              kpiData={kpiData.rows}
              kpiInfo={props.kpiInfo}
              config={widgetConfig}
              context={props.context}
            />
          </>
        );
      }}
    />
  );
}

export type MonstrumSelectOpt =
  | 'target'
  | 'points'
  | 'league_rank'
  | 'team_rank';

export function TableSelect(props: {
  value: string;
  onChange: (v: string) => void;
  hasTeamRank: boolean;
}) {
  return (
    <Select
      id="table-select"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      sx={{
        background: 'white',
        position: 'absolute',
        top: '-6px',
        right: '8px',
        height: '30px',
      }}
    >
      <MenuItem value="target">Target</MenuItem>
      <MenuItem value="points">Points</MenuItem>
      <MenuItem value="league_rank">League Rank</MenuItem>
      {props.hasTeamRank && <MenuItem value="team_rank">Team Rank</MenuItem>}
    </Select>
  );
}

export function SubjectSelect(props: {
  teamId: number;
  teamSubject: string;
  subteams: Subteam[];
  subteamSubject: string;
  subject: number;
  onChangeSubject: (v: number) => void;
  subjectType: string;
  onChangeSubjectType: (v: string) => void;
}) {
  return (
    <Select
      id="subject-select"
      value={props.subject}
      onChange={(e) => {
        props.onChangeSubject(Number(e.target.value));
        if (Number(e.target.value) === props.teamId) {
          props.onChangeSubjectType(props.teamSubject);
        } else {
          props.onChangeSubjectType(props.subteamSubject);
        }
      }}
      sx={{
        background: 'white',
        position: 'absolute',
        top: '-6px',
        right: '8px',
        height: '30px',
      }}
    >
      <MenuItem value={props.teamId}>Team</MenuItem>
      {props.subteams.map((s, i) => (
        <MenuItem value={s.id} key={i}>
          {s.name}
        </MenuItem>
      ))}
    </Select>
  );
}

interface KpiTableProps {
  kpiInfo: KpiMap;
  kpiData: KpiData[];
  config: Config;
  context: MonstrumContext;
}

function KpiTableGraphSelect(props: KpiTableProps) {
  const tableSelect = props.context.select;

  const sortedPeriods = Array.from(
    new Set(props.kpiData.map((p) => p.period as any as string))
  ) // p.period is actually string
    .sort();

  let fillOut = (data: KpiData[]) => {
    if (data.length < sortedPeriods.length) {
      return sortedPeriods.map(
        (p) =>
          data.find((d) => (d.period as any) === p) ||
          ({
            period: p,
            value: null as any,
          } as any)
      );
    } else {
      return data;
    }
  };

  const selectedKpis = (group: GroupConf, index: number, name: string) => {
    switch (tableSelect) {
      case 'points': {
        let value = props.kpiData.filter((kpi) => kpi.key === group.points.kpi);
        return {
          value,
          options: group.points,
          ytd: value
            .slice(0, index + 1)
            .reduce((v, kpiData) => kpiData.value + v, 0),
          name,
        };
      }
      case 'league_rank': {
        let value = props.kpiData.filter(
          (kpi) => kpi.key === group.leagueRank.kpi
        );
        let ytdValue = props.kpiData.filter(
          (kpi) => kpi.key === group.leagueRankYtd?.kpi
        );
        return {
          value,
          options: group.leagueRank,
          ytd: fillOut(ytdValue)[index]?.value,
          name,
        };
      }
      case 'team_rank': {
        let value = props.kpiData.filter(
          (kpi) => kpi.key === group.teamRank.kpi
        );
        let ytdValue = props.kpiData.filter(
          (kpi) => kpi.key === group.teamRankYtd?.kpi
        );
        return {
          value,
          options: group.teamRank,
          ytd: fillOut(ytdValue)[index]?.value,
          name,
        };
      }
      default: {
        // target (percent)
        let value = props.kpiData.filter(
          (kpi) => kpi.key === group.percent.kpi
        );
        let ytdValue = props.kpiData.filter(
          (kpi) => kpi.key === group.percentYtd?.kpi
        );
        return {
          value,
          options: group.percent,
          ytd: fillOut(ytdValue)[index]?.value,
          name,
        };
      }
    }
  };

  let tableHead: string[] = sortedPeriods.map((p) => {
    return new Date(p).toLocaleString('default', { month: 'short' });
  });

  const [ytdSelect, setYtdSelect] = React.useState(tableHead.length - 1);

  let groupRows = props.config.groups.map((group, i) =>
    selectedKpis(group.kpis, ytdSelect, props.config.groups[i].name)
  );
  groupRows = groupRows.filter((group) => group.value.length > 0);

  let totalPoints: number[] = [];
  if (tableSelect === 'points') {
    groupRows.forEach((group, i) => {
      if (totalPoints[0]) {
        totalPoints[0] += group.ytd;
      } else {
        totalPoints[0] = group.ytd;
      }
      fillOut(group.value).forEach((kpiData, j) => {
        if (totalPoints[j + 1]) {
          totalPoints[j + 1] += kpiData.value;
        } else {
          totalPoints[j + 1] = kpiData.value;
        }
      });
    });
  }

  return (
    <TableWrap>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell className="stickyCell"></TableCell>
              <TableCell>
                <YTDSelect
                  value={ytdSelect}
                  onChange={setYtdSelect}
                  months={tableHead}
                ></YTDSelect>
              </TableCell>
              {tableHead.map((value, index) => (
                <TableCell key={index}>{value}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {groupRows.map((group, i) => (
              <TableRow key={i}>
                <TableCell className="stickyCell">
                  <Button
                    variant="contained"
                    color={
                      i === props.context.focusGroup ? 'secondary' : 'primary'
                    }
                    onClick={() => props.context.setFocusGroup(i)}
                    sx={{ width: '100%', fontSize: 'inherit' }}
                  >
                    {group.name}
                  </Button>
                </TableCell>
                <TableCell>
                  <KpiValue value={group.ytd} options={group.options} />
                </TableCell>
                {fillOut(groupRows[i].value)?.map((kpiData, j) => (
                  <TableCell key={j}>
                    <KpiValue value={kpiData} options={group.options} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {tableSelect === 'points' && (
              <TableRow>
                <TableCell className="stickyCell">TOTAL</TableCell>
                {totalPoints.map((p, i) => (
                  <TableCell key={i}>{p}</TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrap>
  );
}

function YTDSelect(props: {
  value: number;
  onChange: (v: number) => void;
  months: string[];
}) {
  const months = props.months
    .map((m, i) => {
      return { month: m, index: i };
    })
    .reverse()
    .slice(1);
  const maxIndex = months.length;
  return (
    <Select
      id="ytd-select"
      value={props.value}
      onChange={(e) => props.onChange(Number(e.target.value))}
      sx={{
        background: 'white',
        height: '30px',
      }}
    >
      <MenuItem value={maxIndex}>YTD</MenuItem>
      {months.map((m, i) => (
        <MenuItem value={m.index} key={i}>
          YTD {m.month}
        </MenuItem>
      ))}
    </Select>
  );
}

let TableWrap = styled.div`
  .stickyCell {
    position: sticky;
    left: 0;
    background: white;
    z-index: 90;
    mind-width: 20px;
  }

  table {
    margin-top: 5px;
  }

  th {
    font-weight: bold !important;
  }

  td:first-of-type {
    font-weight: bold !important;
  }

  th,
  td {
    font-size: 12px;
    text-align: center;
  }
`;

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        groups: config?.groups || [],
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, { subjectType: string }>) {
  let form = props.form;
  let groups = form.values.groups;

  let targetSheetDisabled = groups.some(
    (g) =>
      g.kpis.actual.kpi != null ||
      g.kpis.previous.kpi != null ||
      g.kpis.percent.kpi != null ||
      g.kpis.target.kpi != null ||
      g.kpis.percentYtd.kpi != null ||
      g.kpis.points.kpi != null ||
      g.kpis.leagueRank.kpi != null ||
      g.kpis.teamRank.kpi != null ||
      g.kpis.leagueRankYtd.kpi != null ||
      g.kpis.teamRankYtd.kpi != null
  );
  let sheet = groups.find((g) => !!g.kpis.sheet)?.kpis.sheet;
  let subsheet = groups.find((g) => !!g.kpis.subsheet)?.kpis.subsheet;

  return (
    <KpiGroup
      form={form}
      value={groups}
      field="groups"
      component={GroupEditor}
      newKpis={() => ({
        sheet,
        subsheet,
        actual: {},
        target: {},
        percentYtd: {},
        previous: {},
        percent: {},
        leagueRank: {},
        teamRank: {},
        points: {},
        leagueRankYtd: {},
        teamRankYtd: {},
      })}
      innerProps={{
        targetSheetDisabled,
      }}
    />
  );
}

interface GroupEditorProps {
  form: UseFormReturnType<any>;
  field: string;
  value: GroupConf;
  targetSheetDisabled: boolean;
}

function GroupEditor(props: GroupEditorProps) {
  let { form, field, value, targetSheetDisabled } = props;

  return (
    <Stack>
      <Divider mt={10} mb={10} />
      <SheetSelect
        label="Target Sheet"
        form={form}
        field={`${field}.sheet`}
        disabled={targetSheetDisabled}
      />
      <SheetSelect
        label="Target Sub Sheet"
        form={form}
        field={`${field}.subsheet`}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.actual`}
        label="Actual"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.target`}
        label="Target"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.previous`}
        label="Previous"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.percent`}
        label="Percent"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.percentYtd`}
        label="Percent YTD"
        hasEdit={false}
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.points`}
        label="Points"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.leagueRank`}
        label="League Rank"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.leagueRankYtd`}
        label="League Rank YTD"
        hasEdit={false}
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.teamRank`}
        label="Team Rank"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.teamRankYtd`}
        label="Team Rank YTD"
        hasEdit={false}
        sheet={value.sheet}
      />
    </Stack>
  );
}
