import styled from '@emotion/styled';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from '@mui/material';
import React from 'react';
import RichText from '../components/RichText';
import { AppStore, mediaAsset, newsDetailLoader, useTitle } from '../state';
import { FromUrl } from '../util';

interface Props {
  competitionId: number;
  newsId: string;
}

function NewsDetail(props: Props) {
  const { competitionId, newsId } = props;
  React.useEffect(() => {
    newsDetailLoader({ news_id: newsId });
  }, [competitionId, newsId]);

  const { newsDetail } = AppStore.useState((s) => s.pageData);
  let loadedNews = newsDetail.data || newsDetail.prevData;
  let loading = loadedNews === null;

  if (loading) {
    return <CircularProgress />;
  }

  let news = loadedNews!;
  return (
    <Card sx={{ width: '100%' }}>
      <CardMedia
        component="img"
        height="200"
        image={mediaAsset(news.photo)}
        alt=""
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {news.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <RichText html={news.body} />
        </Typography>
        <Typography variant="caption" component="div" color="text.secondary">
          <i>
            Published at: {new Date(news.published_at).toLocaleDateString()}
          </i>
        </Typography>
      </CardContent>
      <CardActions>
        {news.document && (
          <Button
            size="small"
            variant="contained"
            target="_blank"
            href={mediaAsset(news.document)}
          >
            Download document
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default function NewsPage() {
  useTitle('News', '/');
  let competitionId = AppStore.useState((s) => s.competitionId);
  const { id } = FromUrl<NewsIdUrl>();
  return (
    <Wrap>
      <News>
        <NewsDetail competitionId={competitionId} newsId={id} />
      </News>
    </Wrap>
  );
}

interface NewsIdUrl {
  id: string;
}

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
`;

let News = styled.div`
  padding: 10px 10px 56px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin: 10px;
  }
`;
