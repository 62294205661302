import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { siteData } from '../../util';

export * from './competition';
export { usePlayersMap } from './players';

export interface KpiData {
  subject_id: number;
  key: string;
  period: Date;
  value: number;
}

export interface KpiDataRows {
  rows: KpiData[];
}

export interface KpiDataMap {
  [k: string]: KpiData[];
}

const transformPeriod = (data: KpiData): KpiData => ({
  ...data,
  ...{ period: new Date(data.period) },
});

export const cleanKpiDataRows = (data: KpiDataRows): KpiDataRows => ({
  rows: data.rows.map(transformPeriod),
});

const getLeaderboardKpiData = async (
  competition_id: number,
  kpis: string[],
  subject_type: string,
  subjects: number[],
  import_id: number
): Promise<KpiDataRows> => {
  const platformId = siteData().platform.toString();
  const { data } = await axios.get<KpiDataRows>(
    `/api/v2/competitions/${competition_id}/leaderboard?kpi=${kpis.join(
      ','
    )}&subjects=${subjects.join(
      ','
    )}&subject_type=${subject_type}&platform_id=${platformId}&import_id=${import_id}`,
    { headers: { 'Gamifier-Platform': platformId } }
  );
  return cleanKpiDataRows(data);
};

export const useLeaderboardKpiData = (
  competition_id: number,
  kpis: string[],
  subject_type: string,
  subjects: number[],
  import_id: number
) => {
  return useQuery(
    ['leaderboard', competition_id, kpis, subject_type, subjects],
    () =>
      getLeaderboardKpiData(
        competition_id,
        kpis,
        subject_type,
        subjects,
        import_id
      )
  );
};

export interface Widget {
  competition_id: number;
  key: string;
  config: any;
}

export interface Kpi {
  key: string;
  name: string;
  short_name: string;
  number_format: string;
  decimal_places: number;
  sheets: string[];
}

export type KpiMap = { [key: string]: Kpi };

const getKpiInfo = async (): Promise<Kpi[]> => {
  const platformId = siteData().platform.toString();
  const { data } = await axios.get<{ rows: Kpi[] }>('/api/v2/kpi', {
    params: { platform_id: platformId },
  });

  return data.rows;
};

export const useKpiInfo = () => {
  return useQuery(['kpi_info'], async () => {
    let list = await getKpiInfo();

    let kpiMap: KpiMap = {};
    list.forEach((kpi) => {
      kpiMap[kpi.key] = kpi;
    });

    return kpiMap;
  });
};

export const useKpiInfoList = () => useQuery(['kpi_list'], getKpiInfo);

type AggFunc = 'sum' | 'avg';
type AggPeriod = 'year' | 'month' | 'week' | 'day';

interface KpiDataQueryParams {
  importId: number;
  competitionId: number;
  subjectType: string;
  subjects?: number[];
  kpis: string[];
  start?: Date;
  end?: Date;
  agg?: AggFunc;
  period?: AggPeriod;
}

const getKpiData = async (p: KpiDataQueryParams): Promise<KpiDataRows> => {
  if (p.kpis.length === 0) return { rows: [] };

  let params: { [k: string]: string } = {
    platform_id: siteData().platform.toString(),
    import_id: p.importId.toString(),
    kpi: p.kpis.join(','),
    subject_type: p.subjectType,
  };

  if (p.subjects != null) params['subjects'] = p.subjects.join(',');
  if (p.start != null) params['start'] = p.start.toISOString().slice(0, 10);
  if (p.end != null) params['start'] = p.end.toISOString().slice(0, 10);
  if (p.agg != null && p.period != null) {
    params['agg'] = p.agg;
    params['period'] = p.period;
  }

  const { data } = await axios.get<KpiDataRows>(
    `/api/v2/kpi_data/${p.competitionId}`,
    {
      params,
      headers: {
        'Gamifier-Platform': siteData().platform.toString(),
      },
    }
  );
  return data;
};

export const useKpiData = (params: KpiDataQueryParams) => {
  return useQuery(
    [
      'kpi_data',
      params.competitionId,
      params.subjectType,
      params.subjects,
      params.kpis,
      params.start,
      params.end,
      params.agg,
      params.period,
    ],
    () => getKpiData(params)
  );
};

export const useKpiDataGroupedByKpi = (params: KpiDataQueryParams) => {
  return useQuery(
    [
      'kpi_data_grouped_by_kpi',
      params.competitionId,
      params.subjectType,
      params.subjects,
      params.kpis,
      params.start,
      params.end,
      params.agg,
      params.period,
    ],
    async () => {
      let data = await getKpiData(params);
      let map: { [key: string]: KpiData[] } = {};
      data?.rows?.forEach((d) => {
        if (map[d.key] != null) {
          map[d.key].push(d);
        } else {
          map[d.key] = [d];
        }
      });
      return map;
    }
  );
};

export interface SubjectKpiMap {
  subjectId: number;
  kpis: { [kpi: string]: KpiData[] };
}

export const useKpiDataGroupedBySubjectAndKpi = (
  params: KpiDataQueryParams
) => {
  return useQuery(
    [
      'kpi_data_grouped_by_subject_and_kpi',
      params.competitionId,
      params.subjectType,
      params.subjects,
      params.kpis,
      params.start,
      params.end,
      params.agg,
      params.period,
    ],
    async () => {
      // Assumes data is sorted by subject id, kpi id, and then period
      let data = await getKpiData(params);
      let res: SubjectKpiMap[] = [];
      data?.rows?.forEach((d) => {
        if (
          res.length === 0 ||
          res[res.length - 1].subjectId !== d.subject_id
        ) {
          res.push({
            subjectId: d.subject_id,
            kpis: { [d.key]: [d] },
          });
        } else {
          let kpis = res[res.length - 1].kpis;
          if (kpis[d.key] != null) {
            kpis[d.key].push(d);
          } else {
            kpis[d.key] = [d];
          }
        }
      });
      return res;
    }
  );
};

interface KpiDataLatestQueryParams {
  importId: number;
  competitionId: number;
  subjectType: string;
  subjects: number[];
  kpis: string[];
  start?: Date;
  end?: Date;
  period?: AggPeriod;
}

const getKpiDataLatest = async (
  params: KpiDataLatestQueryParams
): Promise<KpiDataRows> => {
  const platformId = siteData().platform.toString();
  let query = `/api/v2/kpi_data/${params.competitionId}?subject_type=${
    params.subjectType
  }&subjects=${params.subjects.join(',')}&kpi=${params.kpis.join(',')}`;
  if (params.start !== undefined) {
    query = query.concat(`&start=${params.start.toISOString().slice(0, 10)}`);
  }
  if (params.end !== undefined) {
    query = query.concat(`&end=${params.end.toISOString().slice(0, 10)}`);
  }
  query = query.concat(`&platform_id=${platformId}`);
  query = query.concat(`&import_id=${params.importId}`);
  const { data } = await axios.get<KpiDataRows>(query);
  return data;
};

export const useKpiDataLatest = (params: KpiDataLatestQueryParams) => {
  return useQuery(
    [
      'kpi_data',
      params.competitionId,
      params.subjectType,
      params.subjects,
      params.kpis,
      params.start,
      params.end,
      params.period,
    ],
    () => getKpiDataLatest(params)
  );
};

export function useWidgetConfig(
  competitionId: number,
  widgetKey: string,
  transform?: (config: any) => any
) {
  return useQuery(['widgets', competitionId, widgetKey], async () => {
    let config = (
      await axios.get<{ rows: Widget[] }>(`/api/v2/widget/${competitionId}`, {
        headers: {
          'Gamifier-Platform': siteData().platform.toString(),
        },
      })
    ).data.rows.find((w: any) => w.key === widgetKey)?.config;
    if (transform) {
      return transform(config);
    } else {
      return config;
    }
  });
}
