import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PlayerInfo, ProfileField } from '../../state';
import { siteData } from '../../util';

export function usePlayers(competitionId: number) {
  return useQuery(['players', competitionId], async () => {
    let d = (
      await axios.get(`/api/competitions/${competitionId}/players`, {
        params: { platform_id: siteData().platform.toString() },
        headers: { 'gamifier-platform': siteData().platform.toString() },
      })
    ).data as { players: PlayerInfo[] };
    return d.players;
  });
}

export function usePlayersMap(competitionId: number) {
  return useQuery(['players', 'map', competitionId], async () => {
    let d = (
      await axios.get(`/api/competitions/${competitionId}/players`, {
        params: { platform_id: siteData().platform.toString() },
        headers: { 'gamifier-platform': siteData().platform.toString() },
      })
    ).data as { players: PlayerInfo[] };
    let res: Map<number, PlayerInfo> = new Map();
    d.players
      .filter((p) => p.team_id != null)
      .forEach((player) => res.set(player.player_id, player));
    return res;
  });
}

export interface PlayerDetail {
  player_id: number;
  name: string;
  is_captain: boolean;
  bio: string;
  motto: string;
  strengths: string[];
  photo: string;
  extra: any;
  email?: string;
  user_id?: string;
  logo: string;
  profile_data?: any;
}

export function usePlayerDetail(playerId: number) {
  return useQuery(['player', playerId], async () => {
    let d = (
      await axios.get(`/api/v2/players/${playerId}`, {
        params: { platform_id: siteData().platform.toString() },
        headers: { 'gamifier-platform': siteData().platform.toString() },
      })
    ).data as PlayerDetail;
    return d;
  });
}

export function useProfileFields() {
  return useQuery(['profile_fields', siteData().platform], async () => {
    let d = (
      await axios.get(`/djapi/v1/profile_fields/`, {
        params: { platform_id: siteData().platform.toString() },
        headers: { 'gamifier-platform': siteData().platform.toString() },
      })
    ).data as ProfileField[];
    return d;
  });
}

export function useTeamPlayers(competitionId: number, teamId: number) {
  return useQuery(['players', competitionId, teamId], async () => {
    let d = (
      await axios.get(
        `/api/competitions/${competitionId}/teams/${teamId}/players`,
        {
          params: { platform_id: siteData().platform.toString() },
          headers: { 'gamifier-platform': siteData().platform.toString() },
        }
      )
    ).data as { players: PlayerInfo[] };
    return d.players;
  });
}
