import { VariantType } from 'notistack';
import React from 'react';
import { AppStore, OTPDevice, refreshMainData } from '../state';
import { Button, Divider, FormHelperText, Switch } from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';
import { siteData } from '../util';
import { Loader } from '../components/Loader';
import ConfirmOTPDeviceForm from '../components/ConfirmOTPDeviceForm';
import CreateOTPDeviceForm, {
  OTPDeviceResponse,
} from '../components/CreateOTPDeviceForm';
import ScanOTPDevice from '../components/ScanOTPDevice';

function OTPForm(props: Props) {
  const [error, setError] = React.useState<string | null>(null);
  const [device, setDevice] = React.useState<OTPDeviceResponse | null>(null);

  if (!props.hasEnabled2FA) {
    return <></>;
  }

  return (
    <Root>
      {device ? (
        <div>
          <ScanOTPDevice device={device} />
          <FormHelperText>
            Add this token to Google Authenticator App by scanning the QR Code
            or manually copying the Time based secret key.
          </FormHelperText>
          <ConfirmOTPDeviceForm
            device={device as OTPDevice}
            onSuccess={async () => {
              await refreshMainData();
              props.showSnackBar('OTP Device confirmed.', 'success');
            }}
            onError={(message) => {
              if (message) {
                setError(message);
              }
              props.showSnackBar('Could not confirm OTP Device.', 'error');
            }}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
          <FormHelperText>
            Make sure to confirm your OTP Device by entering the Token from
            Google Authenticator App. Otherwise You will be locked out from Your
            account.
          </FormHelperText>
        </div>
      ) : (
        <CreateOTPDeviceForm
          onSuccess={async (device) => {
            props.showSnackBar('OTP Device created.', 'success');
            setDevice(device);
          }}
          onError={() => {
            props.showSnackBar('Cannot create OTP Device.', 'error');
          }}
        />
      )}
    </Root>
  );
}

interface LoadedProps extends Props {
  otpDevices: OTPDevice[];
}

function OTPDevicesList(props: LoadedProps) {
  const deleteOTPDevice = async (deviceId: string) => {
    try {
      await axios.post(
        '/djapi/v1/user/delete_2fa_device/',
        { otp_device: deviceId },
        { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
      );
      await refreshMainData();
      props.showSnackBar('Deleted Two Factor Device.', 'success');
    } catch (e: any) {
      props.showSnackBar('Cannot delete Two Factor Device.', 'error');
    }
  };

  return (
    <div>
      <TwoFactorTitle>
        <h2>Two-factor Device</h2>
      </TwoFactorTitle>
      <Divider />
      <DevicesWrap>
        {props.otpDevices.map((d, i) => (
          <DeviceWrap key={i}>
            {d.name}
            {!props.hasEnabled2FA && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => deleteOTPDevice(d.id)}
              >
                Delete
              </Button>
            )}
          </DeviceWrap>
        ))}
      </DevicesWrap>
    </div>
  );
}

const TwoFactorTitle = styled.div`
  padding-left: 16px;
  margin-top: 30px;
`;

const DevicesWrap = styled.div`
  padding-left: 16px;
`;

const DeviceWrap = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
`;

function OTPDeviceManager(props: LoadedProps) {
  if (props.otpDevices.length === 0) {
    return <OTPForm {...props} />;
  } else {
    return <OTPDevicesList {...props} />;
  }
}

interface Props {
  showSnackBar(message: string, variant: VariantType): void;
  hasEnabled2FA: boolean;
}

export default function Security2FAForm(props: Props) {
  const { hasEnabled2FA } = props;
  const toggleHasEnabled2FA = async () => {
    try {
      await axios.post(
        '/djapi/v1/user/toggle_2fa/',
        { has_2fa_enabled: !hasEnabled2FA },
        {
          headers: { 'Gamifier-Platform': siteData().platform.toString() },
        }
      );
      await refreshMainData();
      props.showSnackBar('Updated 2FA.', 'success');
    } catch (e: any) {
      props.showSnackBar('Cannot update 2FA.', 'error');
    }
  };

  const otpDevices = AppStore.useState((s) => s.otpDevices);

  return (
    <>
      <TwoFactorWrap>
        <h2>Two Factor Authentication</h2>
        <Switch checked={hasEnabled2FA} onChange={toggleHasEnabled2FA} />
      </TwoFactorWrap>
      Two-factor authentication adds an additional layer of security to your
      account by requiring more than just a password to sign in.
      <Loader
        data={otpDevices}
        loaded={(d) => <OTPDeviceManager otpDevices={d} {...props} />}
      />
    </>
  );
}

const TwoFactorWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

let Root = styled.div`
  form {
    padding: 0;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  div {
    padding: 0;
  }

  div > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
