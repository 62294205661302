import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { AppStore, useTitle, refreshMainData, mediaAsset } from '../state';
import ProfileForm from './ProfileForm';
import PasswordForm from './PasswordForm';
import styled from '@emotion/styled';
import PlayerForm from './PlayerForm';
import { siteData } from '../util';
import { useSnackbar, VariantType } from 'notistack';
import Security2FAForm from './Security2FAForm';

export default function Settings() {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackBar = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, { variant });
  };

  let me = AppStore.useState((s) => s.user);
  useTitle('Settings');

  const [photo, setPhoto] = React.useState(null);
  const [logo, setLogo] = React.useState(null);

  function handlePhotoUpload(event: any) {
    setPhoto(event.target.files[0]);
  }

  async function photoSubmit(e: any) {
    if (photo == null) return;
    let data = new FormData();
    data.append('file', photo as any);
    try {
      await axios.post('/djapi/v1/user_photo/', data, {
        headers: {
          accept: 'application/json',
          'content-type': `multipart/form-data`,
        },
      });
      await axios.post('/djapi/v1/player_photo/', data, {
        headers: {
          accept: 'application/json',
          'content-type': `multipart/form-data`,
          'Gamifier-Platform': siteData().platform.toString(),
        },
      });
      await refreshMainData();
      showSnackBar('Photo updated', 'success');
    } catch (e) {
      console.log(e);
      showSnackBar('Problem updating photo', 'error');
    }
  }

  function handleLogoUpload(event: any) {
    setLogo(event.target.files[0]);
  }

  async function logoSubmit(e: any) {
    if (logo == null) return;
    let data = new FormData();
    data.append('file', logo as any);
    try {
      await axios.post('/djapi/v1/player_logo/', data, {
        headers: {
          accept: 'application/json',
          'content-type': `multipart/form-data`,
          'Gamifier-Platform': siteData().platform.toString(),
        },
      });
      await refreshMainData();
      showSnackBar('Logo updated', 'success');
    } catch (e) {
      console.log(e);
      showSnackBar('Problem updating logo', 'error');
    }
  }

  if (me == null) return null;

  return (
    <Wrap>
      <h2>Change Photo</h2>
      <form className="image-form">
        <Avatar alt="My image" src={me?.image} />
        <div>
          <input type="file" onChange={handlePhotoUpload} />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={photoSubmit}
          >
            Update
          </Button>
        </div>
      </form>

      {false && me?.player && (
        <>
          <h2>Change Logo</h2>
          <form className="image-form">
            <Avatar alt="My logo" src={mediaAsset(me!.player!.logo)} />
            <div>
              <input type="file" onChange={handleLogoUpload} />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={logoSubmit}
              >
                Update
              </Button>
            </div>
          </form>
        </>
      )}

      <h2>Change Password</h2>
      <PasswordForm showSnackBar={showSnackBar} />

      <h2>Profile</h2>
      <ProfileForm showSnackBar={showSnackBar} />

      {me.player && (
        <>
          <h2>Player</h2>
          <PlayerForm showSnackBar={showSnackBar} />
        </>
      )}
      {!siteData().force2fa && siteData().enable_2fa_auth && (
        <Security2FAForm
          showSnackBar={showSnackBar}
          hasEnabled2FA={me.profile.has_2fa_enabled}
        />
      )}
    </Wrap>
  );
}

const Wrap = styled.div`
  padding: 20px 20px 80px 20px;

  .MuiAvatar-root {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }

  .image-form {
    display: flex;
    align-items: center;

    & > div:last-child {
      flex: 1;
      box-sizing: border-box;
      max-width: 240px;
      overflow-x: hidden;
      padding: 10px 10px 10px 0;
    }

    .MuiButtonBase-root {
      margin-top: 10px;
      width: 100px;
    }
  }

  h2 {
    font-size: 18px;
    color: #444;
    margin-bottom: 10px;
  }

  h2:not(:first-of-type) {
    margin-top: 30px;
  }
`;
