import styled from '@emotion/styled';
import React from 'react';
import { QueryLoader } from '../../components/Loader';
import NarrowHeader from '../../components/NarrowHeader';
import Subheader from '../../components/Subheader';
import {
  AppStore,
  Competition,
  Division,
  LastMatches,
  Team,
  useTitle,
} from '../../state';
import {
  useDivisionByCompetition,
  useLastMatchesByCompetition,
  useTeamsByCompetition,
} from '../api';
import { LeaderTable } from '../widgets/LeaderTable';

interface Props {
  competition: Competition;
}

export function Leaderboard(props: Props) {
  const divisionsQuery = useDivisionByCompetition(
    props.competition.competition_id
  );
  const teamsQuery = useTeamsByCompetition(props.competition.competition_id);
  const lastMatchesQuery = useLastMatchesByCompetition(
    props.competition.competition_id,
    props.competition.active_import_id
  );

  return (
    <QueryLoader
      data={teamsQuery}
      loaded={(teams) => (
        <QueryLoader
          data={divisionsQuery}
          loaded={(divisions) => (
            <QueryLoader
              data={lastMatchesQuery}
              loaded={(lastMatches) => (
                <LeaderboardInner
                  {...props}
                  divisions={divisions}
                  teams={teams.teams}
                  lastMatches={lastMatches}
                />
              )}
            />
          )}
        />
      )}
    />
  );
}

interface InnerProps {
  competition: Competition;
  divisions: Division[];
  teams: Team[];
  lastMatches: LastMatches;
}

function LeaderboardInner(props: InnerProps) {
  const importId = props.competition.active_import_id || 0;
  const settings = AppStore.useState((s) => s.settings);
  const isLeague = props.competition.is_league;
  const title = isLeague ? 'Ladder' : 'Group';
  const titleSubheader = isLeague ? 'Ladder' : 'Standings';
  const divisionLabel = isLeague ? 'Division' : 'Group';
  useTitle(title);

  const [selectedDivision, setSelectedDivision] = React.useState(0);
  let banner = props.divisions[selectedDivision]?.banner;
  let headerTitle = banner ? '' : props.divisions[selectedDivision]?.name;

  let headerColor = settings.team_header_background;

  return (
    <Wrap>
      <NarrowHeader
        title={headerTitle}
        background={banner}
        headerColor={headerColor}
      />
      <div className="subheader">
        <Subheader
          title={titleSubheader}
          value={selectedDivision.toString()}
          onChange={(v) => setSelectedDivision(parseInt(v, 10))}
          inputId="division-select"
          inputLabel={divisionLabel}
          values={props.divisions}
          mapValues={(d, i) => ({ value: i.toString(), label: d.name })}
          lastUpdate={props.competition.last_update}
        />
      </div>
      <LeaderTable
        importId={importId}
        competitionId={props.competition.competition_id}
        divisionId={props.divisions[selectedDivision]?.key || ''}
        teams={props.teams}
        lastMatches={props.lastMatches}
      />
    </Wrap>
  );
}

let Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  overflow: hidden;

  .subheader {
    padding: 20px 20px 0px 20px;
    position: sticky;
  }

  td,
  th {
    padding: 2px 10px !important;
    text-align: center;
    font-size: 12px;
  }

  th {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .main {
    position: sticky;
    left: 0;
    background: white;
    z-index: 90;
    min-width: 160px;
  }

  .main > div {
    display: flex;
    align-items: center;
  }

  th.main {
    top: 0;
    z-index: 1001;
  }

  .main span:nth-child(1) {
    width: 30px;
    font-weight: bold;
  }

  .main span:nth-child(2) {
    width: 50px;
  }

  .main span:nth-child(3) {
    width: 160px;
  }

  .empty > div {
    display: flex;
    align-items: center;
  }

  .empty-logo {
    width: 50px;
  }

  .empty-team {
    width: 80px;
  }
`;
