import React from 'react';
import { WidgetProps } from './data';
import DateFnsUtils from '@date-io/date-fns';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import { countries } from './countries';
import CountryFlag from '../components/CountryFlag';
import { DatePicker, LocalizationProvider } from '@mui/lab';

type InputType = 'text' | 'password' | 'email';

export function Input(type: InputType) {
  return function InputText(p: WidgetProps) {
    let hasErrors = p.errors.length > 0;
    return (
      <TextField
        fullWidth
        type={type}
        key={p.field.name}
        id={p.field.name}
        name={p.field.name}
        label={p.field.label}
        value={p.value}
        onChange={(e) => p.onChange(e.target.value)}
        error={hasErrors}
        helperText={hasErrors ? p.errors.join(' ') : p.field.helpText}
      />
    );
  };
}

export function SelectWidget(options: string[]) {
  return function SelectW(p: WidgetProps) {
    let hasErrors = p.errors.length > 0;
    return (
      <React.Fragment key={p.field.name}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            {p.field.label}
          </InputLabel>
          <Select
            native
            value={p.value}
            onChange={(e) => p.onChange((e as any).target.value)}
            label={p.field.label}
            error={hasErrors}
            inputProps={{
              name: p.field.name,
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            {options.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </Select>
        </FormControl>
        {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
      </React.Fragment>
    );
  };
}

export function DateWidget(p: WidgetProps) {
  let hasErrors = p.errors.length > 0;
  let value: Date | null = p.value ? new Date(p.value) : null;
  return (
    <React.Fragment key={p.field.name}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          value={value}
          renderInput={(params: any) => (
            <TextField {...params} label={`${p.field.label} (DD/MM/YYYY)`} />
          )}
          onChange={(value: Date | null) => {
            if (value && isNaN(value.getTime())) {
              p.onChange('');
            } else {
              p.onChange(value?.toUTCString() || '');
            }
          }}
        />
      </LocalizationProvider>
      {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
    </React.Fragment>
  );
}

export function PhoneWidget(options: void) {
  return function PhoneW(p: WidgetProps) {
    let hasErrors = p.errors.length > 0;
    /*
          preferredCountries={['ae']}
          defaultCountry="ae"
          placeholder="+971 (050) 1234567"
          */
    return (
      <React.Fragment key={p.field.name}>
        <MuiPhoneNumberStyled
          variant="outlined"
          defaultCountry="ae"
          id={p.field.name}
          name={p.field.name}
          label={p.field.label}
          value={p.value}
          onChange={(e: any) => p.onChange(e as any as string)}
        />
        {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
      </React.Fragment>
    );
  };
}

const MuiPhoneNumberStyled = styled(MuiPhoneNumber)`
  svg {
    height: 20px;
  }
`;

export function Terms(p: WidgetProps) {
  let hasErrors = p.errors.length > 0;
  return (
    <div className="terms" key={p.field.name}>
      <p>
        I have read and understood{' '}
        <a href="/terms/" target="_blank">
          terms and conditions
        </a>{' '}
        and accept the contents in their entirety.
      </p>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!p.value}
            onChange={(e) => p.onChange(e.target.checked ? 'checked' : '')}
            name={p.field.name}
          />
        }
        label="Accept the Terms & Conditions"
      />
      {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
    </div>
  );
}

export function CountryWidget() {
  return function CountryW(p: WidgetProps) {
    let hasErrors = p.errors.length > 0;
    return (
      <React.Fragment key={p.field.name}>
        <Autocomplete
          id="country-select"
          sx={{ width: 300 }}
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          value={countries.find((o) => o.code === p.value)}
          onChange={(_, country) => p.onChange(country?.code || '')}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <CountryFlag code={option.code} />
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
      </React.Fragment>
    );
  };
}
