import axios from 'axios';
import { siteData } from '../util';
import { useQuery } from '@tanstack/react-query';
import { Drawer } from '@mantine/core';
import { QueryLoader } from './Loader';
import { Avatar, Grid, Typography } from '@mui/material';
import { mediaAsset } from '../state';

type Props = {
  close: () => void;
  opened: boolean;
  competition_id: number;
  post_id: number;
};

interface Like {
  player_name: string;
  player_photo: string;
}

const getLikes = async (
  competiton_id: number,
  post_id: number
): Promise<Like[]> => {
  const { data } = await axios.get<Like[]>(
    `/api/competitions/${competiton_id}/posts/${post_id}/likes`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );

  return data;
};

const useGetLikes = (competition_id: number, post_id: number) => {
  return useQuery(['likes', competition_id, post_id], () =>
    getLikes(competition_id, post_id)
  );
};

export default function LikesComponent({
  close,
  opened,
  competition_id,
  post_id,
}: Props) {
  const likes = useGetLikes(competition_id, post_id);
  let styles = {
    title: {
      fontSize: '20px',
    },
    header: {
      '& .mantine-Drawer-header': {
        fontSize: '20px',
      },
    },
  };

  return (
    <Drawer
      opened={opened}
      onClose={close}
      title="Likes"
      position="bottom"
      overlayProps={{ opacity: 0.5, blur: 4 }}
      withCloseButton={false}
      styles={styles}
    >
      <QueryLoader
        data={likes}
        loaded={(data) => <ListLikeComponent likes={data} />}
      />
    </Drawer>
  );
}

interface ListLikeProps {
  likes: Like[];
}

function ListLikeComponent({ likes }: ListLikeProps) {
  return (
    <div>
      {likes.length === 0 ? (
        <Typography variant="body1">No data</Typography>
      ) : (
        likes.map((like) => (
          <LikeCard
            player_name={like.player_name}
            player_photo={like.player_photo}
          />
        ))
      )}
    </div>
  );
}

function LikeCard({ player_name, player_photo }: Like) {
  return (
    <div style={{ padding: '10px', margin: '10px 0' }}>
      <Grid container wrap="nowrap" spacing={2} alignItems="center">
        <Grid item>
          <Avatar
            sx={{ width: 34, height: 34 }}
            src={mediaAsset(player_photo)}
            alt="Profile"
          />
        </Grid>
        <Grid
          justifyContent="left"
          item
          xs
          zeroMinWidth
          style={{ paddingLeft: '8px' }}
        >
          <Typography
            variant="body1"
            style={{ margin: 0, fontSize: '0.875rem' }}
          >
            {player_name}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
