import React from 'react';
import styled from '@emotion/styled';
import { QueryLoader } from '../../components/Loader';
import { Competition, Division } from '../../state';
import { TeamDetail, useKpiInfo, useMatchesByCompetition } from '../api';
import { Monstrum, MonstrumContext } from '../widgets/Monstrum';
import { Summary } from '../widgets/Summary';
import TeamRank, { RankType } from '../widgets/TeamRank';
import { MatchInfo } from './TeamMatches';
import BonusPoints from '../widgets/BonusPoints';

interface OverviewProps {
  team: TeamDetail;
  competition: Competition;
  division?: Division;
}

export function Overview(props: OverviewProps) {
  const importId = props.competition.active_import_id || 0; // TODO
  const rankType = props.competition.is_league
    ? RankType.Current
    : RankType.Group;

  const kpiTeamQuery = useKpiInfo();
  const matchesQuery = useMatchesByCompetition(
    props.competition.competition_id,
    importId,
    props.team.team_id
  );

  const [monstrumSelect, setMonstrumSelect] = React.useState('target');
  const [monstrumGroupKpi, setMonstrumFocusGroup] = React.useState(0);

  const monstrumContext: MonstrumContext = {
    select: monstrumSelect,
    setSelect: setMonstrumSelect,
    focusGroup: monstrumGroupKpi,
    setFocusGroup: setMonstrumFocusGroup,
    hasTeamRank: false,
    originalSubjectId: props.team.team_id,
    subteams: props.team.subteams,
  };

  return (
    <>
      <SectionTitle
        title={
          <TeamRank
            competitionId={props.competition.competition_id}
            teamId={props.team.team_id}
            rankType={rankType}
            importId={importId}
          />
        }
        sub=""
        image={props.division?.logo}
      />

      <Summary
        summaryType="big"
        widgetKey="team_summary_big"
        competitionId={props.competition.competition_id}
        importId={importId}
        subject="leaderboard"
        subjectId={props.team.team_id}
      />

      <Summary
        summaryType="small"
        widgetKey="team_summary_small"
        competitionId={props.competition.competition_id}
        importId={importId}
        subject="leaderboard"
        subjectId={props.team.team_id}
      />

      <QueryLoader
        data={kpiTeamQuery}
        loaded={(kpiInfo) => (
          <Monstrum
            widgetKey="team_monstrum"
            competitionId={props.competition.competition_id}
            importId={importId}
            context={monstrumContext}
            subjectId={props.team.team_id}
            kpiInfo={kpiInfo}
            sectionTitle="TEAM PERFORMANCE"
          />
        )}
      />

      <SectionTitle title="BONUS POINTS" sub="" />
      <BonusPoints
        teamId={props.team.team_id}
        importId={importId}
        competitionId={props.competition.competition_id}
      />
      <QueryLoader
        data={matchesQuery}
        loaded={(matches) => {
          let rounds = matches.matches.map((m) => m.period);
          let last = matches.matches.find(
            (m) =>
              (matches.current_round &&
                m.period ===
                  Math.max(
                    ...rounds.filter((r) => r < matches.current_round!)
                  )) ||
              m.period === Math.max(...rounds)
          );
          let current = matches.matches.find(
            (m) => m.period === matches.current_round
          );
          let upcoming = matches.matches.find(
            (m) =>
              matches.current_round &&
              m.period ===
                Math.min(...rounds.filter((r) => r > matches.current_round!))
          );
          return (
            <>
              {last && (
                <>
                  <SectionTitle title="LAST PLAYED" sub="" />
                  <MatchInfo
                    match={last}
                    competitionId={props.competition.competition_id}
                    showRound
                  />
                </>
              )}
              {current && (
                <>
                  <SectionTitle title="PLAYING NOW" sub="" />
                  <MatchInfo
                    match={current}
                    competitionId={props.competition.competition_id}
                    showRound
                  />
                </>
              )}
              {upcoming && (
                <>
                  <SectionTitle title="UPCOMING" sub="" />
                  <MatchInfo
                    match={upcoming}
                    competitionId={props.competition.competition_id}
                    showRound
                  />
                </>
              )}
            </>
          );
        }}
      />
    </>
  );
}

interface SectionTitleProps {
  title: string | React.ReactNode;
  sub: string;
  image?: string;
  right?: React.ReactNode;
  dontSetTopMargin?: boolean;
}

export function SectionTitle(props: SectionTitleProps) {
  return (
    <SectionTitleWrap dontSetTopMargin={props.dontSetTopMargin}>
      <div className="titleSub">
        {typeof props.title === 'string' ? (
          <strong>{props.title}</strong>
        ) : (
          props.title
        )}
        <span className={'sub ' + (props.image || props.right ? 'short' : '')}>
          {props.sub}
        </span>
      </div>
      {props.image && <img src={props.image} alt="" />}
      {props.right}
    </SectionTitleWrap>
  );
}

interface CssProps {
  dontSetTopMargin?: boolean;
  subBelow?: boolean;
}

let SectionTitleWrap = styled.div<CssProps>`
  display: flex;
  margin: ${(props) => (props.dontSetTopMargin && '0px') || '20px'} 0 20px 0;
  padding: 0px 10px 0 10px;
  position: relative;

  .titleSub {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  img {
    position: absolute;
    right: 15px;
    height: 40px;
    top: 0px;
  }

  strong {
    flex: 1;
  }

  .sub {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .sub.short {
    max-width: 70%;
    max-width: calc(100% - 100px);
  }
`;
