import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { siteData } from '../util';
import { AppStore } from '../state';
import RichText from './RichText';

interface Props {
  name: string;
  description: string;
}

const StyledCard = styled(Card)`
  p {
    margin-bottom: 10px;
  }

  .home-rich-text {
    font-family: var(--main-font);
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.6em;
    font-size: 0.75rem;
  }
`;

export default function HomeCard(props: Props) {
  let site = siteData();
  let competitionId = AppStore.useState((s) => s.competitionId);
  let teamsUrl = '/teams';
  if (site.v2) {
    teamsUrl = `/competition/${competitionId}/teams`;
  }
  return (
    <StyledCard sx={{ width: '100%' }} raised>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.name}
        </Typography>
        <RichText className="home-rich-text" html={props.description} />
      </CardContent>
      <CardActions sx={{ mb: 1 }}>
        <Button size="small" variant="contained" component={Link} to={teamsUrl}>
          View All Teams
        </Button>
      </CardActions>
    </StyledCard>
  );
}
