import styled from '@emotion/styled';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { dateFormatterFull } from '../util';

export interface Props<T> {
  title: string;
  sub?: string;
  value: string;
  onChange: (value: string) => void;
  inputId: string;
  inputLabel: string;
  values: Array<T>;
  mapValues: (values: T, i: number) => { value: string; label: string };
  lastUpdate?: Date;
}

export default function Subheader<T>(props: Props<T>) {
  let labelId = props.inputId + '-label';
  return (
    <SubheaderWrap>
      <div>
        <strong>{props.title}</strong>
        {props.sub && <div className="sub">{props.sub}</div>}
        {props.lastUpdate && (
          <div>Updated: {new Date(props.lastUpdate).toUTCString()}</div>
        )}
      </div>
      <FormControl>
        {props.inputLabel && (
          <InputLabel id={`${labelId}-input`}>{props.inputLabel}</InputLabel>
        )}
        {props.values.length > 0 && (
          <Select
            labelId={labelId}
            id={props.inputId}
            value={props.value}
            label={props.inputLabel}
            onChange={(e) => props.onChange(e.target.value)}
            sx={{ height: '40px', minWidth: '80px' }}
          >
            {props.values.map(props.mapValues).map((m, i) => (
              <MenuItem key={`${i}-menu`} value={m.value}>
                {m.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </SubheaderWrap>
  );
}

const SubheaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 10px;

  strong {
    text-transform: uppercase;
  }
`;
