import React from 'react';
import styled from '@emotion/styled';
import { Center, Divider } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import { useHistory } from 'react-router-dom';
import { QueryLoader } from '../../components/Loader';
import { LastMatches, mediaAsset, Team } from '../../state';
import { KpiDataRows, useLeaderboardKpiData, useWidgetConfig } from '../api';
import { KpiSelect } from './editor/KpiSelect';
import { EditorProps, WidgetEditor } from './WidgetEditor';
//import { LastMatchesPlayed } from '../../pages/Leaderboard';
import { KpiOptions } from './editor/KpiOptions';
import { KpiAdd, KpiList } from './editor/KpiList';
import { KpiValue } from './utils';
import { FadeElement } from '../../components/FadeElement';
import { grey, red } from '@mui/material/colors';
//import { matchIcon } from '../../pages/Leaderboard';

interface LastMatchesPlayedProps {
  last_matches: LastMatches;
  team_id: number;
}

export function LastMatchesPlayed(props: LastMatchesPlayedProps) {
  const { last_matches, team_id } = props;

  let playedMatchIcons = (last_matches[team_id] || []).map((e, i) =>
    matchIcon(e, i)
  );
  if (playedMatchIcons.length < 5) {
    let unplayedMatchIcons = Array.from(
      'x'.repeat(5 - playedMatchIcons.length)
    ).map((e, i) => matchIcon(e, i + playedMatchIcons.length));
    playedMatchIcons.push(...unplayedMatchIcons);
  }
  return <LastMatchesWrap>{playedMatchIcons}</LastMatchesWrap>;
}

const LastMatchesWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export function matchIcon(e: string, i: number) {
  switch (e) {
    case 'W': {
      return <CheckCircleIcon key={i} color="success" fontSize="small" />;
    }
    case 'D': {
      return (
        <RemoveCircleIcon key={i} sx={{ color: grey[500] }} fontSize="small" />
      );
    }
    case 'L': {
      return <CancelIcon key={i} sx={{ color: red[500] }} fontSize="small" />;
    }
    default: {
      return <CircleIcon key={i} sx={{ color: grey[300] }} fontSize="small" />;
    }
  }
}

interface Props {
  importId: number;
  competitionId: number;
  divisionId: string;
  teams: Team[];
  lastMatches: LastMatches;
}

const WIDGET_KEY = 'leaderboard_widget';

function LoadedTable(props: {
  kpiData: KpiDataRows;
  teams: Team[];
  competitionId: number;
  config: Config;
  lastMatches: LastMatches;
}) {
  const history = useHistory();
  const teamsSorted = props.teams
    .map((team) => ({
      teamId: team.team_id,
      pos:
        props.kpiData.rows.find(
          (kpi) =>
            kpi.key === props.config.sortingKpi &&
            kpi.subject_id === team.team_id
        )?.value || -1,
    }))
    .filter((p) => p.pos !== -1);
  teamsSorted.sort((a, b) => {
    if (a.pos < b.pos) {
      return -1;
    }
    if (a.pos > b.pos) {
      return 1;
    }
    return 0;
  });

  return (
    <Wrap>
      <TableContainer>
        <Table stickyHeader size="small" aria-label="Leaderboard">
          <TableHead>
            <TableRow>
              <TableCell className="main">
                <div>
                  <span>Pos</span>
                  <span></span>
                </div>
              </TableCell>
              <TableCell className="team">
                <div>
                  <span>Team</span>
                </div>
              </TableCell>
              {props.config.kpis.map((kpi, index) => (
                <TableCell key={index}>{kpi.title}</TableCell>
              ))}
              <TableCell>Last 5</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {teamsSorted.map((team) => {
              let t = props.teams.find((t) => team.teamId === t.team_id);

              return (
                <TableRow
                  key={team.teamId}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    history.push(
                      `/competition/${props.competitionId}/teams/${team.teamId}`
                    )
                  }
                  sx={{ textDecoration: 'none' }}
                >
                  <TableCell className="main">
                    <div>
                      <span>{team.pos}</span>
                      <span>
                        <FadeElement
                          front={
                            <img
                              src={
                                t?.logo
                                  ? mediaAsset(t.logo)
                                  : require('../../imgs/team_logo_placeholder.png')
                              }
                              width="40"
                              height="40"
                              alt="Team"
                            />
                          }
                          back={
                            t?.alt_logo !== '' ? (
                              <img
                                src={mediaAsset(t?.alt_logo!)}
                                width="40"
                                height="40"
                                alt="Team"
                              />
                            ) : null
                          }
                          width={'40px'}
                          height={'40px'}
                        />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="team">
                    <div>
                      <span>{t?.name || ''}</span>
                    </div>
                  </TableCell>
                  {props.config.kpis.map((kpi) => {
                    const value = props.kpiData.rows.find(
                      (k) => k.key === kpi.kpi && team.teamId === k.subject_id
                    )?.value;
                    return (
                      <TableCell key={kpi.kpi}>
                        <KpiValue value={value} options={kpi} />
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <LastMatchesPlayed
                      last_matches={props.lastMatches}
                      team_id={team.teamId}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrap>
  );
}

let Wrap = styled.div`
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px);
  overflow: hidden;

  .subheader {
    padding: 20px 20px 0px 20px;
    position: sticky;
  }

  td,
  th {
    padding: 2px 10px !important;
    text-align: center;
    font-size: 12px;
  }

  th {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  td.team,
  th.team {
    text-align: left;
  }

  .main {
    position: sticky;
    left: 0;
    background: white;
    z-index: 90;
    min-width: 0px;
  }

  .main > div {
    display: flex;
    align-items: center;
  }

  th.main {
    top: 0;
    z-index: 1001;
  }

  .main span:nth-child(1) {
    width: 30px;
    font-weight: bold;
  }

  .main span:nth-child(2) {
    width: 50px;
  }

  .team {
    min-width: 160px;
  }

  .team > div {
    display: flex;
    align-items: center;
  }

  .team span {
    width: 160px;
  }

  .empty > div {
    display: flex;
    align-items: center;
  }

  .empty-logo {
    width: 50px;
  }

  .empty-team {
    width: 80px;
  }
`;

function TableInner(props: Props & { config: Config }) {
  const kpis = [
    [props.config.sortingKpi],
    props.config.kpis.map((kpi) => kpi.kpi),
  ].flat();
  const teams = props.teams.filter(
    (team) => team.division === props.divisionId
  );
  const subjects = teams.map((team) => team.team_id);
  const kpiQuery = useLeaderboardKpiData(
    props.competitionId,
    kpis,
    'leaderboard',
    subjects,
    props.importId
  );

  return (
    <QueryLoader
      data={kpiQuery}
      loaded={(kpiData) => (
        <LoadedTable
          kpiData={kpiData}
          teams={teams}
          competitionId={props.competitionId}
          config={props.config}
          lastMatches={props.lastMatches}
        />
      )}
    />
  );
}

export function LeaderTable(props: Props) {
  const config: Config | undefined = useWidgetConfig(
    props.competitionId,
    WIDGET_KEY
  ).data;

  return (
    <>
      <WidgetEditor
        widgetKey={WIDGET_KEY}
        config={config}
        editor={editor}
        competitionId={props.competitionId}
      />
      {config && <TableInner {...props} config={config} />}
    </>
  );
}

interface Config {
  sortingKpi: string;
  kpis: KpiOptions[];
  sheet: string;
}

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        sortingKpi: config?.sortingKpi || '',
        kpis: config?.kpis || [],
        sheet: config?.sheet ?? '',
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, void>) {
  let form = props.form;
  let kpis = form.values.kpis;

  return (
    <>
      <KpiAdd form={form} kpisField="kpis" sheetDisabled={kpis.length > 0} />
      {kpis.length > 0 && <KpiList kpis={kpis} form={form} field="kpis" />}
      {kpis.length === 0 && (
        <Center mt="lg" mb="lg">
          No KPIs added
        </Center>
      )}
      <Divider size="sm" mb={10} />
      <KpiSelect
        label="Sort by"
        form={form}
        field="sortingKpi"
        sheet={form.values.sheet}
      />
      <Divider size="sm" mt={10} />
    </>
  );
}
