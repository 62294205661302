import { ActionIcon, Box, Group, Select, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { EditorStore } from '../state';
import { IconEdit, IconEraser } from '@tabler/icons-react';
import React from 'react';
import { KpiOptions, KpiOptionsWidget } from './KpiOptions';

export function KpiSelectAndEdit<T>(props: {
  field: string;
  form: UseFormReturnType<T>;
  sheet: string;
  hasTitle?: boolean;
  hasClear?: boolean;
  hasEdit?: boolean;
  label?: string;
}) {
  let { field, form, sheet, label } = props;
  let [isEdit, setEdit] = React.useState<boolean>(false);

  let hasClear = props.hasClear ?? false;
  let hasTitle = props.hasTitle ?? true;
  let hasEdit = props.hasEdit ?? true;

  return (
    <Stack>
      <Group align="flex-end" sx={{ '& > *:first-of-type': { flex: '1' } }}>
        <KpiSelect
          form={form}
          field={`${field}.kpi`}
          label={label}
          sheet={sheet}
        />
        {hasClear && (
          <ActionIcon
            color="gray"
            onClick={() => form.setFieldValue(`${field}.kpi`, null as any)}
          >
            <IconEraser size="1rem" />
          </ActionIcon>
        )}
        {hasEdit && (
          <ActionIcon color="gray" onClick={() => setEdit(!isEdit)}>
            <IconEdit size="1rem" />
          </ActionIcon>
        )}
      </Group>
      {isEdit && (
        <Box sx={{ background: '#e9e9e9', padding: '10px' }} mb={20}>
          <KpiOptionsWidget form={form} field={field} />
        </Box>
      )}
    </Stack>
  );
}

export function KpiSelect<T>(props: {
  field: string;
  form: UseFormReturnType<T>;
  sheet: string;
  label?: string;
}) {
  let sheetKpis = EditorStore.useState((s) => s.sheetKpis);
  let kpis = sheetKpis[props.sheet] || [];

  return (
    <Select
      className="kpi_select"
      searchable
      maxDropdownHeight={300}
      nothingFound="KPI not found"
      placeholder="Select KPI"
      label={props.label}
      zIndex={11000}
      filter={(value, item) =>
        item.label != null &&
        item.label.toLowerCase().includes(value.toLowerCase().trim())
      }
      data={kpis.map((k) => ({ label: k.name, value: k.key }))}
      {...props.form.getInputProps(props.field)}
    />
  );
}

export function KpiSelectSimple(props: {
  sheet: string;
  value: string | null;
  onChange: (value: string) => void;
  label?: string;
}) {
  let sheetKpis = EditorStore.useState((s) => s.sheetKpis);
  let kpis = sheetKpis[props.sheet] || [];

  return (
    <Select
      className="kpi_select"
      searchable
      maxDropdownHeight={300}
      nothingFound="KPI not found"
      placeholder="Select KPI"
      label={props.label}
      zIndex={11000}
      value={props.value}
      filter={(value, item) =>
        item.label != null &&
        item.label.toLowerCase().includes(value.toLowerCase().trim())
      }
      data={kpis.map((k) => ({ label: k.name, value: k.key }))}
      onChange={props.onChange}
    />
  );
}

export function SheetSelect<T>(props: {
  form: UseFormReturnType<T>;
  field: string;
  disabled?: boolean;
  sheets?: string[];
  label?: string;
}) {
  let sheets = EditorStore.useState((s) => s.sheets);
  let selectProps = props.form.getInputProps(props.field);

  return (
    <Select
      className="sheet_select"
      searchable
      maxDropdownHeight={300}
      nothingFound="No sheet"
      placeholder="Select sheet"
      label={props.label}
      zIndex={11000}
      filter={(value, item) =>
        item.label != null &&
        item.label.toLowerCase().includes(value.toLowerCase().trim())
      }
      data={(props.sheets || sheets).map((k) => ({
        label: k.replaceAll('_', ' '),
        value: k,
      }))}
      disabled={props.disabled}
      {...selectProps}
    />
  );
}
