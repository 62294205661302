import styled from '@emotion/styled';
import { Divider } from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { SetStateAction } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryLoader } from '../../components/Loader';
import Subheader from '../../components/Subheader';
import {
  KpiData,
  KpiDataRows,
  KpiMap,
  useKpiData,
  useWidgetConfig,
} from '../api';
import KpiGraph, { YAxisType } from './KpiGraph';
import { roundToFirstTwoDigits } from './Monstrum';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { KpiGroup } from './editor/KpiGroup';
import { KpiOptions } from './editor/KpiOptions';
import { KpiSelectAndEdit, SheetSelect } from './editor/KpiSelect';
import { KpiValue } from './utils';
import { useTeamsByCompetition } from '../api';
import { SectionTitle } from '../pages/TeamOverview';

interface Props {
  importId: number;
  competitionId: number;
  widgetKey: string;
  context: Context;
  kpiInfo: KpiMap;
}

export interface Context {
  activeGroup: number;
  setActiveGroup: React.Dispatch<SetStateAction<number>>;
}

export function TotalPerformance(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    props.widgetKey
  ).data;

  return (
    <Wrap>
      {
        <WidgetEditor
          widgetKey={props.widgetKey}
          competitionId={props.competitionId}
          editor={editor}
          config={config}
        />
      }
      <ErrorBoundary fallback={<div>Currently unavailable</div>}>
        {config && <InnerComponent {...props} config={config} />}
      </ErrorBoundary>
    </Wrap>
  );
}

function InnerComponent(props: Props & { config: Config }) {
  const importId = props.importId;
  const competitionId = props.competitionId;
  const widgetConfig = props.config;

  const kpis = widgetConfig.groups
    .map((g) => [
      g.kpis.actual.kpi,
      g.kpis.target.kpi,
      g.kpis.previous.kpi,
      g.kpis.percentage.kpi,
      g.kpis.prevPercentage.kpi,
      g.kpis.achieved.kpi,
      g.kpis.prevAchieved.kpi,
    ])
    .flat();

  const kpiDataQuery = useKpiData({
    importId,
    competitionId,
    subjectType: widgetConfig.groups[0].kpis.sheet,
    kpis,
    agg: 'sum',
    period: 'month',
  });

  const teamsQuery = useTeamsByCompetition(competitionId);

  return (
    <QueryLoader
      data={kpiDataQuery}
      loaded={(kpiData) => (
        <QueryLoader
          data={teamsQuery}
          loaded={(teams) => (
            <LoadedInnerComponent
              {...props}
              kpiData={kpiData}
              teams={teams.teams.length}
            />
          )}
        />
      )}
    />
  );
}

function LoadedInnerComponent(
  props: Props & { config: Config } & { kpiData: KpiDataRows } & {
    teams: number;
  }
) {
  const { kpiData } = props;
  const widgetConfig = props.config;
  let months = extractAllCurrentMonths(kpiData.rows);
  const [monthSelect, setMonthSelect] = React.useState(
    months[0].value || 'January'
  );

  const graphs = prepareGraphData(kpiData.rows, props.kpiInfo, widgetConfig);
  const rows = prepareTableDataForGroup(kpiData.rows, widgetConfig);
  const focusedGraph = graphs[props.context.activeGroup];

  return (
    <>
      <SectionTitle
        title="TOTAL PERFORMANCE"
        //sub="Executive analysis on aggregated KPI performance against targets and comparatives."
        sub=""
        right={
          <Subheader
            title=""
            value={monthSelect}
            onChange={setMonthSelect}
            inputId="month-select"
            inputLabel="Month"
            values={months}
            mapValues={(m, _) => m}
          />
        }
      />
      <KpiGraph
        data={focusedGraph.data}
        kpiList={focusedGraph.kpiList}
        xAxisDomain={focusedGraph.xAxisDomain}
        yAxisDomain={focusedGraph.yAxisDomain}
        yAxisType={focusedGraph.yAxisType}
      />
      <TableWrap>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell className="stickyCell"></TableCell>
                <TableCell>Target</TableCell>
                <TableCell>Actual</TableCell>
                <TableCell>Ach%</TableCell>
                <TableCell>Team</TableCell>
                {widgetConfig.groups.every(
                  (g) => g.kpis.previous?.kpi != null
                ) && (
                  <>
                    <TableCell>Prev Yr</TableCell>
                    <TableCell>Ach%</TableCell>
                    <TableCell>Team</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {widgetConfig.groups.map((g, i) => {
                const row = rows[i].data[monthSelect];
                return (
                  <TableRow>
                    <TableCell className="stickyCell">
                      {' '}
                      <Button
                        variant="contained"
                        color={
                          i === props.context.activeGroup
                            ? 'secondary'
                            : 'primary'
                        }
                        sx={{
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '100%',
                          margin: '5px',
                        }}
                        onClick={() => props.context.setActiveGroup(i)}
                      >
                        <Typography sx={{ fontSize: 12, m: 0 }} gutterBottom>
                          {g.name}
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell>
                      <KpiValue value={row.target} options={g.kpis.target} />
                    </TableCell>
                    <TableCell>
                      <KpiValue value={row.actual} options={g.kpis.actual} />
                    </TableCell>
                    <TableCell>
                      <KpiValue
                        value={row.percentage}
                        options={g.kpis.percentage}
                      />
                    </TableCell>
                    <TableCell>
                      <KpiValue
                        value={row.achieved}
                        options={g.kpis.achieved}
                      />
                      /{props.teams}
                    </TableCell>
                    {g.kpis.previous?.kpi != null && (
                      <>
                        <TableCell>
                          <KpiValue
                            value={row.previous}
                            options={g.kpis.previous}
                          />
                        </TableCell>
                        <TableCell>
                          <KpiValue
                            value={row.prevPercentage}
                            options={g.kpis.prevPercentage}
                          />
                        </TableCell>
                        <TableCell>
                          <KpiValue
                            value={row.prevAchieved}
                            options={g.kpis.prevAchieved}
                          />
                          /{props.teams}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrap>
    </>
  );
}

function extractAllCurrentMonths(kpiData: KpiData[]) {
  const periods = kpiData.map((d) => new Date(d.period).getTime());
  const tmp = new Set(periods);
  let uniqueDates = Array.from(tmp);
  uniqueDates.sort();
  return uniqueDates
    .map((d) => new Date(d).toLocaleString('default', { month: 'long' }))
    .map((d) => {
      return {
        value: d,
        label: d,
      };
    });
}

interface KpiRow {
  groupName: string;
  data: {
    [month: string]: {
      actual?: KpiData;
      target?: KpiData;
      previous?: KpiData;
      percentage?: KpiData;
      prevPercentage?: KpiData;
      achieved?: KpiData;
      prevAchieved?: KpiData;
    };
  };
}

function prepareTableDataForGroup(
  kpiData: KpiData[],
  config: Config
): KpiRow[] {
  return config.groups.map((group) => {
    const actual = kpiData.filter((kpi) => kpi.key === group.kpis.actual.kpi);
    const target = kpiData.filter((kpi) => kpi.key === group.kpis.target.kpi);
    const previous = kpiData.filter(
      (kpi) => kpi.key === group.kpis.previous.kpi
    );
    const percentage = kpiData.filter(
      (kpi) => kpi.key === group.kpis.percentage.kpi
    );
    const prevPercentage = kpiData.filter(
      (kpi) => kpi.key === group.kpis.prevPercentage.kpi
    );
    const achieved = kpiData.filter(
      (kpi) => kpi.key === group.kpis.achieved.kpi
    );
    const prevAchieved = kpiData.filter(
      (kpi) => kpi.key === group.kpis.prevAchieved.kpi
    );

    let card: KpiRow = {
      groupName: group.name,
      data: {},
    };

    actual.forEach((row, i) => {
      const ts = new Date(row.period).getTime();
      const period = new Date(row.period).toLocaleString('default', {
        month: 'long',
      });
      card.data[period] = {
        actual: row,
        target: target.find((r) => ts === new Date(r.period).getTime()),
        previous: previous.find((r) => ts === new Date(r.period).getTime()),
        percentage: percentage.find((r) => ts === new Date(r.period).getTime()),
        prevPercentage: prevPercentage.find(
          (r) => ts === new Date(r.period).getTime()
        ),
        achieved: achieved.find((r) => ts === new Date(r.period).getTime()),
        prevAchieved: prevAchieved.find(
          (r) => ts === new Date(r.period).getTime()
        ),
      };
    });
    return card;
  });
}

function prepareGraphData(kpiData: KpiData[], kpiInfo: KpiMap, config: Config) {
  return config.groups.map((group) => {
    const actual = kpiData.filter((kpi) => kpi.key === group.kpis.actual.kpi);
    const target = kpiData.filter((kpi) => kpi.key === group.kpis.target.kpi);
    const previous = kpiData.filter(
      (kpi) => kpi.key === group.kpis.previous.kpi
    );
    let data: {
      period: number;
      actual: number;
      target: number;
      previous: number;
    }[] = [];
    actual.forEach((a, i) => {
      const period = new Date(a.period).getTime();
      let t = target[i];
      let p = previous[i];
      data.push({
        period: period,
        actual: a?.value,
        target: t?.value,
        previous: p?.value,
      });
    });

    let xAxisDomain = [
      Math.min(...data.map((d) => d.period)),
      Math.max(...data.map((d) => d.period)),
    ];

    let numberFormat =
      group.kpis.actual.format || kpiInfo[group.kpis.actual.kpi]?.number_format;
    let isPercent = numberFormat === 'percent';

    const values = data.map((d) => [d.actual, d.target, d.previous]).flat();
    // Y-axis new domain
    const yAxisDomain = [
      roundToFirstTwoDigits(Math.min(...values) * 0.75),
      roundToFirstTwoDigits(Math.max(...values) * 1.25),
    ];

    return {
      data: data,
      kpiList: [
        {
          dataKey: 'actual',
          color: 'blue',
          displayName: kpiInfo[group.kpis.actual.kpi]?.name,
        },
        {
          dataKey: 'target',
          color: 'black',
          displayName: kpiInfo[group.kpis.target.kpi]?.name,
        },
        {
          dataKey: 'previous',
          color: 'orange',
          displayName: kpiInfo[group.kpis.previous.kpi]?.name,
        },
      ],
      xAxisDomain: xAxisDomain,
      yAxisDomain: yAxisDomain,
      yAxisType: (isPercent ? 'percentage' : 'numeric') as YAxisType,
    };
  });
}

const Wrap = styled.div`
  position: relative;
  padding: 10px 20px 56px 20px;

  .edit-btn {
    align-self: flex-end;
    position: absolute;
    right: 0px;
    top: -50px;
  }
`;

interface GroupConf {
  sheet: string;
  // Graph
  actual: KpiOptions;
  target: KpiOptions;
  previous: KpiOptions;
  // Card
  percentage: KpiOptions;
  prevPercentage: KpiOptions;
  achieved: KpiOptions;
  prevAchieved: KpiOptions;
}

interface Config {
  groups: Array<{ name: string; kpis: GroupConf; key: string }>;
}

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        groups: config?.groups || [],
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, { subjectType: string }>) {
  let form = props.form;
  let groups = form.values.groups;

  let targetSheetDisabled = groups.some(
    (g) =>
      g.kpis.actual.kpi != null ||
      g.kpis.previous.kpi != null ||
      g.kpis.target.kpi != null
  );
  let sheet = groups.find((g) => g.kpis.sheet != null)?.kpis.sheet;
  return (
    <KpiGroup
      form={form}
      value={groups}
      field="groups"
      component={GroupEditor}
      newKpis={() => ({
        sheet,
        actual: {},
        target: {},
        previous: {},
        percentage: {},
        prevPercentage: {},
        achieved: {},
        prevAchieved: {},
      })}
      innerProps={{
        targetSheetDisabled,
      }}
    />
  );
}

interface GroupEditorProps {
  form: UseFormReturnType<any>;
  field: string;
  value: GroupConf;
  targetSheetDisabled: boolean;
}

function GroupEditor(props: GroupEditorProps) {
  let { form, field, value, targetSheetDisabled } = props;

  return (
    <Stack>
      <Divider mt={10} mb={10} />
      <SheetSelect
        label="Sheet"
        form={form}
        field={`${field}.sheet`}
        disabled={targetSheetDisabled}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.actual`}
        label="Actual"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.target`}
        label="Target"
        sheet={value.sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.previous`}
        label="Previous"
        sheet={value.sheet}
      />

      <Divider mt={10} mb={10} />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.percentage`}
        label="Percentage"
        sheet={value.sheet}
      />
      <KpiSelectAndEdit
        form={form}
        field={`${field}.prevPercentage`}
        label="Previous Percentage"
        sheet={value.sheet}
      />
      <KpiSelectAndEdit
        form={form}
        field={`${field}.achieved`}
        label="Achieved"
        sheet={value.sheet}
      />
      <KpiSelectAndEdit
        form={form}
        field={`${field}.prevAchieved`}
        label="Previous Achieved"
        sheet={value.sheet}
      />
    </Stack>
  );
}

let TableWrap = styled.div`
  .stickyCell {
    position: sticky;
    left: 0;
    background: white;
    z-index: 90;
    min-width: 20px;
  }

  table {
    margin-top: 5px;
  }

  th {
    font-weight: bold !important;
  }

  td:first-of-type {
    font-weight: bold !important;
  }

  th,
  td {
    font-size: 12px;
    text-align: center;
  }
`;
