import axios from 'axios';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { AppStore, refreshMainData } from '../state';
import { useForm, Form } from '../form';
import * as widgets from '../form/widgets';
import { Errors } from '../form/data';
import styled from '@emotion/styled';
import { siteData } from '../util';
import { VariantType } from 'notistack';

const PlayerForm = (props: Props) => {
  let profile = AppStore.useState((s) => {
    return Object.assign({
      first_name: s.user?.firstName || '',
      last_name: s.user?.lastName || '',
      motto: s.user?.player?.motto || '',
      strength_1: (s.user?.player?.strengths || ['', '', ''])[0],
      strength_2: (s.user?.player?.strengths || ['', '', ''])[1],
      strength_3: (s.user?.player?.strengths || ['', '', ''])[2],
    });
  });

  const STRENGTHS = siteData().strengths;

  let form = useForm({
    initialValues: profile,
    onSubmit: async (f: Form) => {
      f.state.setLoading(true);
      try {
        let data = f.state.values;
        await axios.post('/djapi/v1/update_player/', data, {
          headers: {
            'Gamifier-Platform': siteData().platform.toString(),
          },
        });
        await refreshMainData();
        props.showSnackBar('Player saved', 'success');
      } catch (e: any) {
        if (e.response && e.response.data) {
          let errors: Errors = {};
          for (const [key, value] of Object.entries(e.response.data)) {
            if (key && Array.isArray(value)) {
              errors[key] = value;
            }
          }
          if (e.response.data.code === 'motto') {
            errors['motto'] = [e.response.data.message];
          }
          f.state.setErrors(errors);
        }
        props.showSnackBar('Player not saved', 'error');
      } finally {
        f.state.setLoading(false);
      }
    },
    fields: [
      {
        name: 'motto',
        label: 'Motto',
        isRequired: false,
        widget: widgets.Input('text'),
        section: 0,
      },
      {
        name: 'strength_1',
        label: 'Strength',
        isRequired: false,
        widget: widgets.SelectWidget(STRENGTHS),
        section: 0,
      },
      {
        name: 'strength_2',
        label: 'Strength',
        isRequired: false,
        widget: widgets.SelectWidget(STRENGTHS),
        section: 0,
      },
      {
        name: 'strength_3',
        label: 'Strength',
        isRequired: false,
        widget: widgets.SelectWidget(STRENGTHS),
        section: 0,
      },
    ],
  });

  return (
    <form>
      {form.elems().map((e) => e.render())}

      {form.state.globalErrors.length > 0 && (
        <p className="error">{form.state.globalErrors.join(' ')}</p>
      )}

      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="button"
        disabled={form.state.loading}
        onClick={form.onSubmit}
      >
        {form.state.loading ? <CircularProgress size={20} /> : 'Save'}
      </Button>
    </form>
  );
};

interface Props {
  showSnackBar(message: string, variant: VariantType): void;
}

export default function Register(props: Props) {
  return (
    <Root>
      <PlayerForm {...props} />
    </Root>
  );
}

let Root = styled.div`
  form {
    padding: 0;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
