import React from 'react';
import styled from '@emotion/styled';
import { Avatar, Card, CardContent, Typography } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import { QueryLoader } from '../../components/Loader';
import { Competition, mediaAsset, ProfileField, useTitle } from '../../state';
import { useKpiInfo } from '../api';
import { usePlayerDetail, useProfileFields } from '../api/players';
import { countries } from '../../form/countries';
import CountryFlag from '../../components/CountryFlag';
import { PlayerRank } from '../widgets/PlayerRank';
import { Monstrum, MonstrumContext } from '../widgets/Monstrum';
import { FadeElement } from '../../components/FadeElement';
import { Summary } from '../widgets/Summary';

export function PlayerDetails(props: { competition: Competition }) {
  useTitle('Player', '');
  let competition = props.competition;
  const match = useRouteMatch(
    '/competition/:competition_id/teams/:team_id/players/:player_id'
  );
  const { player_id } = match?.params as {
    player_id: number;
  };

  const profileFieldsQuery = useProfileFields();

  return (
    <QueryLoader
      data={profileFieldsQuery}
      loaded={(profileFields) => (
        <PlayerDetailsInner
          importId={competition.active_import_id || 0}
          playerId={player_id}
          competition={competition}
          profileFields={profileFields}
        />
      )}
    />
  );
}

interface Props {
  importId: number;
  playerId: number;
  competition: Competition;
  profileFields: ProfileField[];
}

function PlayerDetailsInner(props: Props) {
  const playerQuery = usePlayerDetail(props.playerId);

  const kpiPlayerQuery = useKpiInfo();
  const [monstrumSelect, setMonstrumSelect] = React.useState('target');
  const [monstrumGroupKpi, setMonstrumFocusGroup] = React.useState(0);

  const monstrumContext: MonstrumContext = {
    select: monstrumSelect,
    setSelect: setMonstrumSelect,
    focusGroup: monstrumGroupKpi,
    setFocusGroup: setMonstrumFocusGroup,
    hasTeamRank: true,
  };

  return (
    <Wrap>
      <QueryLoader
        data={playerQuery}
        loaded={(player) => (
          <Card sx={{ marginTop: '20px' }}>
            <CardContent sx={{ mb: -1 }}>
              <div style={{ padding: '20px' }}>
                <NamedAvatar
                  name={player.name}
                  img={mediaAsset(player.photo)}
                  captain={player.is_captain}
                  logo={mediaAsset(player.logo)}
                />
                <div>
                  <PerformanceWrap>
                    {props.profileFields.map((f, index) => {
                      const label = f.label;
                      const value = player.profile_data
                        ? player.profile_data[f.id] || '-'
                        : '-';
                      const countryCode = countries.find(
                        (c) => c.code === value
                      )?.code;
                      return (
                        <div key={index}>
                          <div>{label}</div>
                          {countryCode ? (
                            <CountryFlag code={countryCode} width="20" />
                          ) : (
                            <div>{value}</div>
                          )}
                        </div>
                      );
                    })}
                    <PlayerRank
                      widgetKey="player_team_rank"
                      competitionId={props.competition.competition_id}
                      playerId={player.player_id}
                      importId={props.importId}
                      title="Team"
                    />
                    <PlayerRank
                      widgetKey="player_league_rank"
                      competitionId={props.competition.competition_id}
                      playerId={player.player_id}
                      importId={props.importId}
                      title="League"
                    />
                  </PerformanceWrap>
                </div>
              </div>

              <Summary
                summaryType="big"
                widgetKey="player_summary"
                competitionId={props.competition.competition_id}
                importId={props.competition.active_import_id || 0}
                className="player_stats"
                subject="player_stats"
                subjectId={props.playerId}
              />

              <WrapPlayerInfo>
                <div>
                  <h3>Motto</h3>
                  <p>{player.motto}</p>
                </div>
                <div>
                  <h3>Top 3 Strengths</h3>
                  <p>
                    {(player.strengths.length === 0
                      ? (player.extra.strengths as string[]) || []
                      : player.strengths
                    ).map((s, i) => (
                      <span key={i}>
                        {i + 1}. {s}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
              </WrapPlayerInfo>
            </CardContent>
          </Card>
        )}
      />

      <QueryLoader
        data={kpiPlayerQuery}
        loaded={(kpiInfo) => (
          <Monstrum
            widgetKey="player_monstrum"
            competitionId={props.competition.competition_id}
            importId={props.competition.active_import_id || 0}
            context={monstrumContext}
            subjectId={props.playerId}
            kpiInfo={kpiInfo}
            sectionTitle="MONTHLY RESULTS"
          />
        )}
      />
    </Wrap>
  );
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapPlayerInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  text-align: center;

  & > div {
    width: 50%;
  }

  h3 {
    color: gray;
    margin-bottom: 5px;
    font-size: 12px;
  }
`;

let PerformanceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  margin-bottom: -20px;

  & > div {
    width: 25%;
    text-align: center;

    padding: 10px 0px;

    img {
      display: block;
      margin: auto;
      padding-top: 5px;
    }
  }

  & > div > div:first-of-type {
    color: gray;
    font-size: 12px;
  }

  &.invert > div > div:first-of-type {
    color: white;
  }

  & > div > div:last-child {
    font-size: 0.75rem;
  }
`;

interface NamedAvatarProps {
  name: string;
  img: string;
  captain: boolean;
  logo: string;
}

function NamedAvatar(props: NamedAvatarProps) {
  return (
    <NamedAvatarInline>
      <div>
        <Avatar src={props.img} sx={{ width: 60, height: 60 }} />
      </div>
      <Typography
        align="left"
        variant="h6"
        sx={{ mt: 1, marginLeft: 3, fontSize: '1.2rem' }}
        gutterBottom
      >
        {props.name} {props.captain && <span>-&nbsp;Captain</span>}
      </Typography>
    </NamedAvatarInline>
  );
}

let NamedAvatarInline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  text-align: center;
`;
