import styled from '@emotion/styled';
import { List, ListItem } from '@mui/material';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Header from '../../components/NarrowHeader';
import { QueryLoader } from '../../components/Loader';
import Subheader from '../../components/Subheader';
import { getPeriodLabel } from '../../pages/MatchesAll';
import { AppStore, Match, useTitle } from '../../state';
import { displayPeriodFormatter } from '../../util';
import { useCompetitionById, useMatchesByCompetition } from '../api';
import { MatchInfo } from './TeamMatches';

export default function MatchList() {
  useTitle('Matches');

  const match = useRouteMatch('/competition/:competition_id/matches');
  const { competition_id } = match?.params as { competition_id: number };
  const { settings } = AppStore.useState((s) => s);
  const headerColor = settings.team_header_background;

  const competitionQuery = useCompetitionById(competition_id);

  return (
    <QueryLoader
      data={competitionQuery}
      loaded={(competition) => (
        <Main>
          <Header
            title=""
            headerColor={headerColor}
            background={settings.matches_header_background_img}
          />
          <MatchesList
            competitionId={competition_id}
            importId={competition.active_import_id}
          />
        </Main>
      )}
    />
  );
}

interface Props {
  competitionId: number;
  importId?: number;
}

function MatchesList(props: Props) {
  const { competitionId, importId } = props;
  const matchesQuery = useMatchesByCompetition(competitionId, importId);

  return (
    <QueryLoader
      data={matchesQuery}
      loaded={(matches) => (
        <MatchesListWithSelect
          matches={matches.matches}
          currentRound={matches.current_round}
          competitionId={competitionId}
        />
      )}
    />
  );
}

interface MatchesProps {
  matches: Match[];
  currentRound?: number;
  competitionId: number;
}

function MatchesListWithSelect(props: MatchesProps) {
  const { matches, currentRound, competitionId } = props;
  let periods = matches
    .map((m) => m.period)
    .filter(onlyUnique)
    .reverse();
  let currentPeriod = currentRound || periods[0];
  let [period, setPeriod] = React.useState(currentPeriod.toString());
  let info = matches.find((m) => m.period.toString() === period);
  let title =
    info && info.round_start && info.round_end
      ? displayPeriodFormatter(
          new Date(info.round_start).getTime(),
          new Date(info.round_end).getTime()
        )
      : '';

  return (
    <>
      <SubheaderWrap>
        <Subheader
          title={title}
          value={period}
          onChange={setPeriod}
          inputId="round-select"
          inputLabel="Round"
          values={periods}
          mapValues={(d, i) => ({
            value: d.toString(),
            label: getPeriodLabel(d, false, 'Round #'),
          })}
        />
      </SubheaderWrap>

      <List>
        {matches
          .filter((m) => m.period.toString() === period)
          .map((m) => (
            <ListItem key={m.match_id}>
              <Wrap>
                <MatchInfo match={m} competitionId={competitionId} />
              </Wrap>
            </ListItem>
          ))}
      </List>
    </>
  );
}

let Main = styled.div`
  padding-bottom: 100px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

let SubheaderWrap = styled.div`
  padding: 20px 20px 0px 20px;
`;

function onlyUnique(value: any, index: any, array: any[]) {
  return array.indexOf(value) === index;
}
