import { AppStore } from './state';

// let registration = null;

function listenForWaitingServiceWorker(reg, callback) {
  function awaitStateChange() {
    reg.installing.addEventListener('statechange', function() {
      if (this.state === 'installed') callback(reg);
    });
  }
  if (!reg) return;
  if (reg.waiting) return callback(reg);
  if (reg.installing) awaitStateChange();
  reg.addEventListener('updatefound', awaitStateChange);
}

function promptUserToRefresh(reg) {
  AppStore.update(s => {
    s.promptRefresh = () => {
      if (reg.waiting) reg.waiting.postMessage('skipWaiting');
      AppStore.update(s => {
        s.promptRefresh = undefined;
      });
    };
  });
}

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  let refreshing = false;
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (!refreshing) {
      refreshing = true;
      window.location.reload();
    }
  });

  navigator.serviceWorker.register('/app/service-worker.js', {
    scope: '/app/'
  }).then(function (reg) {
    // registration = reg;
    setTimeout(() => {
      if (reg.active) reg.active.postMessage('version');
    }, 1000);
    listenForWaitingServiceWorker(reg, promptUserToRefresh);
    console.log('ServiceWorker registration successful with scope: ', reg.scope);
  }, function (err) {
    console.log('ServiceWorker registration failed: ', err);
  });

  navigator.serviceWorker.addEventListener('message', event => {
    if (event && event.data && event.data.version) {
      AppStore.update(s => {
        s.serviceWorkerVersion = event.data.version;
      });
    }
  });
}

window.addEventListener('beforeinstallprompt', e => {
  if (e.prompt) {
    AppStore.update(s => {
      s.install = e;
    });
  }
});

