import { AppBar } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { AppStore, refreshMainData, useTitle } from '../state';
import { siteData } from '../util';

export default function WaitingVerification() {
  useTitle('Verification');
  const waitingVerification = AppStore.useState((s) => s.waitingVerification);

  const tryLogin = async () => {
    try {
      let resp = await axios.post('/djapi/v1/login/', waitingVerification, {
        headers: { 'Gamifier-Platform': siteData().platform.toString() },
      });
      let data = resp.data as { is_verified: boolean };
      if (data.is_verified) {
        await refreshMainData();
      }
    } catch (e: any) {}
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      tryLogin();
    }, 1000 * 10);
    return () => clearInterval(interval);
  });

  // TODO CSS
  return (
    <>
      <AppBar />
      <p>Please wait until your account is activated</p>
    </>
  );
}
