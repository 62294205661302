import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { AppStore, Match, mediaAsset } from '../state';
import styled from '@emotion/styled';
import {
  displayPeriodFormatter,
  getMidweekResultBadgeUrl,
  getWinnerBadgeUrl,
  isMidweekResult,
} from '../util';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  match: Match;
  mutualMatches: Match[];
  competitionId: number;
}

export function MutualMatches(props: Props) {
  const { match, mutualMatches, competitionId } = props;
  return (
    <>
      {mutualMatches.length < 2 && (
        <Card sx={{ mt: '20px' }}>
          <CardContent>
            <Typography>
              {(mutualMatches.length === 0 && 'Match results pending.') ||
                (mutualMatches.length === 1 && 'No previous matches played.')}
            </Typography>
          </CardContent>
        </Card>
      )}
      <Wrap>
        {mutualMatches
          .filter((m) => m.match_id !== match.match_id)
          .sort((a, b) => b.period - a.period)
          .map((m, i) => (
            <MiniMatchCard key={i} match={m} competitionId={competitionId} />
          ))}
      </Wrap>
    </>
  );
}

function MiniMatchCard(props: { match: Match; competitionId: number }) {
  const history = useHistory();
  const { match, competitionId } = props;
  const { settings } = AppStore.useState((s) => s);
  const left = match.teams.find((t) => t.is_home)!;
  const right = match.teams.find((t) => !t.is_home)!;

  let leftWinner = undefined;
  let rightWinner = undefined;
  if (
    (left?.total_score || left?.total_score === 0) &&
    (right?.total_score || right?.total_score === 0) &&
    left?.total_score !== right?.total_score
  ) {
    leftWinner = left.total_score > right.total_score;
    rightWinner = !leftWinner;
  }

  let midweekResult = isMidweekResult(match.round_start, match.round_end);
  const badgeSrc = midweekResult
    ? getMidweekResultBadgeUrl(settings)
    : getWinnerBadgeUrl(settings);

  let date =
    match.round_start && match.round_end
      ? displayPeriodFormatter(
          new Date(match.round_start).getTime(),
          new Date(match.round_end).getTime()
        )
      : '';

  return (
    <Card
      sx={{
        mt: '20px',
        minWidth: '200px',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <RoundDate>
          <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>
            Round {match.period}
          </Typography>
          <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>
            {date}
          </Typography>
        </RoundDate>
        <Team>
          <img
            src={
              left.logo
                ? mediaAsset(left.logo)
                : require('../imgs/team_logo_placeholder.png')
            }
            width="40"
            height="40"
            alt="Left team"
          />
          {leftWinner && (
            <Badge>
              <img src={badgeSrc} alt="badge" />
            </Badge>
          )}
          <Typography sx={{ fontSize: '26px', fontWeight: 'bold' }}>
            {left.total_score}
          </Typography>
        </Team>
        <Team>
          <Logo>
            <img
              src={
                right.logo
                  ? mediaAsset(right.logo)
                  : require('../imgs/team_logo_placeholder.png')
              }
              width="40"
              height="40"
              alt="Right team"
            />
            {rightWinner && (
              <Badge>
                <img src={badgeSrc} alt="badge" />
              </Badge>
            )}
          </Logo>
          <Typography sx={{ fontSize: '26px', fontWeight: 'bold' }}>
            {right.total_score}
          </Typography>
        </Team>
        <CardActions sx={{ justifyContent: 'center', mt: '10px' }}>
          <Button
            size="small"
            variant="contained"
            component={Link}
            to={{
              pathname: `/competition/${competitionId}/matches/${match.match_id}`,
              state: { back: history.location },
            }}
          >
            Full details
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

const Logo = styled.div`
  display: flex;
  flex-direction: row;
`;

const Team = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  margin: 5px 30px;
`;

const Badge = styled.div`
  img {
    width: 25px;
    height: 25px;
    right: 0px;
    top: 0px;
    z-index: 2;
  }
`;

let RoundDate = styled.div`
  display: flex;
  flex-direction: column;
  p:first-of-type {
    font-weight: bold;
  }
  margin: 10px 0px;
`;

let Wrap = styled.div`
  display: flex;
  overflow-x: auto;
  height: 250px;
  > div {
    margin: 5px;
  }
`;
