import { useFormik } from 'formik';
import { OTPDevice } from '../state';
import * as yup from 'yup';
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import { siteData } from '../util';

interface ConfirmOTPDeviceFormProps {
  device: OTPDevice;
  onSuccess(): void;
  onError(message?: string): void;
  email?: string;
  password?: string;
}

export default function ConfirmOTPDeviceForm(props: ConfirmOTPDeviceFormProps) {
  let confirmForm = useFormik({
    initialValues: {
      otp_device: props.device.id,
      otp_token: '',
      email: props.email,
      password: props.password,
    },
    validationSchema: yup.object({
      otp_token: yup.string().required('OTP Token is required.'),
    }),
    onSubmit: async (values: any) => {
      try {
        await axios.post('/djapi/v1/user/confirm_2fa_device/', values, {
          headers: { 'Gamifier-Platform': siteData().platform.toString() },
        });
        props.onSuccess();
      } catch (e: any) {
        if (e.response && e.response.status === 403) {
          props.onError(e.response.data.message);
        }
        props.onError();
      }
    },
  });

  return (
    <form onSubmit={confirmForm.handleSubmit}>
      <TextField
        fullWidth
        id="otp_token"
        name="otp_token"
        label="OTP Token"
        value={confirmForm.values.otp_token}
        onChange={confirmForm.handleChange}
        error={
          confirmForm.touched.otp_token && Boolean(confirmForm.errors.otp_token)
        }
        helperText={
          confirmForm.touched.otp_token && confirmForm.errors.otp_token
        }
      />
      <Button color="primary" variant="contained" fullWidth type="submit">
        Confirm Two Factor Device
      </Button>
    </form>
  );
}
