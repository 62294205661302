import styled from '@emotion/styled';

interface Props {
  title: string;
  background: string;
  headerColor: string;
}

export default function Header(props: Props) {
  return (
    <Wrap headerColor={props.headerColor} headerImage={props.background}>
      <h1>{props.title}</h1>
    </Wrap>
  );
}

interface WrapProps {
  headerColor: string;
  headerImage: string;
}

let Wrap = styled.div<WrapProps>`
  padding: 25% 0 0 0;
  position: sticky;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: ${(props) => props.headerColor};
  background-image: url(${(props) => props.headerImage});
  background-size: cover;

  h1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
`;
