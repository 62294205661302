import { useForm } from '@mantine/form';
import { KpiData, useKpiData, useWidgetConfig } from '../api';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { KpiSelectAndEdit, SheetSelect } from './editor/KpiSelect';
import { QueryLoader } from '../../components/Loader';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { dateFormatter } from '../../util';
import styled from '@emotion/styled';
import { ErrorBoundary } from 'react-error-boundary';
import { KpiValue } from './utils';
import { KpiOptions } from './editor/KpiOptions';

export const WIDGET_KEY = 'bonus_points';

interface Props {
  teamId: number;
  importId: number;
  competitionId: number;
}

export default function BonusPoints(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    WIDGET_KEY
  ).data;

  return (
    <Wrap>
      {
        <WidgetEditor
          widgetKey={WIDGET_KEY}
          config={config}
          editor={editor}
          competitionId={props.competitionId}
        />
      }
      <ErrorBoundary fallback={<div>Currently unavailable</div>}>
        {config && <BonusPointsInner {...props} config={config} />}
      </ErrorBoundary>
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;

  .edit-btn {
    align-self: flex-end;
    position: absolute;
    right: 0px;
    top: -50px;
  }
`;

interface Config {
  sheet: string;
  kpi: KpiOptions;
}

function BonusPointsInner(props: Props & { config: Config }) {
  const bonusQuery = useKpiData({
    importId: props.importId,
    competitionId: props.competitionId,
    subjectType: props.config.sheet,
    subjects: [props.teamId],
    kpis: [props.config.kpi.kpi],
    agg: 'sum',
    period: 'month',
  });
  return (
    <QueryLoader
      data={bonusQuery}
      loaded={(kpiRows) => (
        <BonusPointsTable rows={kpiRows.rows} opts={props.config.kpi} />
      )}
    />
  );
}

interface TableProps {
  opts: KpiOptions;
  rows: KpiData[];
}

function BonusPointsTable(props: TableProps) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell key={0} className="main"></TableCell>
            <TableCell sx={{ fontSize: '12px' }}>
              <strong>YTD</strong>
            </TableCell>
            {props.rows.map((d, i) => (
              <TableCell key={i + 1} sx={{ fontSize: '12px' }}>
                <strong>{dateFormatter(new Date(d.period).getTime())}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow key="TOTAL">
            <TableCell key={0} className="main">
              <strong>TOTAL</strong>
            </TableCell>
            <TableCell>{props.rows.reduce((a, b) => a + b.value, 0)}</TableCell>
            {props.rows.map((d, i) => (
              <TableCell key={i + 1}>
                <KpiValue value={d} options={props.opts} />
              </TableCell>
            ))}
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  'th,td': {
    'font-size': '12px',
  },
}));

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        sheet: config?.sheet || '',
        kpi: config?.kpi || ({} as KpiOptions),
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, void>) {
  let form = props.form;
  return (
    <Stack>
      <SheetSelect label="Sheet" form={form} field="sheet" disabled={false} />
      <KpiSelectAndEdit
        label="Kpi"
        field="kpi"
        form={form}
        sheet={form.values.sheet}
      />
    </Stack>
  );
}
