import { Progress } from '@mantine/core';
import { CircularProgress } from '@mui/material';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useCompetitionById } from '../api';
import { Leaderboard } from './Leaderboard';
import { MatchDetails } from './MatchDetails';
import MatchList from './MatchList';
import Performance from './Performance';
import { PlayerDetails } from './PlayerDetails';
import { Players } from './Players';
import Team from './Team';
import TeamList from './TeamList';
import KpiRank from './KpiRank';
import PlayerKpiRank from './PlayerKpiRank';
import { AppStore } from '../../state';

export { default as MatchList } from './MatchList';
export { default as Performance } from './Performance';
export { default as Team } from './Team';
export { default as TeamList } from './TeamList';

export function CompetitionPages() {
  const match = useRouteMatch('/competition/:competition_id');
  const { competition_id } = match?.params as { competition_id: number };
  const competitionQuery = useCompetitionById(competition_id);
  const user = AppStore.useState((s) => s.user);

  if (competitionQuery.isLoading) {
    return (
      <Progress>
        <CircularProgress />
      </Progress>
    );
  }

  if (competitionQuery.isError) {
    // TODO style
    return <div>Error loading competition</div>;
  }

  let competition = competitionQuery.data!;

  return (
    <Switch>
      <Route exact path="/competition/:competition_id/teams">
        <TeamList competition={competition} />
      </Route>
      <Route path="/competition/:competition_id/teams/:id" component={Team}>
        <Team competition={competition} />
      </Route>

      <Route
        exact
        path="/competition/:competition_id/teams/:team_id/players/:player_id"
      >
        <PlayerDetails competition={competition} />
      </Route>

      <Route exact path="/competition/:competition_id/players/">
        <Players competition={competition} />
      </Route>

      <Route exact path="/competition/:competition_id/leaderboard">
        <Leaderboard competition={competition} />
      </Route>

      <Route
        exact
        path="/competition/:competition_id/matches"
        component={MatchList}
      />

      <Route exact path="/competition/:competition_id/matches/:id">
        <MatchDetails competition={competition} />
      </Route>

      {user?.profile.has_performance_page_access && (
        <Route exact path="/competition/:competition_id/performance">
          <Performance competition={competition} />
        </Route>
      )}

      {user?.profile.has_kpi_rank_page_access && (
        <Route exact path="/competition/:competition_id/kpi_rank">
          <KpiRank competition={competition} />
        </Route>
      )}

      {user?.profile.has_kpi_rank_page_access && (
        <Route exact path="/competition/:competition_id/player_kpi_rank">
          <PlayerKpiRank competition={competition} />
        </Route>
      )}
    </Switch>
  );
}
