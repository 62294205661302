import { ReactNode } from 'react';
import { KpiOptions } from './editor/KpiOptions';
import numberFormat from 'format-number';
import { KpiData } from '../api';
import { EditorStore } from './state';
import { getSupFromNumber } from './TeamRank';

function get<T>(a: T | null | undefined, b: T | null | undefined, c: T) {
  if (a != null) return a;
  if (b != null) return b;
  return c;
}

export function formatKpiStr(
  value: number | undefined | null,
  options: KpiOptions,
  globalOptions?: KpiOptions
): string {
  if (value == null) return 'N/A';

  let format = get(options?.format, globalOptions?.format, 'decimal');
  let decimalPlaces = get(
    options.decimalPlaces,
    globalOptions?.decimalPlaces,
    1
  );
  let letterThousands = get(
    options.letterThousands,
    globalOptions?.letterThousands,
    false
  );

  decimalPlaces =
    typeof decimalPlaces === 'string'
      ? parseInt(decimalPlaces, 10)
      : decimalPlaces;
  let suffix = get(options.suffix, globalOptions?.suffix, '');

  let prefix = get(options.prefix, globalOptions?.prefix, '');
  if (format === 'percent') {
    value = value * 100;
    suffix = '%';
  }

  let s = null;
  if (format === 'ordinal') {
    let v = Math.round(value);
    s = v.toFixed(0) + getSupFromNumber(v);
  } else {
    if (letterThousands) {
      const ranges: Array<[number, number, string, number]> = [
        [1e10, 1e9, 'B', 0],
        [1e9, 1e9, 'B', 1],
        [1e7, 1e6, 'M', 0],
        [1e6, 1e6, 'M', 1],
        [1e5, 1e3, 'K', 0],
        [1e3, 1e3, 'K', 1],
      ];
      for (let i = 0; i < ranges.length; i++) {
        let [above, trunc, symbol, places] = ranges[i];
        if (value > above) {
          value /= trunc;
          suffix = symbol + suffix;
          decimalPlaces = places;
          break;
        }
      }
    }
    s = numberFormat({ prefix, suffix, round: decimalPlaces })(value);
  }

  return s;
}

export function formatKpi(
  value: number | undefined | null,
  options: KpiOptions,
  globalOptions?: KpiOptions
): string | ReactNode {
  let s = formatKpiStr(value, options, globalOptions);

  let color = get(options.color, globalOptions?.color, 'neutral');
  let colors = get(options.colors, globalOptions?.colors, []);

  color = getColor(value, color, colors);

  if (color !== 'black') {
    return <span style={{ color }}>{s}</span>;
  } else {
    return s;
  }
}

type Value =
  | number
  | null
  | undefined
  | KpiData
  | KpiData[]
  | { [kpi: string]: KpiData }
  | { [kpi: string]: number | null | undefined };

export function KpiValue(props: { value: Value; options: KpiOptions }) {
  let keyKpi = EditorStore.useState((s) => s.keyKpi);
  let kpi = props.options.kpi;
  let globalOptions = keyKpi[kpi]?.options;
  let v = props.value;
  let t = typeof v;

  // guess which type it is
  if (v != null && t !== 'number') {
    if (t === 'object') {
      if (Array.isArray(v)) {
        v = v.find((d) => d?.key === kpi)?.value;
      } else {
        if (typeof (v as any)?.value === 'number') {
          v = (v as any).value as number;
        } else if ((v as any)[kpi] != null) {
          let vv = (v as any)[kpi];
          if (typeof vv === 'number') {
            v = vv as number;
          } else if (typeof vv === 'object' && typeof vv?.value === 'number') {
            v = vv?.value;
          } else {
            v = null;
          }
        } else {
          v = null;
        }
      }
    } else {
      v = null;
    }
  }

  return <>{formatKpi(v as number, props.options, globalOptions)}</>;
}

export function KpiTitle(props: { options: KpiOptions }) {
  let keyKpi = EditorStore.useState((s) => s.keyKpi);
  let { kpi, title } = props.options;
  return <>{title || keyKpi[kpi]?.name || ''}</>;
}

function getColor(
  value: number | undefined | null,
  color: string,
  colors: Array<{ value: number; color: string }>
) {
  if (value == null) return 'black';
  const colorMap: { [k: string]: string } = {
    red: 'red',
    green: 'green',
    yellow: 'orange',
    neutral: 'black',
  };
  let prevColor = color;
  for (let i = 0; i < colors.length; i++) {
    if (value < colors[i].value) return colorMap[prevColor] || 'black';
    prevColor = colors[i].color;
  }
  return colorMap[prevColor] || 'black';
}

// taken from Mantine
export function getPath(path: string, values: any): unknown {
  const splittedPath = path.split('.');

  if (
    splittedPath.length === 0 ||
    typeof values !== 'object' ||
    values === null
  ) {
    return undefined;
  }

  let value = values[splittedPath[0]];
  for (let i = 1; i < splittedPath.length; i += 1) {
    if (value === undefined) {
      break;
    }

    value = value[splittedPath[i]];
  }

  return value;
}
