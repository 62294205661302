import { ReactNode } from 'react';

export interface Field {
  name: string;
  label: string;
  helpText?: string;
  isRequired: boolean;
  validate: (value: FieldValue) => string[];
  widget: (props: WidgetProps) => ReactNode;
  section: number;
}

const emptyArray: string[] = [];

function alwaysValid(value: FieldValue) {
  return emptyArray;
}

function noWidget(props: WidgetProps) {
  return null;
}

export const defaultField = {
  name: '',
  label: '',
  isRequired: false,
  validate: alwaysValid,
  widget: noWidget,
  section: 0,
};

export type FieldValue = string;

export interface WidgetProps {
  field: Field;
  value: FieldValue;
  errors: string[];
  onChange: (value: FieldValue) => any;
}

export type Values = { [field: string]: FieldValue };
export type Errors = { [field: string]: string[] };
