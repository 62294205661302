import React from 'react';
import { Store } from 'pullstate';
import axios from 'axios';
import { Data, loader, newData } from './core/data';
import { siteData } from './util';
import { Values } from './form/data';

declare global {
  interface Window {
    APP_VERSION: string;
    STORE: Store<AppState>;
  }
}

let VERSION = window.APP_VERSION;

if (window.console) console.log('App version', VERSION);

let DEFAULT_SETTINGS: Settings = {
  main_color: '',
  main_text_color: '',
  main_font: '',
  main_font_weight: '',
  company_logo: '',
  app_name: '',
  app_short_name: '',
  app_description: '',
  app_logo: '',
  favicon: '',
  team_header_background: '',
  team_header_background_img: '',
  players_header_img: '',
  match_details_header_background_img: '',
  match_list_header_background_img: '',
  performance_header_background_img: '',
  rules_header_background_img: '',
  matches_header_background_img: '',
  winner_badge: '',
  midweek_result_badge: '',
  roar_post_flip_background_img: '',
  kpi_rank_header_background_img: '',
  player_kpi_rank_header_background_img: '',
  video_placeholder_img: '',
};

// MEDIA ROOT
const MEDIA_ROOT = '/media/';
// TODO Django should set it, use helper fn not `MEDIA_ROOT + ...`

export function mediaAsset(path: string): string {
  return MEDIA_ROOT + path;
}

export function media(path: string | null | undefined): string | null {
  if (path == null) return null;
  return MEDIA_ROOT + path;
}

export const AppStore = (window.STORE = new Store<AppState>({
  appVersion: VERSION,
  serviceWorkerVersion: null,
  loading: true,
  loadingSettings: true,
  auth: false, // TODO
  waitingVerification: undefined,
  user: undefined,
  title: '',
  back: null,
  editMode: false,
  baseCompetition: { name: '', logo: '' },
  competition: newData(),
  competitionList: newData(),
  competitionId: siteData().competition,
  teamId: null,
  pages: {},
  dynamicPages: {},
  profileFields: newData(),
  otpDevices: newData(),
  settings: DEFAULT_SETTINGS,
  pageData: {
    teams: newData(),
    news: newData(),
    matches: newData(),
    team: newData(),
    teamPlayers: newData(),
    newsDetail: newData(),
    matchDetail: newData(),
    leaderboard: newData(),
    divisions: newData(),
    allMatches: newData(),
    competitionPerformance: newData(),
    teamPerformance: newData(),
    players: newData(),
  },
}));

interface AppState {
  appVersion: string;
  serviceWorkerVersion: string | null;
  loading: boolean;
  loadingSettings: boolean;
  editMode: boolean;
  auth: boolean;
  waitingVerification?: { email: string; password: string };
  user?: User;
  title: string;
  back: string | null;
  install?: any;
  promptRefresh?: any;
  baseCompetition: { name: string; logo: string };
  competition: Data<Competition>;
  competitionList: Data<Competition[]>;
  competitionId: number;
  teamId: number | null;
  pages: { [key: string]: Data<Page> };
  dynamicPages: { [key: string]: Data<DynamicPage> };
  profileFields: Data<ProfileField[]>;
  pageData: PagesData;
  otpDevices: Data<OTPDevice[]>;
  settings: Settings;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  isStaff: boolean;
  image: string;
  player?: UserPlayer;
  profile: Profile;
}

export interface OTPDevice {
  id: string;
  name: string;
  confirmed: boolean;
}

export interface Profile {
  data: Values;
  has_performance_page_access: boolean;
  has_2fa_enabled: boolean;
  has_kpi_rank_page_access: boolean;
}

export interface UserPlayer {
  player_id: number;
  name: string;
  team_id: number;
  motto: string;
  strengths: string[];
  photo: string;
  logo: string;
}

export function useTitle(title: string, back: null | string = null) {
  React.useEffect(() => {
    AppStore.update((s) => {
      s.title = title;
      s.back = back;
    });
    return () => {
      AppStore.update((s) => {
        s.title = '';
        s.back = null;
      });
    };
  }, [title, back]);
}

export function useTeam(teamId: number | null = null) {
  React.useEffect(() => {
    AppStore.update((s) => {
      s.teamId = teamId;
    });
  }, [teamId]);
}

interface PagesData {
  teams: Data<Team[]>;
  news: Data<CompetitionNews[]>;
  matches: Data<Matches>;
  team: Data<TeamDetailsWithColumnOptions>;
  teamPlayers: Data<PlayersWrapper>;
  newsDetail: Data<CompetitionNewsDetail>;
  matchDetail: Data<MatchDetail>;
  leaderboard: Data<Leaderboard>;
  divisions: Data<Division[]>;
  competitionPerformance: Data<CompetitionPerformance>;
  allMatches: Data<Matches>;
  teamPerformance: Data<TeamStats>;
  players: Data<PlayerInfo[]>;
}

export interface Page {
  title: string;
  content: string;
  header_img: string;
}

export interface DynamicPageElement {
  title: string;
  content: string;
  photo: string;
  order: number;
  document: string | null;
  external_link: string | null;
}

export interface DynamicPage {
  title: string;
  header_img: string;
  elements: DynamicPageElement[];
  summary: string;
}

export interface Competition {
  competition_id: number;
  name: string;
  logo: string;
  description: string;
  round_name: string;
  rules: string;
  active_sheet_id: number | undefined;
  active_import_id: number | undefined;
  is_league: boolean;
  is_visible: boolean;
  teams_have_same_kpis: boolean;
  has_group_ladder: boolean;
  hide_graph_numbers: boolean;
  last_update: Date;
}

export interface CompetitionNews {
  news_id: number;
  title: string;
  summary: string;
  body: string;
  photo: string | null;
  photo_background_color: string;
  photo_cover: boolean;
  external_link: string | null;
  document: string | null;
  published_at: Date;
}

export let newsLoader = loader<
  AppState,
  CompetitionNews[],
  { news: CompetitionNews[] }
>({
  url: (s) => `/api/competitions/${s.competitionId}/news`,
  store: AppStore,
  prepare: (d) => d.news,
  get: (s) => s.pageData.news,
  set: (s, d) => {
    s.pageData.news = d;
  },
});

export interface CompetitionNewsDetail {
  title: string;
  summary: string;
  body: string;
  published_at: string;
  photo: string;
  document: string;
}

export let newsDetailLoader = loader<
  AppState,
  CompetitionNewsDetail,
  CompetitionNewsDetail
>({
  url: (s) => `/api/competitions/${s.competitionId}/news/{news_id}`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.newsDetail,
  set: (s, d) => {
    s.pageData.newsDetail = d;
  },
});

export interface Team {
  team_id: number;
  name: string;
  logo: string;
  alt_logo: string;
  description: string;
  division: string;
  team_group: string;
  subteams: Subteam[];
}

export interface Subteam {
  id: number;
  name: string;
}

export interface TeamDetails {
  team_id: number;
  name: string;
  logo: string;
  alt_logo: string;
  description: string;
  total_points: number;
  details: any | null;
  division: string;
  position: number;
  previous_position: number | null;
  team_group: string;
}

export interface TeamDetailsWithColumnOptions {
  team_details: TeamDetails;
  column_options: Array<ColumnOptions>;
}

export let teamListLoader = loader<AppState, Team[], { teams: Team[] }>({
  url: (s) => `/api/competitions/${s.competitionId}/teams`,
  store: AppStore,
  prepare: (d) => d.teams,
  get: (s) => s.pageData.teams,
  set: (s, d) => {
    s.pageData.teams = d;
  },
});

export interface ColumnOptions {
  order: number;
  name: string;
  display_name: string;
  short_name: string;
  style: string;
  only_full_data: boolean;
  scores_points: string;
  description: string;
  group: string;
}
export interface MatchDetail {
  match_details: Match;
  column_options: Array<ColumnOptions>;
}

export interface Matches {
  matches: Array<Match>;
  playing: Array<Match>;
  upcoming: Array<Match>;
  column_options: Array<ColumnOptions>;
  current_round: Round | null;
}

export interface Round {
  period: number;
  stage: string;
}

export interface Match {
  match_id: number;
  period: number;
  round_start: Date | null;
  round_end: Date | null;
  match_data: any;
  division: string;
  teams: MatchTeam[];
}

interface MatchTeam {
  team_id: number;
  match_team_id: number;
  name: string;
  logo: string;
  alt_logo: string;
  is_home: boolean;
  total_score: number | null;
}

export let matchLoader = loader<AppState, Matches, Matches>({
  url: (s) => `/api/competitions/${s.competitionId}/teams/{team_id}/matches`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.matches,
  set: (s, d) => {
    s.pageData.matches = d;
  },
});

export let matchDetailLoader = loader<AppState, MatchDetail, MatchDetail>({
  url: (s) => `/api/competitions/${s.competitionId}/matches/{match_id}`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.matchDetail,
  set: (s, d) => {
    s.pageData.matchDetail = d;
  },
});

export let teamLoader = loader<
  AppState,
  TeamDetailsWithColumnOptions,
  TeamDetailsWithColumnOptions
>({
  url: (s) => `/api/competitions/${s.competitionId}/teams/{team_id}`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.team,
  set: (s, d) => {
    s.pageData.team = d;
  },
});

export interface PlayersWrapper {
  players: Player[];
  squad_kpi_groups: PerformanceKpiGroup[];
}

export interface KpiGroupPlayerCard {
  group: string;
  name: string | null;
  value: Kpi | null;
  ranking: boolean;
  span: number;
  bold: boolean;
}

export interface Player {
  player_id: number;
  name: string;
  is_captain: boolean;
  photo: string;
  logo: string;
  bio: string;
  stats: PlayerStats[];
  motto: string;
  strengths: string[];
  profile_data: Values | null;
}

export interface PlayerStats {
  period: Date;
  period_type: number;
  kpi_data: KpiData;
}

export interface PlayerInfo {
  player_id: number;
  name: string;
  photo: string;
  logo: string;
  team_id: number;
  team_logo: string;
  team_alt_logo: string;
}

export let playerListLoader = loader<
  AppState,
  PlayerInfo[],
  { players: PlayerInfo[] }
>({
  url: (s) => `/api/competitions/${s.competitionId}/players`,
  store: AppStore,
  prepare: (d) => d.players,
  get: (s) => s.pageData.players,
  set: (s, d) => {
    s.pageData.players = d;
  },
});

export let teamPlayersLoader = loader<AppState, PlayersWrapper, PlayersWrapper>(
  {
    url: (s) => `/api/competitions/${s.competitionId}/teams/{team_id}/players`,
    store: AppStore,
    prepare: (d) => d,
    get: (s) => s.pageData.teamPlayers,
    set: (s, d) => {
      s.pageData.teamPlayers = d;
    },
  }
);

let competitionsLoader = loader<AppState, Competition, Competition>({
  url: (s) => `/api/competitions/${s.competitionId}`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.competition,
  set: (s, d) => {
    s.competition = d;
    if (d.data) {
      s.baseCompetition = { name: d.data.name, logo: d.data.logo };

      // TODO this is probably not used anyway in v2
      let site = siteData();
      if (
        site.overrideImport &&
        d.data.competition_id === site.overrideImport.competition_id
      ) {
        d.data.active_import_id = site.overrideImport.import_id;
      }
    }
  },
});

let competitionListLoader = loader<
  AppState,
  Competition[],
  { competitions: Competition[] }
>({
  url: '/api/competitions/',
  store: AppStore,
  prepare: (d) => d.competitions,
  get: (s) => s.competitionList,
  set: (s, d) => {
    s.competitionList = d;
  },
});

export interface LeaderboardRowDetail {
  [key: string]: any;
}

export interface LastMatches {
  [key: number]: Array<MatchResult>;
}

type MatchResult = 'W' | 'D' | 'L';

export interface LeaderboardRow {
  team_id: number;
  team_name: string;
  total_points: number;
  logo: string;
  alt_logo: string;
  details: LeaderboardRowDetail | null;
  division: string;
  position: number;
  previous_position: number | null;
  team_group: string;
}
export interface Leaderboard {
  leaderboard_rows: Array<LeaderboardRow>;
  column_options: Array<ColumnOptions>;
  last_matches: LastMatches;
  group_matches: GroupMatches | null;
  matches_column_options: Array<ColumnOptions> | null;
  team_groups: Array<TeamGroup> | null;
}

export interface TeamGroup {
  team_group_id: number;
  name: string;
  logo: string;
  alt_logo: string;
}

export interface GroupMatches {
  [key: string]: Match[];
}

export let leaderboardLoader = loader<AppState, Leaderboard, Leaderboard>({
  url: (s) => `/api/competitions/${s.competitionId}/leaderboard?limit=5`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.leaderboard,
  set: (s, d) => {
    s.pageData.leaderboard = d;
  },
});

export interface ProfileField {
  id: string;
  label: string;
  type: string;
  is_required: boolean;
  section: number;
  values: string;
}

export let pageLoader = loader<AppState, Page, Page>({
  url: (_s, p) => `/api/page/${p.slug || ''}`,
  store: AppStore,
  prepare: (d) => d,
  get: (s, p) => s.pages[p.slug] || newData(),
  set: (s, d, p) => {
    if (p.slug) {
      s.pages[p.slug] = d;
    }
  },
});

export let dynamicPageLoader = loader<AppState, DynamicPage, DynamicPage>({
  url: (_s, p) => `/api/dpage/${p.slug || ''}`,
  store: AppStore,
  prepare: (d) => d,
  get: (s, p) => s.dynamicPages[p.slug] || newData(),
  set: (s, d, p) => {
    if (p.slug) {
      s.dynamicPages[p.slug] = d;
    }
  },
});

export interface Division {
  name: string;
  key: string;
  logo: string;
  banner: string;
}

export let divisionLoader = loader<
  AppState,
  Division[],
  { divisions: Division[] }
>({
  url: (s) => `/api/competitions/${s.competitionId}/divisions`,
  store: AppStore,
  prepare: (d) => d.divisions,
  get: (s) => s.pageData.divisions,
  set: (s, d) => {
    s.pageData.divisions = d;
  },
});

export interface Kpi {
  name: string;
  short_name: string;
  display_name: string;
  style: string;
}

export interface Group {
  name: string;
  order: number;
  format: number;
  actual: Kpi | null;
  target: Kpi | null;
  percentage: Kpi | null;
  previous: Kpi | null;
  previous_percentage: Kpi | null;
  achieved: Kpi | null;
  previous_achieved: Kpi | null;
  color_code_compare: number;
}

export interface KpiGroup {
  name: string;
  actual: Kpi;
  target: Kpi | null;
  points: Kpi | null;
  decimal_points: number;
  greater_than_target: boolean;
  format: number;
}

export interface PerformanceKpiGroup {
  name: string;
  actual?: Kpi;
  target?: Kpi;
  decimal_points: number;
  greater_than_target: boolean;
  format: number;
  points?: Kpi;
  league_rank?: Kpi;
  team_rank?: Kpi;
  graph_actual?: Kpi;
  graph_target?: Kpi;
  graph_comparison?: Kpi;
  graph_format: number;
}

export interface CompetitionPerformance {
  monthly_stats: Array<MonthlyStats>;
  yearly_stats: YearlyStats;
  groups: Group[];
}

export interface MonthlyStats {
  month: Date;
  kpi_data: KpiData;
  monthly_points: number;
  bonus_points: number;
}

export interface KpiData {
  [key: string]: { value: number | string; display_value: string };
}

export interface YearlyStats {
  [key: string]: number;
}

export const competitionPerformanceLoader = loader<
  AppState,
  CompetitionPerformance,
  CompetitionPerformance
>({
  url: (s) => `/api/competitions/${s.competitionId}/performance`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.competitionPerformance,
  set: (s, d) => {
    s.pageData.competitionPerformance = d;
  },
});

export const allMatchesLoader = loader<AppState, Matches, Matches>({
  url: (s) => `/api/competitions/${s.competitionId}/matches`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.allMatches,
  set: (s, d) => {
    s.pageData.allMatches = d;
  },
});

interface TeamStats {
  monthly_stats: MonthlyStats[];
  yearly_stats: KpiData;
  team_overview_kpi_groups: PerformanceKpiGroup[];
  monthly_points_co: ColumnOptions | null;
  bonus_points_co: ColumnOptions | null;
}

export const teamPerformanceLoader = loader<AppState, TeamStats, TeamStats>({
  url: (s) =>
    `/api/competitions/${s.competitionId}/teams/{team_id}/performance`,
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.pageData.teamPerformance,
  set: (s, d) => {
    s.pageData.teamPerformance = d;
  },
});

export const loadProfileFields = loader<
  AppState,
  ProfileField[],
  ProfileField[]
>({
  url: '/djapi/v1/profile_fields/',
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.profileFields,
  set: (s, d) => {
    s.profileFields = d;
  },
});

export const loadOTPDevices = loader<AppState, OTPDevice[], OTPDevice[]>({
  url: '/djapi/v1/user/otp_devices/',
  store: AppStore,
  prepare: (d) => d,
  get: (s) => s.otpDevices,
  set: (s, d) => {
    s.otpDevices = d;
  },
});

export interface Settings {
  main_color: string;
  main_text_color: string;
  main_font: string;
  main_font_weight: string;
  company_logo: string;
  app_name: string;
  app_short_name: string;
  app_description: string;
  app_logo: string;
  favicon: string;
  team_header_background: string;
  team_header_background_img: string;
  players_header_img: string;
  match_details_header_background_img: string;
  match_list_header_background_img: string;
  performance_header_background_img: string;
  rules_header_background_img: string;
  matches_header_background_img: string;
  winner_badge: string;
  midweek_result_badge: string;
  roar_post_flip_background_img: string;
  kpi_rank_header_background_img: string;
  player_kpi_rank_header_background_img: string;
  video_placeholder_img: string;
}

export async function refreshMainData() {
  console.log('refresh main data');
  const s = AppStore.getRawState();

  try {
    let response = await axios.get(`/djapi/v1/settings/`, {
      headers: {
        'Gamifier-Platform': siteData().platform.toString(),
        'Gamifier-Competition': s.competitionId.toString(),
      },
    });
    AppStore.update((s) => {
      s.settings = response.data as Settings;
      s.loadingSettings = false;
    });
  } catch (e: any) {
    console.log('Failed to load settings', e);
    AppStore.update((s) => {
      s.loadingSettings = false;
    });
  }

  try {
    let response = await axios.get('/djapi/v1/user/');
    let data = response.data as any;
    AppStore.update((s) => {
      s.auth = true;
      s.waitingVerification = undefined;
      s.user = {
        id: data['me'].id,
        firstName: data['me'].first_name,
        lastName: data['me'].last_name,
        email: data['me'].email,
        isActive: data['me'].is_active,
        isStaff: data['me'].is_staff,
        image: data['me'].image,
        profile: data.profile,
      };
    });

    response = await axios.get(`/api/competitions/${s.competitionId}/me`, {
      headers: { 'Gamifier-Platform': siteData().platform.toString() },
    });
    let player = response.data as UserPlayer;
    AppStore.update((s) => {
      if (s.user) {
        s.user.player = player;
      }

      s.loading = false;
    });

    competitionsLoader({});
    competitionListLoader({});
    loadOTPDevices({});
  } catch (e: any) {
    if (e.response.status === 403) {
      AppStore.update((s) => {
        s.auth = false;
        s.loading = false;
        s.user = undefined;
        s.waitingVerification = undefined;
      });
    }
  }
}

export async function logout() {
  await axios.get('/djapi/v1/logout/');
  AppStore.update((s) => {
    s.auth = false;
    s.user = undefined;
    s.loading = false;
    s.waitingVerification = undefined;
  });
}

refreshMainData();
