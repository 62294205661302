import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  Competition,
  Division,
  LastMatches,
  Match,
  mediaAsset,
  Subteam,
  Team,
} from '../../state';
import { siteData } from '../../util';

const getCompetitionById = async (
  competitionId: number
): Promise<Competition> => {
  const { data } = await axios.get<Competition>(
    `/api/competitions/${competitionId}`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
  let site = siteData();
  if (
    site.overrideImport &&
    data.competition_id === site.overrideImport.competition_id
  ) {
    data.active_import_id = site.overrideImport.import_id;
  }
  return data;
};

export const useCompetitionById = (competitionId: number) => {
  return useQuery(['competition', competitionId], () =>
    getCompetitionById(competitionId)
  );
};

const getTeamsByCompetition = async (
  competitionId: number
): Promise<{ teams: Team[] }> => {
  const { data } = await axios.get<{ teams: Team[] }>(
    `/api/competitions/${competitionId}/teams`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
  return data;
};

export const useTeamsByCompetition = (competitionId: number) => {
  return useQuery(['competition', 'teams', competitionId], () =>
    getTeamsByCompetition(competitionId)
  );
};

export const useTeamsMapByCompetition = (competitionId: number) => {
  return useQuery(['competition', 'teams_map', competitionId], async () => {
    let data = await getTeamsByCompetition(competitionId);
    let res: Map<number, Team> = new Map();
    data.teams.forEach((team) => res.set(team.team_id, team));
    return res;
  });
};

const getMatchesByCompetition = async (
  competitionId: number,
  importId?: number,
  teamId?: number,
  teamIdOther?: number
): Promise<{ matches: Match[]; current_round?: number }> => {
  const teamQuery = teamId ? `&team_id=${teamId}` : '';
  const otherTeamQuery = teamIdOther ? `&team_id_other=${teamIdOther}` : '';
  const { data } = await axios.get<{ matches: Match[]; current_round: number }>(
    `/api/v2/competitions/${competitionId}/matches?platform_id=${
      siteData().platform
    }&import_id=${importId}${teamQuery}${otherTeamQuery}`
  );
  const matches = data.matches.map((match) => {
    let updated = {
      round_start: match.round_start ? new Date(match.round_start) : null,
      round_end: match.round_end ? new Date(match.round_end) : null,
    };
    return { ...match, ...updated };
  });
  const dataNew = { matches: matches, current_round: data.current_round };

  return dataNew;
};

export const useMatchesByCompetition = (
  competitionId: number,
  importId?: number,
  teamId?: number,
  teamIdOther?: number
) => {
  return useQuery(['competition', 'matches', competitionId], () =>
    getMatchesByCompetition(competitionId, importId, teamId, teamIdOther)
  );
};

export interface TeamDetail {
  team_id: number;
  name: string;
  logo?: string;
  logo_512?: string;
  alt_logo?: string;
  alt_logo_512?: string;
  description: string;
  is_visible: boolean;
  division: string;
  hide: boolean;
  team_group: string;
  subteams: Subteam[];
}

const getTeamDetailByCompetition = async (
  competitionId: number,
  teamId: number
): Promise<TeamDetail> => {
  const { data } = await axios.get<TeamDetail>(
    `/api/v2/competitions/${competitionId}/teams/${teamId}`,
    { params: { platform_id: siteData().platform.toString() } }
  );
  const assets = {
    logo: data.logo !== '' ? mediaAsset(data.logo!) : undefined,
    logo_512: data.logo_512 !== '' ? mediaAsset(data.logo_512!) : undefined,
    alt_logo: data.alt_logo !== '' ? mediaAsset(data.alt_logo!) : undefined,
    alt_logo_512:
      data.alt_logo_512 !== '' ? mediaAsset(data.alt_logo_512!) : undefined,
  };
  const team = { ...data, ...assets };
  return team;
};

export const useTeamDetailByCompetition = (
  competitionId: number,
  teamId: number
) => {
  return useQuery(['competition', 'team', competitionId, teamId], () =>
    getTeamDetailByCompetition(competitionId, teamId)
  );
};

const getDivisionByCompetition = async (
  competitionId: number
): Promise<Division[]> => {
  const { data } = await axios.get<{ divisions: Division[] }>(
    `/api/competitions/${competitionId}/divisions`,
    { headers: { 'Gamifier-Platform': siteData().platform.toString() } }
  );
  return data.divisions.map((div) => {
    return {
      ...div,
      ...{
        logo: div.logo ? mediaAsset(div.logo) : '',
        banner: div.banner ? mediaAsset(div.banner) : '',
      },
    };
  });
};

export const useDivisionByCompetition = (competitionId: number) => {
  return useQuery(['competition', 'divisions', competitionId], () =>
    getDivisionByCompetition(competitionId)
  );
};

const getMatchById = async (
  competitionId: number,
  matchId: number,
  importId?: number
): Promise<Match> => {
  const { data } = await axios.get<Match>(
    `/api/v2/competitions/${competitionId}/matches/${matchId}?platform_id=${
      siteData().platform
    }&import_id=${importId}`
  );
  const dates = {
    round_start: data.round_start ? new Date(data.round_start) : null,
    round_end: data.round_end ? new Date(data.round_end) : null,
  };
  const match = { ...data, ...dates };
  return match;
};

export const useMatchById = (
  competitionId: number,
  matchId: number,
  importId?: number
) => {
  return useQuery(['competition', 'match', competitionId, matchId], () =>
    getMatchById(competitionId, matchId, importId)
  );
};

const getLastMatchesByCompetition = async (
  competitionId: number,
  importId?: number
): Promise<LastMatches> => {
  const { data } = await axios.get<LastMatches>(
    `/api/v2/competitions/${competitionId}/last_matches?platform_id=${
      siteData().platform
    }&import_id=${importId}`
  );
  return data;
};

export const useLastMatchesByCompetition = (
  competitionId: number,
  importId?: number
) => {
  return useQuery(['competition', 'lastMatches', competitionId], () =>
    getLastMatchesByCompetition(competitionId, importId)
  );
};
