import React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { AppStore, loadProfileFields, refreshMainData } from '../state';
import { useForm, Form, dynamicField } from '../form';
import * as widgets from '../form/widgets';
import { Errors, Field, Values } from '../form/data';
import styled from '@emotion/styled';
import { Loader } from '../components/Loader';
import { VariantType } from 'notistack';

function dataToSnakeCase(data: Values): Values {
  const camelToSnakeCase = (str: string) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  let newData: Values = {};
  Object.entries(data).forEach(([key, v]) => {
    let keySC = camelToSnakeCase(key);
    newData[keySC] = v;
  });
  return newData;
}

const RegForm = (props: { fields: Field[]; other: Props }) => {
  let fields = props.fields;

  let profile = AppStore.useState((s) =>
    Object.assign(
      {
        first_name: s.user?.firstName || '',
        last_name: s.user?.lastName || '',
      },
      dataToSnakeCase(s.user?.profile?.data || {})
    )
  );

  let form = useForm({
    initialValues: profile,
    onSubmit: async (f: Form) => {
      f.state.setLoading(true);
      try {
        let data = f.state.values;
        console.log(data);
        await axios.post('/djapi/v1/update_profile/', data);
        await refreshMainData();
        props.other.showSnackBar('Profile saved', 'success');
      } catch (e: any) {
        if (e.response && e.response.data) {
          let errors: Errors = {};
          for (const [key, value] of Object.entries(e.response.data)) {
            if (key && Array.isArray(value)) {
              errors[key] = value;
            }
          }
          f.state.setErrors(errors);
        }
        props.other.showSnackBar('Profile not saved', 'error');
      } finally {
        f.state.setLoading(false);
      }
    },
    fields: [
      {
        name: 'first_name',
        label: 'First name',
        isRequired: true,
        widget: widgets.Input('text'),
        section: 0,
      },
      {
        name: 'last_name',
        label: 'Last name',
        isRequired: true,
        widget: widgets.Input('text'),
        section: 0,
      },
      ...fields,
    ],
  });

  return (
    <form>
      {form.elems().map((e) => e.render())}

      {form.state.globalErrors.length > 0 && (
        <p className="error">{form.state.globalErrors.join(' ')}</p>
      )}

      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="button"
        disabled={form.state.loading}
        onClick={form.onSubmit}
      >
        {form.state.loading ? <CircularProgress size={20} /> : 'Save'}
      </Button>
    </form>
  );
};

interface Props {
  showSnackBar(message: string, variant: VariantType): void;
}

export default function Register(props: Props) {
  React.useEffect(() => {
    loadProfileFields({});
  }, []);

  const fields = AppStore.useState((s) => s.profileFields);

  return (
    <Root>
      <Loader
        data={fields}
        loaded={(d) => (
          <RegForm
            fields={d.map((f) => {
              let ff = dynamicField(f);
              ff.section = 0;
              return ff;
            })}
            other={props}
          />
        )}
      />
    </Root>
  );
}

let Root = styled.div`
  form {
    padding: 0;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
